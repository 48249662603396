import gql from "graphql-tag";

export const CREATE_CANDIDATE_PROGRESS = gql`
  mutation createCandidateProgress($candidateProgress: candidateProgress_insert_input!) {
    insert_candidateProgress_one(object: $candidateProgress, on_conflict: { constraint: candidateProgress_candidateID_candidateStep_candidateStatus_key, update_columns: [] }) {
      id
    }
  }
`;

export const UPDATE_CANDIDATE_PROGRESS = gql`
  mutation updateCandidateProgress($id: uuid!, $candidateProgress: candidateProgress_set_input!) {
    update_candidateProgress_by_pk(pk_columns: { id: $id }, _set: $candidateProgress) {
      id
    }
  }
`;

export const UPDATE_CANDIDATE_STATUS = gql`
  mutation RemoveDocumentFromClient($id: uuid!, $rollBackSteps: [String!]) {
    delete_candidateProgress(where: { candidateID: { _eq: $id }, candidateStep: { _in: $rollBackSteps } }) {
      affected_rows
    }
  }
`;

export const UPDATE_ELIGIBILITY_INFO = gql`
  mutation updateEligibilityInfo($responses: [partnerApplicationResponses_insert_input!]!) {
    insert_partnerApplicationResponses(objects: $responses, on_conflict: { constraint: partnerApplicationResponses_applicationID_questionID_key, update_columns: [response, updatedBy, updatedDate] }) {
      affected_rows
    }
  }
`;

export const UPDATE_SSN = gql`
  mutation updateSSN($ssn: String!, $candidateID: uuid!) {
    update_partners(where: { locations: { id: { _eq: $candidateID } } }, _set: { ssn: $ssn }) {
      affected_rows
    }
  }
`;

export const UPDATE_DOB = gql`
  mutation updateSSN($dob: date!, $candidateID: uuid!) {
    update_partners(where: { locations: { id: { _eq: $candidateID } } }, _set: { dateOfBirth: $dob }) {
      affected_rows
    }
  }
`;

export const UPDATE_INSURANCE_EXPIRY = gql`
  mutation updateSSN($insuranceExpiry: date!, $candidateID: uuid!) {
    update_partners(where: { locations: { id: { _eq: $candidateID } } }, _set: { insuranceExpiry: $insuranceExpiry }) {
      affected_rows
    }
  }
`;

export const UPDATE_DRIVERS_LICENSE = gql`
  mutation updateSSN($licenseNumber: String!, $licenseState: String!, $licenseExpiry: date!, $candidateID: uuid!) {
    update_partners(where: { locations: { id: { _eq: $candidateID } } }, _set: { licenseNumber: $licenseNumber, licenseState: $licenseState, licenseExpiry: $licenseExpiry }) {
      affected_rows
    }
  }
`;
export const UPDATE_DL = gql`
  mutation updateDL($licenseNumber: String!, $licenseState: String!, $licenseExpiry: date!, $candidateID: uuid!) {
    update_driversLicenses(where: { partnerID: { _eq: $candidateID } }, _set: { expiration: $licenseExpiry, number: $licenseNumber, state: $licenseState }) {
      affected_rows
    }
  }
`;
export const SAVE_FUNNEL_FILE = gql`
  mutation SaveFunnelFiles($partnerFile: partnerFiles_insert_input!) {
    insert_partnerFiles_one(object: $partnerFile, on_conflict: { constraint: partnerFiles_pkey, update_columns: [fileID] }) {
      id
    }
  }
`;

export const SET_APPROVAL_DATE = gql`
  mutation setPartnerApprovalDate($clientLocationPartnerID: uuid!, $approvalDate: date!) {
    update_clientLocationPartners(where: { id: { _eq: $clientLocationPartnerID } }, _set: { approvalDate: $approvalDate }) {
      affected_rows
    }
  }
`;

export const SET_CANDIDATE_NOTES = gql`
  mutation setCandidateNotes($id: uuid!, $notes: String!) {
    update_clientLocationPartners(where: { id: { _eq: $id } }, _set: { notes: $notes }) {
      affected_rows
    }
  }
`;

export const SET_CANDIDATE_NOTES_NEW = gql`
mutation setCandidateNotes($id: uuid!, $notes: jsonb) {
  update_clientLocationPartners(where: {id: {_eq: $id}}, _set: {notesList: $notes}) {
    affected_rows
  }
}
`;

export const MARK_CANDIDATE_STATUS = gql`
mutation markCandidateNewlyApproved($candidateID: uuid!, $status: partnerStatuses_enum, $updateDate: timestamptz,$approvalDate: date ) {
  update_clientLocationPartners_by_pk(pk_columns: { id: $candidateID }, _set: { status: $status, updated_at: $updateDate,approvalDate: $approvalDate }) {
    id
  }
}
`;

export const UPDATE_PROFILE_BASIC  = gql`
mutation SavePartner($id: uuid!, $profileSet: partners_set_input!) {
  update_partners_by_pk(pk_columns: {id: $id}, _set: $profileSet) {
    addressID
    driversLicenses {
      id
    }
  }
}
`;


export const UPDATE_PARTNER_DRIVERSLICENSE = gql`
mutation SavePartnerDriversLicense($id: uuid!, $profileSet:driversLicenses_set_input!) {
  update_driversLicenses_by_pk(pk_columns: {id: $id}, _set: $profileSet) {
    partnerID
  }
}
`;

export const UPDATE_PARTNER_ADDRESS = gql`
mutation SavePartnerDriversLicense($id: uuid!, $profileSet: addresses_set_input!) {
  update_addresses_by_pk(pk_columns: {id: $id}, _set: $profileSet) {
    partners {
      criminalHistory {
        id
      }
    }
  }
}
`
export const UPDATE_CBC_VERIFIED = gql`
mutation markPartnerCBCVerified($candidateID: uuid!,$status: String) {
  update_clientLocationPartners_by_pk(pk_columns: {id: $candidateID}, _set: {partnerCBCResult: $status}) {
    id
  }
}
`;

export const DELETE_CANDIDATE=gql`
mutation deleteCandidate($partnerID: uuid!) {
  delete_partners_by_pk(id: $partnerID) {
    id
  }
}
`;

export const UPDATE_CANDIDATE_LOCATION = gql`
mutation updateCandidateLocation($clientLocationID: uuid!, $candidateID: uuid!) {
  update_clientLocationPartners_by_pk(pk_columns: {id: $candidateID}, _set: {clientLocationID: $clientLocationID}) {
      id
  }
}
`;

export const DELETE_INVALID_STATUS=gql`
mutation deleteInvalidStatus($id: uuid!) {
  delete_candidateProgress(where: {candidateStatus: {_eq: "INVALID"}, id: {_eq: $id}}) {
    affected_rows
  }
}
`;
export const UPDATE_CANDIDATE_FILES = gql`
    mutation updateCandidateFiles($funnelFilesID: uuid!, $locationPartnerID: uuid!) {
        update_partnerFiles(where: {locationPartnerID: {_eq: $locationPartnerID}}, _set: {funnelFilesID: $funnelFilesID}) {
            affected_rows
        }
    }
`;
