import Vue from "vue";
import VueApollo from "vue-apollo";
import apolloClient from "@/apollo/vue-apollo";

Vue.use(VueApollo);
let loading = 0;
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    //notifyOnNetworkStatusChange: true,
    // $loadingKey: 'loading',
  },
  watchLoading(state, mod) {
    loading += mod;
    return loading;
  },
});

export default apolloProvider;
