<template>
  <img
    class="loader-img margin-t-8"
    src="data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='9.625' width='2.75' height='8.25' rx='1.375' fill='%2316789E'/%3E%3Crect opacity='0.5' x='9.625' y='13.75' width='2.75' height='8.25' rx='1.375' fill='%2316789E'/%3E%3Crect opacity='0.8' x='22' y='9.625' width='2.75' height='8.25' rx='1.375' transform='rotate(90 22 9.625)' fill='%2316789E'/%3E%3Crect opacity='0.25' x='8.25' y='9.625' width='2.75' height='8.25' rx='1.375' transform='rotate(90 8.25 9.625)' fill='%2316789E'/%3E%3Crect opacity='0.9' x='17.8052' y='2.24982' width='2.75' height='8.25' rx='1.375' transform='rotate(45 17.8052 2.24982)' fill='%2316789E'/%3E%3Crect opacity='0.35' x='8.08347' y='11.9722' width='2.75' height='8.25' rx='1.375' transform='rotate(45 8.08347 11.9722)' fill='%2316789E'/%3E%3Crect opacity='0.65' x='19.7509' y='17.8058' width='2.75' height='8.25' rx='1.375' transform='rotate(135 19.7509 17.8058)' fill='%2316789E'/%3E%3Crect opacity='0.15' x='10.0279' y='8.0835' width='2.75' height='8.25' rx='1.375' transform='rotate(135 10.0279 8.0835)' fill='%2316789E'/%3E%3C/svg%3E"
  />
</template>
<script>
export default {};
</script>

<style scoped>
  .margin-t-8 {
    margin-top: 8px;
  }
</style>