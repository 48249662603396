import { AddEditLayout, AdminLayout } from "@/components";

const Accounting = () => import("@/modules/admin/accounting/Accounting");
const AccountingReview = () => import("@/modules/admin/accounting/invoicing/AccountingReview");
const InvoiceModal = () => import("@/modules/admin/accounting/invoicing/InvoiceModal");

export default [
  {
    path: "accounting",
    name: "accounting",
    component: Accounting,
    meta: { layout: AdminLayout },
  },
  {
    path: "accounting/review/:settlementID",
    name: "accountingReview",
    component: AccountingReview,
    meta: { layout: AddEditLayout },
  },
  {
    path: "accounting/invoice/:invoiceId",
    name: "viewInvoice",
    component: InvoiceModal,
    meta: { layout: AddEditLayout },
  },
];
