export default (to, from, next) => {
  const isAuthenticated = !!localStorage.getItem("access_token");
  if (to.name && to.name.toLowerCase() === "login") {
    if (isAuthenticated) {
      next("/");
    } else {
      next();
    }
  } else {
    if (to.meta.open) {
      next();
    } else {
      if (isAuthenticated) {
        next();
      } else {
        next("/login");
      }
    }
  }
};
