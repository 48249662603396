<template>
  <div class="toolbar-container">
    <div v-if="small" class="toolbar-text">
      <button class="ql-link"></button>
      <!-- TODO make this dynamic -->
      <select v-if="ddi" class="ql-ddiVars">
        <option value="" disabled selected hidden>DDI Tags</option>
        <option value="1">Street 1</option>
        <option value="2">Street 2</option>
        <option value="3">Street 3</option>
        <option value="4">City</option>
        <option value="5">State</option>
        <option value="6">Zip</option>
        <option value="7">Name</option>
        <option value="8">Phone</option>
        <option value="9">Onboarding Email</option>
      </select>
      <select v-if="client" class="ql-clientVars">
        <option value="" disabled selected hidden>Client Tags</option>
        <option value="1">Name</option>
        <option value="2">Worker Type</option>
        <option value="3">ELA Response</option>
      </select>
      <select v-if="partner" class="ql-partnerVars">
        <option value="" disabled selected hidden>Partner Tags</option>
        <option value="1">First Name</option>
        <option value="2">Middle Name</option>
        <option value="3">Last Name</option>
        <option value="4">Name Printed As</option>
        <option value="5">Other Name</option>
        <option value="6">Doing Business As</option>
        <option value="7">Date of Birth</option>
        <option value="8">SSN</option>
        <option value="9">EIN</option>
        <option value="10">Insurance Expiration</option>
        <option value="111">Drivers License Expiration</option>
        <option value="12">Driver License Number</option>
        <option value="13">Driver License State Issued</option>
        <option value="14">Address 1</option>
        <option value="15">City</option>
        <option value="16">State</option>
        <option value="17">Zip</option>
        <option value="18">Phone</option>
        <option value="19">Email</option>
        <option value="20">Emergency Contact Name</option>
        <option value="21">Emergency Contact Phone</option>
        <option value="22">Emergency Contact Relationship</option>
        <option value="23">Vehicle Make</option>
        <option value="24">Vehicle Model</option>
        <option value="25">Vehicle Year</option>
        <option value="26">Electronic Signature</option>
        <option value="27">Initials</option>
        <option value="28">Application Link</option>
      </select>
    </div>
    <div v-if="!small" class="toolbar">
      <div>
        <select class="ql-size">
          <option value="small"></option>
          <!-- Note a missing, thus falsy value, is used to reset to default-->
          <option selected=""></option>
          <option value="large"></option>
          <option value="huge"></option>
        </select>
        <!-- Add a bold button-->
        <button class="ql-bold"></button>
        <button class="ql-italic"></button>
        <button class="ql-underline"></button>
        <button class="ql-strike"></button>
        <select class="ql-align"></select>
        <button class="ql-list" value="ordered"></button>
        <button class="ql-list" value="bullet"></button>
        <button class="ql-link"></button>
        <select class="ql-font"></select>
      </div>
      <div>
        <!-- TODO this needs to be dynamic -->
        <select v-if="ddi" class="ql-ddiVars">
          <option value="" disabled selected hidden>DDI Tags</option>
          <option value="1">Street 1</option>
          <option value="2">Street 2</option>
          <option value="3">Street 3</option>
          <option value="4">City</option>
          <option value="5">State</option>
          <option value="6">Zip</option>
          <option value="7">Name</option>
          <option value="8">Phone</option>
          <option value="9">Onboarding Email</option>
        </select>
        <select v-if="client" class="ql-clientVars">
          <option value="" disabled selected hidden>Client Tags</option>
          <option value="1">Name</option>
          <option value="2">Worker Type</option>
          <option value="3">ELA Response</option>
        </select>
        <select v-if="partner" class="ql-partnerVars">
          <option value="" disabled selected hidden>Partner Tags</option>
          <option value="1">First Name</option>
          <option value="2">Middle Name</option>
          <option value="3">Last Name</option>
          <!--          <option value="4">Name Printed As</option>-->
          <!--          <option value="5">Other Name</option>-->
          <!--          <option value="6">Doing Business As</option>-->
          <!--          <option value="7">Date of Birth</option>-->
          <!--          <option value="8">SSN</option>-->
          <!--          <option value="9">EIN</option>-->
          <!--          <option value="10">Insurance Expiration</option>-->
          <!--          <option value="11">Drivers License Expiration</option>-->
          <!--          <option value="12">Driver License Number</option>-->
          <!--          <option value="13">Driver License State Issued</option>-->
          <option value="14">Address 1</option>
          <option value="15">City</option>
          <option value="16">State</option>
          <option value="17">Zip</option>
          <option value="18">Phone</option>
          <option value="19">Email</option>
          <!--          <option value="20">Emergency Contact Name</option>-->
          <!--          <option value="21">Emergency Contact Phone</option>-->
          <!--          <option value="22">Emergency Contact Relationship</option>-->
          <!--          <option value="23">Vehicle Make</option>-->
          <!--          <option value="24">Vehicle Model</option>-->
          <!--          <option value="25">Vehicle Year</option>-->
          <option value="26">Electronic Signature</option>
          <option value="27">Initials</option>
          <!--          <option value="28">Application Link</option>-->
        </select>
      </div>
    </div>
  </div>
</template>
<script>
import "../../assets/css/quill.css";

export default {
  name: "EditorToolbar",
  props: {
    small: { type: Boolean, default: false },
    ddi: { type: Boolean, default: false },
    client: { type: Boolean, default: false },
    partner: { type: Boolean, default: false },
  },
};
</script>
<style scoped>
.toolbar {
  display: flex;
  flex-direction: column;
}

.ql-picker.ql-clientVars {
  width: 160px;
}
.ql-picker.ql-ddiVars {
  width: 160px;
}
.ql-picker.ql-partnerVars {
  width: 160px;
}
.ql-picker.ql-ddiVars,
.ql-picker.ql-clientVars,
.ql-picker.ql-partnerVars,
.ql-picker-item::before,
.ql-picker.ql-ddiVars,
.ql-picker.ql-clientVars,
.ql-picker.ql-partnerVars,
.ql-picker-label::before {
  content: "Custom";
}
</style>
