import gql from "graphql-tag";

export const GET_DISTRIBUTION = gql`
  query getDistributions($paymentProcessor: [String!]) {
    distributions(where: { status: { _eq: "DISTRIBUTED" }, paymentProcessor: { _in: $paymentProcessor } }) {
      status
      paymentProcessor
      clientID
      id
      payPeriodStartDate
      totalPayout
      payPeriodEndDate
      client {
        id
        name
        startDate
      }
    }
  }
`;

export const GET_DISTRIBUTION_BY_DATE = gql`
  query distributionByDate($filteredDate: timestamptz, $paymentProcessor: [String!]) {
    distributions(where: { distributedDateTime: { _eq: $filteredDate }, status: { _eq: "DISTRIBUTED" }, paymentProcessor: { _in: $paymentProcessor } }) {
      status
      paymentProcessor
      clientID
      id
      payPeriodStartDate
      totalPayout
      payPeriodEndDate
      distributedDateTime
      client {
        id
        name
        startDate
      }
    }
  }
`;
