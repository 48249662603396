<template>
  <nav class="breadcrumbs">
    <router-link to="/">Home</router-link>/
    <fragment v-for="(breadcrumb, index) in breadcrumbs" :key="`${index}-${breadcrumb.name}`">
      <fragment v-if="index < breadcrumbs.length - 1"
        ><router-link :to="{ name: breadcrumb.path }">{{ breadcrumb.name }}</router-link
        >/</fragment
      ><span v-else class="current">{{ breadcrumbs[breadcrumbs.length - 1].name }}</span>
    </fragment>
  </nav>
</template>

<script>
import { Fragment } from "vue-fragment";

export default {
  name: "BreadCrumbs",
  components: { Fragment },
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
