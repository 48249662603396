<template>
  <p class="withdrawn">
    {{ status }}<span class="date-time">{{ currentStep.date | formatDateTimePacific }} </span>
    <slot></slot>
  </p>
</template>
<script>
export default {
  props: {
    currentStep: {
      type: Object,
      required: true,
      default: () => {},
    },
    status: {
      type: String,
      required: false,
      default: "Withdrawn",
    },
  },
};
</script>
