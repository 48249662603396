<template>
  <create-edit-document title="Edit Document" :possible-assignments="clients" :copy-template-id="documentID" is-client @onSave="saveDocument" />
</template>
<script>
import { GET_CLIENTS_DETAILS } from "@/modules/admin/clients/graph/queries";
import CreateEditDocument from "@/modules/shared/documents/CreateEditDocument";
import { mapGetters } from "vuex";
import documentService from "@/services/document.service";

export default {
  name: "EditDdiDocument",
  components: { CreateEditDocument },
  props: {
    documentID: {
      type: String,
      required: false,
      default: () => null,
    },
  },
  data: () => ({
    version: 1,
    templateStatus: "draft",
    clients: [],
  }),
  computed: {
    ...mapGetters(["getFullName"]),
  },
  methods: {
    updateDocumentTemplate(template) {
      const variables = {
        id: template.id,
        title: template.title,
        text: template.text,
        html: template.html,
        version: this.version,
        updatedBy: template.updatedBy,
        status: template.status,
      };
      return documentService.updateDocumentTemplate(variables);
    },
    async saveDocument(event) {
      let clearRemoveOverrideClientIDs;
      let addRemoveOverrideClientIDs;
      const template = event.template;
      template.updatedBy = this.getFullName;

      await this.updateDocumentTemplate(template);

      if (template.status === "draft" || template.status === "inactive") {
        clearRemoveOverrideClientIDs = this.clients.map((client) => client.id);
      } else {
        // TODO: Add to funnel documents
        clearRemoveOverrideClientIDs = this.clients.filter((item) => !event.deSelected.includes(item.id)).map((item) => item.id);
        addRemoveOverrideClientIDs = event.deSelected.filter((item) => !event.originalAssignments.includes(item));
      }

      const objects = addRemoveOverrideClientIDs.map((id) => ({ clientID: id, originalTemplateID: template.id, templateID: template.id, updatedBy: template.updatedBy, type: "REMOVE" }));

      await documentService.clearRemoveClientOverride(clearRemoveOverrideClientIDs, template.id);
      await documentService.addRemoveClientOverride(objects);
      this.$router.back();
    },
  },
  apollo: {
    clients: {
      query: GET_CLIENTS_DETAILS,
    },
  },
};
</script>
