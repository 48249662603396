const getInitialState = () => {
  return {
    clientName: "",
    templateID: "",
  };
};

const state = getInitialState();

const getters = {
  getClientName: (state) => {
    return state.clientName;
  },
  getTemplateID: (state) => {
    return state.templateID;
  },
};

const mutations = {
  updateClientName(state, clientName) {
    state.clientName = clientName;
  },
  updateTemplateID(state, templateID) {
    state.templateID = templateID;
  },
};

export default { state, getters, mutations };
