class PayCycle {
  static WEEKLY = "WEEKLY";
  static BI_WEEKLY = "BI_WEEKLY";
  static NEXT_DAY = "NEXT_DAY";

  static payCycles = {
    [PayCycle.WEEKLY]: "Weekly(7-day)",
    [PayCycle.BI_WEEKLY]: "Biweekly(14-day)",
    [PayCycle.NEXT_DAY]: "Next Day",
  };

  static getPayCycleLabel = (payCycle) => PayCycle.payCycles[payCycle];
  static getPayCycleOptions = () => Object.keys(PayCycle.payCycles).map((payCycle) => ({ id: payCycle, label: PayCycle.getPayCycleLabel(payCycle) }));
  static getSecondaryPayCycleOptions = () => {
    const secondaryPayCycles = PayCycle.getPayCycleOptions();
    secondaryPayCycles.forEach((item, index) => {
      if (item.id == "NEXT_DAY") {
        secondaryPayCycles.splice(index, 1);
      }
    });
    return secondaryPayCycles;
  };
}

export default PayCycle;
