class Direction {
  /**
   * @return {string} ascending direction
   * @constructor
   */
  static get ASC() {
    return "asc";
  }

  /**
   * @return {string} descending direction
   * @constructor
   */
  static get DESC() {
    return "desc";
  }
}

export default Direction;
