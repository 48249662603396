<template>
  <base-modal id="flyout-modal-document-preview">
    <div>
      <a v-show="$props.downloadable" style="margin-left: 50px" class="button secondary" @click="generateReport">Download PDF</a>
      <a style="margin-left: 30px" class="button secondary" @click="closeModal">Cancel</a>
    </div>

    <vue-html2pdf
      ref="html2Pdf"
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename=""
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="800px"
      @hasGenerated="hasGenerated($event)"
    >
      <section slot="pdf-content">
        <div :class="watermark && 'watermark'" v-html="html"></div>
      </section>
    </vue-html2pdf>
    <div v-if="$props.pdf">
      <!--      <vue-html2pdf-->
      <!--        ref="html2Pdf"-->
      <!--        :show-layout="false"-->
      <!--        :float-layout="true"-->
      <!--        :enable-download="false"-->
      <!--        :preview-modal="true"-->
      <!--        :paginate-elements-by-height="1400"-->
      <!--        :pdf-quality="2"-->
      <!--        :manual-pagination="false"-->
      <!--        pdf-format="letter"-->
      <!--        pdf-orientation="portrait"-->
      <!--        pdf-content-width="100%"-->
      <!--        :html-to-pdf-options="htmlToPdfOptions"-->
      <!--        @hasGenerated="hasGenerated($event)"-->
      <!--      >-->
      <!--        <section slot="pdf-content">-->
      <div class="watermark" v-html="html"></div>
      <!--        </section>-->
      <!--      </vue-html2pdf>-->
    </div>
  </base-modal>
</template>
<script>
import BaseModal from "@/components/modals/BaseModal";
import VueHtml2pdf from "vue-html2pdf";
// import { GET_DOCUMENT_PREVIEW } from "@/modules/shared/documents/graph/queries";
import micromodal from "micromodal";
import { mapGetters } from "vuex";
import handlebars from "handlebars";
// import { GET_PARTNER_PROFILE_INFO } from "@/modules/admin/partners/graph/queries";
import { restApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/Base64Encoding";

export default {
  name: "FlyoutDocumentPreview",
  components: {
    VueHtml2pdf,
    BaseModal,
  },
  props: {
    templateid: {
      type: String,
      required: false,
      default: "",
    },
    content: {
      type: String,
      required: false,
      default: "",
    },
    downloadable: {
      type: Boolean,
      default: false,
      required: false,
    },
    preview: {
      type: Boolean,
      default: false,
      required: false,
    },
    watermark: {
      type: Boolean,
      default: false,
      required: false,
    },
    pdf: {
      type: Boolean,
      default: false,
      required: false,
    }
  },
  data: function () {
    return {
      documentTemplates_by_pk: {},
      html: this.$props.content,
      partner: {},
      htmlToPdfOptions: {
        filename: "",
        html2canvas: { useCORS: true, height: 1024, logging: false },
      },
    };
  },
  computed: {
    ...mapGetters(["isFlyoutShowing"]),
  },
  watch: {
    templateid() {
      const params = { id: this.$props.templateid }
      restApi.post('partner/getDocumentPreview', encodeWithParam(params))
        .then((data) => {
          if(data.data.success){
            console.log('PreviewDoc:', data.data.result[0])
          this.htmlToPdfOptions.filename = `${data.data.result[0].title}.pdf`;
          let htm=decodeURIComponent(data.data.result[0].html)
          const myHtml = htm.replace(/(<style[\w\W]+style>)/g, "");
          this.html = handlebars.compile(myHtml)(this.partner);
          if (this.$props.pdf) {
            this.generateReport();
          } else {
            micromodal.show("flyout-modal-document-preview", {});
          }
          }
        });
    },
    content(newValue) {
      this.html = newValue.replace(/(<style[\w\W]+style>)/g, "");
      if (this.$props.pdf) {
        document.body.appendChild(this.$refs.html2Pdf.$el);
        this.generateReport();
      } else {
        micromodal.show("flyout-modal-document-preview", {});
      }
    },
    isFlyoutShowing(value) {
      if (value == false && this.$refs.html2Pdf.$el.parentElement == document.body) document.body.removeChild(this.$refs.html2Pdf.$el);
    },
  },
  mounted() {
    this.html = "";
    this.$nextTick(
      micromodal.init({
        onShow: function () {
          document.body.classList.add("no-scroll");
          /*
          document.body.appendChild(modal);
*/
        },
        onClose: function () {
          document.body.classList.remove("no-scroll");
        },
      })
    );
    if (this.html) {
      if (this.$props.pdf) {
        document.body.appendChild(this.$refs.html2Pdf.$el);
        this.generateReport();
      } else {
        micromodal.show("flyout-modal-document-preview", {});
      }
    }
  },
  methods: {
    hasGenerated() {
      this.$emit("pdfGenerationComplete", "");
    },

    /*
        Generate Report using refs and calling the
        refs function generatePdf()
    */
    generateReport() {
      if ((this.$props.preview && this.$props.content != "") || !this.$props.preview) {
        this.$refs.html2Pdf.generatePdf();
      }
    },
    closeModal() {
      this.$emit("changePreview");
      micromodal.close("flyout-modal-document-preview");
    },
  },
  // apollo: {
  //   partner: {
  //     query: GET_PARTNER_PROFILE_INFO,
  //     variables() {
  //       return {
  //         partnerID: this.$route.params.id,
  //       };
  //     },
  //     result({ data }) {
  //       this.partner = data;
  //     },
  //     skip() {
  //       return !this.$route.params.id;
  //     },
  //   },
  //   documentTemplates_by_pk: {
  //     query: GET_DOCUMENT_PREVIEW,
  //     variables() {
  //       return {
  //         templateID: this.$props.templateid,
  //       };
  //     },
  //     result({ data }) {
  //       this.htmlToPdfOptions.filename = `${data.documentTemplates_by_pk.title}.pdf`;
  //       const myHtml = data.documentTemplates_by_pk.html.replace(/(<style[\w\W]+style>)/g, "");
  //       this.html = handlebars.compile(myHtml)(this.partner);
  //       if (this.$props.pdf) {
  //         this.generateReport();
  //       } else {
  //         micromodal.show("modal-document-preview", {});
  //       }
  //     },
  //     skip() {
  //       return !this.$props.templateid || this.$props.content;
  //     },
  //   },
  // },
};
</script>
<style scoped>
.watermark {
  margin: 50px;
  background-repeat: round;
  background-image: url(../../../assets/images/sample-text.svg);
}
</style>
