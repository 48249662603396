<template>
  <div :class="{ 'flyout-overlay': $props.isOverlay }">
    <div v-show="isThisFlyoutShowing($props.name)" class="flyout edit" :class="[{ hidden: !isThisFlyoutShowing($props.name), large, fullScreen }, $props.name]">
      <slot name="header">
        <div class="flyout-header">
          <div
            class="close"
            @click="
              showFlyout('');
              emitCancel();
            "
          >
            <a @click="showFlyout('')"> <i class="icon-x"></i> </a>
          </div>
          <div class="title">
            <p>{{ $props.title }}</p>
          </div>
          <slot name="action"></slot>
        </div>
      </slot>
      <slot name="contactaction"></slot>
      <div class="flyout-content" :class="{ override: $props.overlay }">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Flyout",
  props: {
    name: {
      type: String,
      required: true,
      default: "default",
    },
    title: {
      type: String,
      required: true,
      default: "Flyout Title",
    },
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
    overlay: {
      type: Boolean,
      required: false,
      default: false,
    },
    isOverlay: {
      type: Boolean,
      required: false,
      default: false,
    },
    fullScreen: Boolean,
  },
  computed: {
    ...mapGetters(["getFlyout", "isThisFlyoutShowing"]),
  },
  methods: {
    ...mapActions(["showFlyout"]),
    emitCancel() {
      this.$emit("cancel");
    },
  },
};
</script>
