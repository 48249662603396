<template>
  <flyout name="bulk-comms-flyout" title="Bulk-Communications" full-screen>
    <!-- <template #header>
      <sticky-header title="Send Message">
        <div class="button-group">
          <button :disabled="quillEmpty" class="button" @click="triggerSend">Send</button>
          <button class="button secondary" @click="done">Cancel</button>
        </div>
      </sticky-header>
    </template> -->
    <main class="off-white">
      <div class="container bulk-email-container">
        <div class="content">
          <div class="to-bulk-emails-tags">
            <h4>To</h4>
            <div class="tags">
              <div v-for="item in $props.bulkObj" :key="item.id" class="tag">
                <div class="info">
                  <p>{{ item.name }}</p>
                </div>
                <!-- <div class="close" @click="removeItem(item)"><i class="icon-x-circle"></i></div> -->
              </div>
            </div>
          </div>
          <tabs :tabs="commsTabs" :use-history="false" :starting-tab="startTab">
            <template #email>
              <validation-observer ref="emailForm" slim>
                <div class="grid email">
                  <div class="col-4_md-6_sm-12">
                    <div class="field">
                      <form-input v-model="subject" name="subject" label="Subject" required placeholder="Email Subject"
                        @input="update($event)" />
                    </div>
                  </div>
                  <div class="col-6_md-6_sm-12"></div>
                  <div class="col-6_md-12">
                    <new-editor v-if="displayEditor" @json="saveJSON" @html="saveHTML" />
                  </div>
                </div>
                <div class="button-group close-top">
                  <button :disabled="quillEmpty" class="button" @click="sendEmail">Send</button>
                  <a class="button secondary" @click="done">Cancel</a>
                </div>
              </validation-observer>
            </template>
            <template #sms>
              <validation-observer ref="smsForm" slim>
                <div class="grid sms">
                  <div class="col-6_md-12">
                    <textarea v-model="smsBody" class="template-preview"
                      @input="messageVal($event.target.value)"></textarea>
                  </div>
                </div>
                <div class="button-group close-top">
                  <button :disabled="quillEmpty" class="button" @click="sendText">Send</button>
                  <a class="button secondary" @click="done">Cancel</a>
                </div>
              </validation-observer>
            </template>
          </tabs>
        </div>
      </div>
    </main>
  </flyout>
</template>
<style>
.bulk-email-container .to-bulk-emails-tags .tags {
  display: inline-flex;
  flex-flow: wrap;
  background: white;
  padding: 10px;
  max-height: 120px;
  overflow-y: scroll;
}

.bulk-email-container .to-bulk-emails-tags .tags .tag {
  margin-right: 8px;
}

.bulk-email-container .white-well {
  display: none;
}
</style>
<script>
import { Flyout } from "@/components";
import NewEditor from "@/components/editor/NewEditor";
import { ValidationObserver } from "vee-validate";
//import StickyHeader from "@/components/layout/StickyHeader";
import Tabs from "@/components/tabs/Tabs";
import FormInput from "@/components/forms/fields/FormInput";
import { mapActions, mapGetters } from "vuex";
import { DateTime } from "luxon";

export default {
  components: {
    FormInput,
    Flyout,
    //StickyHeader,
    NewEditor,
    Tabs,
    ValidationObserver,
  },
  props: {
    candidate: {
      type: Object,
      required: false,
      default: () => { },
    },
    bulkObj: {
      type: Array,
      required: false,
      default: () => [],
    },
    currentStep: {
      type: Object,
      required: false,
      default: () => { },
    },
    onClose: {
      type: Function,
      required: false,
      default: () => { },
    },
    displayEditor: {
      type: Boolean,
      default: false,
      required: false
    },
    commsType: {
      type: String,
      required: false,
      default: "EMAIL"
    }
  },
  data: () => ({
    quillEmpty: true,
    invokeSms: false,
    invokeEmail: false,
    emailText: {},
    template: {
      title: "",
      status: "",
    },
    html: "",
    smsBody: "",
    subject: "",
    partners: [],
  }),
  computed: {
    ...mapGetters(["getUserID", "getUsername"]),
    charsUsed() {
      return this.smsText.length;
    },
    commsTabs() {
      return [
        { key: "email", label: "Email Message" },
        { key: "sms", label: "Text Message" },
      ];
    },
    startTab() {
      return this.$props.commsType == "EMAIL" ? 0 : 1;
    }
  },
  methods: {
    ...mapActions(["showFlyout"]),
    update(val) {
      if (val.length > 0) {
        this.quillEmpty = false;
      } else {
        this.quillEmpty = true;
      }
    },
    messageVal(value) {
      if (value.length > 0) {
        this.quillEmpty = false;
        this.invokeSms = true;
        this.invokeEmail = false;
      } else {
        this.quillEmpty = true;
      }
    },
    sendText() {
      this.$emit("sendText", { body: encodeURIComponent(this.smsBody), sendDate: DateTime.utc().toFormat("yyyy-MM-dd") });
      this.done();
    },
    sendEmail() {
      this.$emit("sendEmail", { subject: this.subject, html: encodeURIComponent(this.html) })
      this.done();
    },
    removeItem(obj) {
      this.$props.bulkObj.forEach((item, index, object) => {
        if (item.partnerID === obj.partnerID) {
          object.splice(index, 1);
        }
      });
    },
    done() {
      if (this.invokeEmail) {
        this.$refs.emailForm.reset();
      }
      this.subject = '';
      this.smsBody = '';
      this.invokeSms = false;
      this.invokeEmail = false;
      this.$props.onClose();
    },
    saveHTML(data) {
      this.html = data;
    },
    saveJSON(data) {
      this.json = data;
      if (data.body.rows[0].columns[0].contents.length > 0 && this.$props.bulkObj.length > 0) {
        this.quillEmpty = false;
        this.invokeEmail = true;
        this.invokeSms = false;
      } else {
        this.quillEmpty = true;
      }
    },
    triggerSend() {
      if (this.invokeSms) {
        this.sendText();
      }
      if (this.invokeEmail) {
        this.sendEmail();
      }
    },
    reformatPhoneNumber(phone) {
      const number = phone.replace(/[^\d]/g, '');
      return number
    }
  },
};
</script>
