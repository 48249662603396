import ReportsPage from "@/modules/admin/reports/ReportsPage";
import { AdminLayout } from "@/components";

export default [
  {
    path: "reports",
    name: "reports",
    component: ReportsPage,
    meta: { layout: AdminLayout },
  },
];
