<template>
  <div :id="$props.id" class="modal" aria-hidden="true">
    <div :class="{ 'modal-overlay': true, 'modal-overlay-full': full }" tabindex="-1" data-micromodal-close="data-micromodal-close">
      <div :class="{ 'modal-container': true, 'modal-full': full }" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby">
        <div class="modal-content">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import micromodal from "micromodal";

export default {
  name: "BaseModal",
  props: {
    id: {
      type: String,
      required: true,
      default: "",
    },
    full: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mounted() {
    this.$nextTick(() => {
      micromodal.init({
        onShow: function () {
          this.toggleBodyClass("addClass", "no-scroll");
        },
        onClose: function () {
          this.toggleBodyClass("removeClass", "no-scroll");
        },
      });
    });
  },
  methods: {
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;

      if (addRemoveClass === "addClass") {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
  },
};
</script>
