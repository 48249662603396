<template>
  <div class="tabs">
    <ul class="list-unstyled tab-list">
      <li v-for="tab in tabs" :key="`${tab.key}-tab`" :class="{ active: selectedTab.key === tab.key }" @click="changeTab(tab)">
        {{ tab.label }}
        <tooltip v-if="showWarning(tab)" :id="`${tab.key}-tooltip`" :title="tabTitle(tab)" :description="tabDescription(tab)" hover icon-class="icon-alert-circle" />
      </li>
    </ul>
    <div class="tab-content">
      <div v-for="tab in tabs" :key="`${tab.key}-content`">
        <fragment v-if="selectedTab.key === tab.key">
          <slot :name="tab.key" :tab="tab"></slot>
        </fragment>
      </div>
    </div>
  </div>
</template>
<script>
import Tooltip from "@/components/popups/Tooltip";
import { Fragment } from "vue-fragment";
export default {
  name: "Tabs",
  components: { Tooltip, Fragment },
  props: {
    tabs: {
      type: Array,
      required: true,
      default: () => [],
    },
    startingTab: {
      type: Number,
      required: false,
      default: 0,
    },
    useHistory: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      selectedTab: this.$props.tabs[this.$props.startingTab],
    };
  },
  mounted() {
    if (this.$props.useHistory) {
      if (!this.$route.hash) {
        this.$router.push(`#${this.selectedTab.key}`);
      }
      this.selectedTab = this.$props.tabs.filter((tab) => tab.key === this.$route.hash.substr(1))[0];
    }
  },
  watch: {
    startingTab(value) {
      this.selectedTab = this.$props.tabs[value]
    }
  },
  methods: {
    changeTab(tab) {
      this.selectedTab = tab;
      if (this.$props.useHistory) {
        this.$router.push(`#${tab.key}`);
      }
    },
    showWarning(tab) {
      return tab.error && tab.error.showWarning;
    },
    tabTitle(tab) {
      if (tab.error) {
        return tab.error.title || "Incomplete";
      }
      return "";
    },
    tabDescription(tab) {
      if (tab.error) {
        return tab.error.description || "This is showing for a reason that did not provide a description, but something on this tab is incomplete.";
      }
      return "";
    },
  },
};
</script>
