<template>
  <base-modal id="select-image-modal">
    <p>Choose an image</p>
    <div id="mediaLibrary">
      <div class="image">
        <img src="https://picsum.photos/id/1/800" />
      </div>

      <div class="image">
        <img src="https://picsum.photos/id/10/800" />
      </div>

      <div class="image">
        <img src="https://picsum.photos/id/20/800" />
      </div>

      <div class="image">
        <img src="https://picsum.photos/id/30/800" />
      </div>

      <div class="image">
        <img src="https://picsum.photos/id/40/800" />
      </div>

      <div class="image">
        <img src="https://picsum.photos/id/50/800" />
      </div>
    </div>
  </base-modal>
</template>

<script>
import BaseModal from "@/components/modals/BaseModal";
export default {
  name: "SelectImageModal",
  components: { BaseModal },
};
</script>
