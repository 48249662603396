var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-table',{attrs:{"headers":_vm.headers,"entries":_vm.contacts},scopedSlots:_vm._u([{key:"phone",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(_vm._f("formatPhone")(item.phone)))])]}},{key:"email",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("mailto:" + (item.email))}},[_vm._v(_vm._s(item.email))])]}},{key:"address",fn:function(ref){
var item = ref.item;
return [_c('p',[_c('span',[_vm._v(_vm._s(item.address && item.address.address1 ? item.address.address1 : ""))])]),_c('p',[_c('span',[_vm._v(" "+_vm._s(item.address && item.address.address2 ? item.address.address2 : ""))])]),_c('p',[_vm._v(" "+_vm._s(item.address && item.address.city ? item.address.city : "")),(item.address && item.address.state)?_c('span',[_vm._v(",")]):_vm._e(),_c('span',[_vm._v(_vm._s(item.address && item.address.state ? item.address.state : ""))])]),_c('p',[_vm._v(_vm._s(item.address && item.address.postalCode ? item.address.postalCode : ""))]),_c('p',[_vm._v(_vm._s(item.address && item.address.country ? item.address.country : ""))])]}},{key:"edit",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: _vm.$props.editLinkName, params: { contactId: item.id } }}},[_vm._v("Edit")])]}},{key:"noContents",fn:function(){return [_c('p',[_vm._v("There are no contacts entered for this client yet, please add one now!")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }