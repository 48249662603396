<template>
  <base-modal :id="invoiceModalName" :full="true">
    <validation-observer v-slot="validation">
      <sticky-header :title="'Create Custom Invoice'" :class="'module-large'">
        <div class="button-group">
          <button class="button primary" :disabled="validation.pristine || validation.invalid" @click="onSubmit">Next</button>
          <button class="button secondary" @click="cancelBtn">Cancel</button>
        </div>
      </sticky-header>

      <div class="container custom-invoice-container">
        <div class="content">
          <form style="max-width: 500px; width: 100%">
            <div class="filter-container client-filter-container">
              <p class="label">Select a Client*</p>
              <div class="filters client-filters">
                <div class="filter-options client-filter-options">
                  <div class="field">
                    <item-picker
                      no-title
                      :search-results="selectedClient"
                      :max-size="1"
                      placeholder="Type Client Name"
                      auto-select
                      :reset-picker="resetPicker"
                      @itemsChanged="updateClient"
                      @pickerReset="resetPicker = false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <form-input :value="invoiceName" :label="'Invoice Name'" required :name="'Invoice Name'" @input="updateInvoiceName($event)" />
            <label>Bill to Address *</label>
            <div class="white-inset">
              <div class="radio-custom">
                <input id="default" value="true" type="radio" name="addressType" :checked="useDefaultAddress" @change="updateAddress($event.target.value)" />
                <label for="default">
                  <span class="label-container">Default Client</span>
                </label>
              </div>
              <div class="radio-custom">
                <input id="custom" type="radio" name="addressType" value="false" :checked="!useDefaultAddress" @change="updateAddress($event.target.value)" />
                <label for="custom">
                  <span class="label-container">Custom</span>
                </label>
              </div>
            </div>
            <div v-if="!useDefaultAddress" class="white-inset">
              <main-address :value="addressForm.form" @input="createAddress"></main-address>
            </div>

            <div class="header-details-container">
              <h3>HEADER DETAILS</h3>
              <div class="invoice-date-picker margin-top-15">
                <div class="filter-input">
                  <label>Invoice Date*</label>
                  <div class="field">
                    <date-pick :value="invoiceDate" :display-format="'DD.MM.YYYY'" :format="'YYYY-MM-DD'" @input="updateInvoiceDate($event)">
                      <template #default="{ toggle }">
                        <div class="input-has-icon">
                          <div class="input-icon input-icon-max-width" @click="toggle"><i class="icon-calendar"></i></div>
                          <input id="startDate" :value="invoiceDate" type="text" placeholder readonly required @click="toggle" />
                        </div>
                      </template>
                    </date-pick>
                  </div>
                </div>
              </div>
              <div class="flex-begin-end-dates margin-top-15">
                <div class="filter-input flex-filter">
                  <label>Time Period Begin</label>
                  <div class="field">
                    <date-pick :value="periodStartDate" :display-format="'DD.MM.YYYY'" :format="'YYYY-MM-DD'" @input="updateStartDate($event)">
                      <template #default="{ toggle }">
                        <div class="input-has-icon">
                          <div class="input-icon input-icon-max-width" @click="toggle"><i class="icon-calendar"></i></div>
                          <input id="startDate" :value="periodStartDate" type="text" placeholder readonly required @click="toggle" />
                        </div>
                      </template>
                    </date-pick>
                  </div>
                </div>
                <div class="filter-input">
                  <label>Time Period End</label>
                  <div class="field">
                    <date-pick :value="periodEndDate" :display-format="'DD.MM.YYYY'" :format="'YYYY-MM-DD'" @input="updateEndDate($event)">
                      <template #default="{ toggle }">
                        <div class="input-has-icon">
                          <div class="input-icon input-icon-max-width" @click="toggle"><i class="icon-calendar"></i></div>
                          <input id="endDate" :value="periodEndDate" type="text" placeholder readonly required @click="toggle" />
                        </div>
                      </template>
                    </date-pick>
                  </div>
                </div>
              </div>
              <div class="invoice-due-date-picker margin-top-15">
                <div class="filter-input">
                  <label>Invoice Due Date</label>
                  <div class="field">
                    <date-pick :value="dueDate" :display-format="'DD.MM.YYYY'" :format="'YYYY-MM-DD'" @input="updateDueDate($event)">
                      <template #default="{ toggle }">
                        <div class="input-has-icon">
                          <div class="input-icon input-icon-max-width" @click="toggle"><i class="icon-calendar"></i></div>
                          <input id="startDate" :value="dueDate" type="text" placeholder readonly required @click="toggle" />
                        </div>
                      </template>
                    </date-pick>
                  </div>
                </div>
              </div>
              <form-input :value="term" :label="'Terms'" required :name="'Terms'" class="margin-top-15" @input="updateTerm($event)" />
            </div>
          </form>
        </div>
      </div>
    </validation-observer>
    <create-invoice-modal
      invoice-modal-name="create-invoice"
      :invoice-id="selectedInvoice"
      :start-date="periodStartDate"
      :end-date="periodEndDate"
      :date-invoiced="invoiceDate"
      :address-obj="addressForm"
      :default-address="useDefaultAddress"
      @resetForm="cancelBtn"
    />
  </base-modal>
</template>

<style>
.header-details-container .flex-begin-end-dates {
  display: flex;
}
.header-details-container .vdpComponent {
  display: block;
}
.header-details-container .filter-input {
  margin-right: 0;
}
.header-details-container .flex-filter {
  margin-right: 15px;
}
.margin-top-15 {
  margin-top: 15px;
}
.client-filters {
  display: block !important;
}
.client-filters .client-filter-options {
  display: block !important;
}
.client-filters .client-filter-options .spread-md {
  max-width: none;
}
.client-filters .client-filter-options .autocomplete-input {
  width: 100%;
  height: 36px;
}
.client-filters .client-filter-options .tags .tag {
  max-width: none;
  margin: -14px 0px 10px 0px;
  display: flex;
  border: 1px solid var(--gray-20);
  border-radius: 4px;
  height: 36px;
}
.custom-invoice-container .header-details-container .input-icon-max-width {
  padding-left: 90%;
}
</style>

<script>
import FormInput from "@/components/forms/fields/FormInput";
import StickyHeader from "@/components/layout/StickyHeader";
import { ValidationObserver } from "vee-validate";
import BaseModal from "@/components/modals/BaseModal";
import MainAddress from "@/components/setup/MainAddress";
import micromodal from "micromodal";
import ItemPicker from "@/components/pickers/ItemPicker";
import DatePick from "vue-date-pick";
import { GET_CLIENT_NAMES } from "@/modules/admin/accounting/archive/graph/queries";
import { CREATE_ADDRESS_FOR_INVOICE, CREATE_CUSTOM_INVOICE } from "@/modules/admin/accounting/archive/graph/mutations";
import CreateInvoiceModal from "@/modules/admin/accounting/archive/customInvoiceModal";

export default {
  name: "InvoiceModal",
  components: {
    BaseModal,
    StickyHeader,
    FormInput,
    ValidationObserver,
    MainAddress,
    ItemPicker,
    DatePick,
    CreateInvoiceModal,
  },
  props: {
    invoiceModalName: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      clientDetails: {
        type: Array,
        default: () => {},
      },
      invoiceTemplate: {
        type: Object,
        default: () => {},
      },
      addressForm: {
        form: {
          address1: "",
          address2: "",
          city: "",
          state: "",
          postalCode: "",
          country: "",
        },
      },
      client: "",
      clientId: "",
      invoiceName: "",
      useDefaultAddress: true,
      invoiceDate: "",
      periodStartDate: "",
      periodEndDate: "",
      dueDate: "",
      term: "",
      status: "APPROVED",
      customAddress: false,
      selectedInvoice: "",
      invoice: [],
      selectedLocations: [],
      selectedClient: [],
      resetPicker: false,
    };
  },
  methods: {
    updateClient(data) {
      if (data.itemAdded != null) {
        this.client = data.itemAdded.name;
        this.clientId = data.itemAdded.id;
        this.clientDetails = [{ clientName: this.client, id: this.clientId }];
      }
    },
    updateInvoiceName(value) {
      this.invoiceName = value;
    },
    updateInvoiceDate(value) {
      this.invoiceDate = value;
    },
    updateStartDate(value) {
      this.periodStartDate = value;
    },
    updateEndDate(value) {
      this.periodEndDate = value;
    },
    updateDueDate(value) {
      this.dueDate = value;
    },
    updateTerm(value) {
      this.term = value;
    },
    createCustomAddress() {
      return this.$apollo.mutate({ mutation: CREATE_ADDRESS_FOR_INVOICE, variables: { address: this.addressForm.form } });
    },
    async onSubmit() {
      this.invoice = {
        name: this.invoiceName,
        clientID: this.clientId,
        dueDate: this.dueDate,
        status: this.status,
        type: "CUSTOM",
        terms: this.term,
        createdAt: this.dueDate,
        invoice: { name: this.invoiceName, clientID: this.clientId, startDate: this.periodStartDate, endDate: this.periodEndDate },
      };
      if (!this.useDefaultAddress) {
        const { data } = await this.createCustomAddress();
        this.invoice.addressID = data.insert_addresses_one?.id;
      }
      this.$apollo
        .mutate({
          mutation: CREATE_CUSTOM_INVOICE,
          variables: {
            invoice: this.invoice,
          },
        })
        .then(({ data }) => {
          this.selectedInvoice = data.insert_invoices_one.id;
          micromodal.show("create-invoice", {
            onShow: function () {
              document.body.classList.add("no-scroll");
            },
            onClose: function () {
              document.body.classList.remove("no-scroll");
            },
          });
          document.body.style.overflow = "hidden";
        });
    },
    cancelBtn() {
      (this.client = ""),
        (this.clientId = ""),
        (this.invoiceName = ""),
        (this.invoiceDate = ""),
        (this.periodStartDate = ""),
        (this.periodEndDate = ""),
        (this.dueDate = ""),
        (this.term = ""),
        (this.resetPicker = true);
      micromodal.close("InvoiceArchiveCreateCustomInvoice");
      document.body.style.overflow = "visible";
    },
    updateAddress(data) {
      if (data == "true") {
        this.useDefaultAddress = true;
      } else {
        this.useDefaultAddress = false;
      }
    },
    createAddress(data) {
      this.addressForm.form = Object.assign(this.addressForm.form, data);
    },
  },
  apollo: {
    clients: {
      fetchPolicy: "no-cache",
      query: GET_CLIENT_NAMES,
      result({ data }) {
        this.selectedClient = data.clients;
      },
    },
  },
};
</script>
