<template>
  <div class="module-xlarge">
    <h3>Colors</h3>
    <div class="swatches">
      <div class="swatch">
        <div ref="brandColor" class="color-preview" :style="{ background: $props.color }"></div>
        <div class="color-value">
          <p>{{ $props.color }}</p>
        </div>
      </div>
    </div>
    <div ref="colorPicker" class="button secondary">Choose color</div>
  </div>
</template>
<script>
import Picker from "vanilla-picker";

export default {
  name: "ColorPicker",
  props: {
    color: {
      type: String,
      required: true,
      default: "",
    },
  },
  mounted() {
    const picker = new Picker(this.$refs.colorPicker);
    picker.setOptions({
      color: this.$refs.brandColor.style.background,
      popup: "top",
      cancelButton: true,
    });
    picker.onDone = (color) => {
      this.$emit("change", color.hex);
    };
  },
};
</script>
