<template>
  <validation-observer v-slot="validation" slim>
    <fragment>
      <slot name="header" :validation="validation" />
      <main :class="$props.className">
        <div class="container">
          <div class="content">
            <slot />
            <slot name="bottomContent" :validation="validation" />
          </div>
        </div>
        <pre v-if="$props.debugValidation">{{ JSON.stringify(validation, null, 2) }}</pre>
      </main>
    </fragment>
  </validation-observer>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import { Fragment } from "vue-fragment";

export default {
  name: "AddEditPage",
  components: { ValidationObserver, Fragment },
  props: {
    debugValidation: {
      type: Boolean,
      required: false,
      default: false,
    },
    className: {
      type: String,
      required: false,
      default: () => "",
    },
  },
};
</script>
