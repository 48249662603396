<template>
  <fragment>
    <Header v-if="showUI" />
    <slot></slot>
    <Footer v-if="showUI" />
  </fragment>
</template>

<script>
import Header from "@/components/layout/Header";
import Footer from "@/components/layout/Footer";
import { Fragment } from "vue-fragment";

export default {
  name: "HeaderFooter",
  components: { Footer, Header, Fragment },
  computed: {
    showUI() {
      return !this.$route.meta.hideUI;
    },
  },
};
</script>
