import { AdminLayout } from "@/components";
import ddi from "@/modules/admin/ddi/DdiSettingsPage";
export default [
  {
    path: "ddi",
    name: "ddi",
    component: ddi,
    meta: {
      breadcrumb: "ddi",
      layout: AdminLayout,
    },
  },
];
