<template>
  <fragment>
    <p v-for="communication in currentStep.comms" :key="communication.date" class="withdrawn">
      Resent Application Link<span class="date-time">{{ communication.date | formatDate }}</span>
      <a @click="viewCommunication(communication)">View <i class="icon icon-external-link"></i></a>
    </p>
  </fragment>
</template>
<script>
import { Fragment } from "vue-fragment";

export default {
  name: "CommunicationsStatus",
  components: { Fragment },
  props: {
    currentStep: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  methods: {
    viewCommunication(communication) {
      console.log("Not yet implemented!", communication);
    },
  },
};
</script>
