<template>
  <fragment>
    <div>
      <a class="button ghost" @click="toggle"> {{ $props.label }}<i class="right-icon" :class="{ 'icon-chevron-up': showing, 'icon-chevron-down': !showing }"> </i> </a>
    </div>
    <slot v-if="showing"></slot>
  </fragment>
</template>
<script>
import { Fragment } from "vue-fragment";

export default {
  components: { Fragment },
  props: {
    label: String,
    id: {
      type: String,
      required: false,
      default: "",
    },
  },
  data: () => ({
    showing: false,
  }),
  methods: {
    toggle() {
      this.showing = !this.showing;
    },
  },
};
</script>
