<template>
  <ul class="button-bar list-unstyled">
    <li v-for="button in buttons" :key="button.id" class="button secondary" :class="{ active: selectedButton.id === button.id }" @click="select(button)">{{ button.label }}</li>
    <slot></slot>
  </ul>
</template>
<script>
export default {
  name: "ButtonBar",
  props: {
    buttons: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedButton: this.$props.buttons[0] || {},
    };
  },
  methods: {
    select(button) {
      this.selectedButton = button;
      this.$emit("onSelect", button);
    },
  },
};
</script>
