import AdminPage from "@/modules/admin/AdminPage";
import adminGuard from "@/security/adminGuard";
import { AccountingRoutes } from "@/modules/admin/accounting";
import { ClientsRoutes } from "@/modules/admin/clients";
import { EmployeeRoutes } from "@/modules/admin/employees";
import { OnboardingRoutes } from "@/modules/admin/onboarding";
import { PartnerRoutes } from "@/modules/admin/partners";
import { ddiRoutes } from "@/modules/admin/ddi";
import { DocumentRoutes } from "@/modules/shared/documents";
import { ProfileRoutes } from "@/modules/admin/profile";
import { ReportRoutes } from "@/modules/admin/reports";

export default [
  {
    path: "/admin",
    name: "admin",
    redirect: "/",
    component: AdminPage,
    beforeEnter: adminGuard,
    children: [...AccountingRoutes, ...ClientsRoutes, ...EmployeeRoutes, ...OnboardingRoutes, ...PartnerRoutes, ...ddiRoutes, ...DocumentRoutes, ...ProfileRoutes, ...ReportRoutes],
  },
];
