import { AddEditLayout, AdminLayout } from "@/components";
import PartnerProfileEdit from "@/modules/admin/partners/profile/PartnerProfileEdit";

const PartnersPage = () => import("@/modules/admin/partners/PartnersPage");
const PartnerPage = () => import("@/modules/admin/partners/PartnerPage");
const AddPartnerPage = () => import("@/modules/admin/partners/AddPartner");
const AddTestPartner = () => import("@/modules/admin/partners/AddTestPartner")

export default [
  {
    path: "partners",
    name: "partners",
    component: PartnersPage,
    meta: { layout: AdminLayout },
  },
  {
    path: "partners/:id",
    name: "partner",
    component: PartnerPage,
    meta: { layout: AdminLayout },
  },
  {
    path: "partners/new",
    name: "addPartner",
    component: AddPartnerPage,
    meta: { layout: AddEditLayout },
  },
  {
    path: "partners/addTestPartner",
    name: "addTestPartner",
    component: AddTestPartner,
    meta: { layout: AddEditLayout },
  },
  {
    path: "partners/:id/edit",
    name: "editPartner",
    component: PartnerProfileEdit,
    meta: { layout: AddEditLayout },
  },
];
