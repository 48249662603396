<template>
  <div class="has-tooltip">
    <i v-if="$props.hover" :class="$props.iconClass" @mouseenter="showTooltip" @mouseleave="hideTooltip"></i>
    <i v-else v-click-outside="hideTooltip" :class="$props.iconClass" @click="showTooltip"></i>
    <div class="tooltip" :class="{ active: tooltip === $props.id }">
      <p class="tooltip-title">{{ $props.title }}</p>
      <p>{{ $props.description }}</p>
      <div v-if="$props.content" class="tooltip-content">
        <ul v-for="(item, index) in $props.content" :key="item.id">
          <li class="content-row">{{ calcLeft(item, index) }}</li>
          <li class="content-row">{{ calcRight(item) }}</li>
        </ul>
      </div>
      <table v-if="$props.datatable" class="">
        <tr v-for="(item, index) in $props.datatable" :key="item.id" class="row">
          <td class="cell50">{{ calcLeft(item, index) }}</td>
          <td class="cell50">{{ calcRight(item) }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import ClickOutside from "vue-click-outside";

export default {
  directives: { ClickOutside },
  props: {
    id: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      required: false,
      default: "Description",
    },
    description: {
      type: String,
      default: "",
    },
    content: {
      type: Array,
      default: () => [],
    },
    datatable: {
      type: Array,
      default: () => [],
    },
    range: {
      type: Array,
      default: () => [],
    },
    dataLeft: {
      type: String,
      default: "",
    },
    dataRight: {
      type: String,
      default: "",
    },
    hover: {
      type: Boolean,
      required: false,
      default: false,
    },
    minMax: {
      type: Boolean,
      required: false,
      default: false,
    },
    iconClass: {
      type: String,
      required: false,
      default: "icon-info",
    },
  },
  data: () => ({
    tooltip: "",
  }),
  methods: {
    showTooltip() {
      if (this.tooltip === this.$props.id) this.hideTooltip();
      else {
        this.tooltip = this.$props.id;
      }
    },
    hideTooltip() {
      this.tooltip = "";
    },
    calcLeft(item, index) {
      if (this.$props.minMax) {
        if (index === 0) {
          return "Less than " + item[this.$props.range[1]];
        } else if (index === this.$props.datatable.length - 1) {
          return "More than " + item[this.$props.range[0]];
        } else {
          return `${item[this.$props.range[0]]} - ${item[this.$props.range[1]]}`;
        }
      } else {
        return item[this.$props.dataLeft];
      }
    },
    calcRight(item) {
      if (this.$props.minMax) {
        return `$${item[this.$props.dataRight]}/partner`;
      } else {
        return item[this.$props.dataRight];
      }
    },
  },
};
</script>
