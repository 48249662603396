import { AdminLayout } from "@/components";

const OnboardingPage = () => import("@/modules/admin/onboarding/OnboardingPage");

export default [
  {
    path: "onboarding",
    name: "onboarding",
    component: OnboardingPage,
    meta: { layout: AdminLayout },
  },
];
