<template>
  <div v-if="isRadio(question)" class="field" :class="{ disabled: $props.disabled, error: disqualified(question) }">
    <p class="radio-label">{{ question.questionText }} {{ question.qualifier ? "*" : "" }}</p>
    <div class="radio-group">
      <div v-for="option in options(question)" :key="option.key" class="radio-custom">
        <p v-if="disqualified(question, option)" class="disqualified">Disqualifying</p>
        <input
          :id="`${question.key}-${option.optionText}`"
          :value="option.key"
          type="radio"
          :name="question.key"
          :checked="option.key === $props.value[`${question.key}`]"
          :disabled="$props.disabled"
          @change="update(question.key, $event.target.value)"
        />
        <label :for="`${question.key}-${option.optionText}`">
          <span class="label-container">
            <span class="inner">{{ option.optionText }}</span>
          </span>
        </label>
      </div>
    </div>
  </div>
  <form-input v-else-if="isText(question)" :value="value[`${question.key}`]" :disabled="$props.disabled" :label="question.questionText" @input="update(question.key, $event)" />
  <!-- <form-select v-else-if="isDropdown(question)" 
              :options="question.options"
              option-key="key"
              option-label ="optionText"
              label="How did you hear about this opportunity?"
              
              /> -->
  <div  v-else-if="isDropdown(question)"  class="field" >
    <p>How did you hear about this opportunity? *</p>
  <select selected="job-advertisement" :disabled="$props.disabled" @change="update(question.key, $event.target.value)">        
          <option value="referral" :selected="question.response === 'Referral' || question.response === 'referral'">Referral</option>
          <option value="job_ad" :selected="question.response === 'Job Ad' || question.response === 'job_ad'">Job Ad</option>
          <option value="social_media" :selected="question.response === 'Social Media'">Social Media</option>
           <option value="facebook" :selected="question.response === 'Facebook' || question.response === 'facebook'">Facebook</option>
           <option value="tiktok" :selected ="question.response ==='tiktok' || question.response === 'Tik Tok'">Tik Tok</option>
           <option value="internet" :selected ="question.response ==='internet' || question.response === 'Internet Search'">Internet Search</option>
           <option value="youtube" :selected ="question.response ==='youtube' || question.response === 'YouTube'">YouTube</option>
           <option value="email" :selected ="question.response ==='email' || question.response === 'Email'">Email</option>
           <option value="indeed" :selected ="question.response ==='indeed' || question.response === 'Indeed'">Indeed</option>
           <option value="glassdoor" :selected ="question.response ==='glassdoor' || question.response === 'Glass Door'">Glass door</option>
           <option value="nextdoor" :selected ="question.response ==='nextdoor' || question.response === 'Next Door'">Nextdoor</option>
           <option value="doordash" :selected ="question.response ==='doordash' || question.response === 'Doordash Driver'">DoorDash Driver</option>
           <option value="spark" :selected ="question.response ==='spark' || question.response === 'Spark Now Driver'">Spark Now Driver</option>
           <option value="uber" :selected ="question.response ==='uber' || question.response === 'Uber Driver'">Uber Driver</option>
           <option value="friend" :selected ="question.response ==='friend' || question.response === 'Friend or Family'">Friend or Family</option>
           <option value="store" :selected ="question.response ==='store' || question.response === 'In Store Recommendation'">In Store Recommendation</option>
           <option value="App Store" :selected ="question.response ==='App Store' || question.response === 'App Store'">App Store</option>
           <option value="apple" :selected ="question.response ==='apple' || question.response === 'Apple App Store'">Apple App Store</option>
           <option value="Google Play" :selected ="question.response ==='Google Play' || question.response === 'Google Play'">Google Play</option>
           <option value="other" :selected ="question.response == '' || question.response ==='other' || question.response === 'Other'">Other</option>
            <!-- <option value="walmart-store" :selected ="walmartSelectedKey(question.response)">Local Walmart Store</option>
            <option value="facebook" :selected ="otherSelectedKey(question.response)">Other</option> -->
    </select>
    <!-- <div v-if="displayWalmartStore" style="margin-top:10px">
      <p> Store Name/Location </p>
     <form-input  :value="selectedValue(question.response)" :disabled="true" @input="update(question.key, $event)" />
    </div>
     <div v-if="displayOtherDetails" style="margin-top:10px">
      <p> Other Details </p>
     <form-input  :value="selectedValue(question.response)" :disabled="true" @input="update(question.key, $event)" />
    </div> -->
  </div>
  <div  v-else-if="isCheckbox(question)"  class="field" >
    <div class="checkbox-container">{{ question.questionText }}</div>
    <p v-if="question.infoText" class="info-text">{{ question.infoText }}</p>

    <div
      class="checkbox-items"
      v-for="(item, index) in CheckboxOptions(question)"
      :key="index"
    >
      <input
        class="checkbox-input"
        type="checkbox"
        :value="item.value"
        v-model="item.value"
        :disabled="$props.disabled"
        @change="handelCheckboxChange(item.key, item.value)"
      /> 
      <label class="checkbox-label">{{ item.label }}</label
      ><br />
      <br />
    </div>

  </div>
  <div v-else>Invalid question type</div>
</template>
<script>
import { FormInput } from "@/components";

export default {
  components: { FormInput },
  data() {
    return {
        displayWalmartStore: false,
        displayOtherDetails: false
    }
  },
  props: {
    value: {
      type: Object,
      required: true,
      default: () => {},
    },
    question: {
      type: Object,
      required: true,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  mounted() {
    if(this.$props.question.key === 'howDidYouHearAboutOpportunity') {
       this.displayWalmartStore = this.$props.question.response.split('_')[0] === "walmart" ? true : false ;
       this.displayOtherDetails = this.$props.question.response.split('_')[0] === "other" ? true : false;
    }
  },
  methods: {
    dropdownChangeHandler($event) {
      if($event.target.value ==='walmart-store') {
        this.displayWalmartStore = true;
      } else {
        this.displayWalmartStore = false;
      }
      if($event.target.value === '') {
        this.displayOtherDetails = true;
      } else {
        this.displayOtherDetails = false
      }
    },
    isRadio(question) {
      return question.type === "radio";
    },
    isText(question) {
      return question.type === "text";
    },
    isDropdown(question) {
      return question.type ==="dropdown";
    },
    isCheckbox(question) {
      return question.type ==="chekcbox";
    },
    walmartSelectedKey(response) {
      return response.split('_')[0] === "walmart" ? true : false
    },
    selectedValue(response) {
      return response.split('_')[1]
    },
    otherSelectedKey(response) {
       return response.split('_')[0] === "other" ? true : false
    },
    disqualified(question, option) {
      if (question.qualifier) {
        const response = question.response;
        let filteredItems = [];
        if (option) {
          return !option.qualifies && option.key === response;
        } else {
          filteredItems = question.options.filter((option) => !option.qualifies && option.key === response);
        }
        return filteredItems.length > 0;
      }
    },
    update(key, value) {
      this.$emit("input", { ...this.$props.value, [key]: value });
    },
    options(question) {
      if (Array.isArray(question.options)) {
        return question.options;
      }
      return [];
    },
    CheckboxOptions(question) {
      let options = {};
      if (question.response != '') {
        options = question.response;
        if(!Array.isArray(question.response)) {
          options = question.response.replace(/([a-zA-Z0-9]+?):/g, '"$1":');
          options = JSON.parse(options.replace(/'/g, '"'));
        }
      }
      if (!Array.isArray(options)) {
        options = [
          { key: "compact", label: "Compact", value: false },
          { key: "sedan", label: "Sedan", value: false },
          { key: "suv", label: "SUV", value: false },
          { key: "van", label: "Van", value: false },
          { key: "pickup_truck", label: "Pick-Up Truck", value: false },
        ];
      }
      return options;
    },
    handelCheckboxChange(key, value){
      let options = {};
      if (this.$props?.question?.response != "") {
        options = this.$props.question.response;
        if(!Array.isArray(this.$props?.question?.response)) {
          options = this.$props.question.response.replace(/([a-zA-Z0-9]+?):/g, '"$1":');
          options = JSON.parse(options.replace(/'/g, '"'));
        }
        
      }
      if (!Array.isArray(options)) {
        options = [
          { key: "compact", label: "Compact", value: false },
          { key: "sedan", label: "Sedan", value: false },
          { key: "suv", label: "SUV", value: false },
          { key: "van", label: "Van", value: false },
          { key: "pickup_truck", label: "Pick-Up Truck", value: false },
        ];
      }
      for(let i = 0; i < options.length; i++) {
        if(options[i].key == key)
          options[i].value = value;
      }
      this.$props.question.response = options;
      this.update(this.$props.question.key, JSON.stringify(options));
    }
  },
};
</script>
