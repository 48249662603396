<template>
  <div v-if="$props.list && $props.list.length <= 0" class="module-large">
    <h3>{{ componentLabel }} Assignments</h3>
    <p>No {{ componentLabel.toLowerCase() }}s to assign</p>
  </div>
  <div v-else class="module-large">
    <h3>{{ componentLabel }} Assignments</h3>
    <div class="multi-input-group">
      <div class="inner no-left-space">
        <div class="radio-custom">
          <input id="location-assignments-all" :checked="radioSelect === 'all'" value="all" type="radio" name="location-assignments" @change="changeSelection" />
          <label for="location-assignments-all">
            <span class="label-container">
              All {{ componentLabel.toLowerCase() }}s
              <span class="secondary-line">{{ componentLabel }}s added in the future will be automatically assigned</span>
            </span>
          </label>
        </div>
        <div class="radio-custom">
          <input id="location-assignments-some" :checked="radioSelect === 'some'" value="some" type="radio" name="location-assignments" @change="changeSelection" />
          <label for="location-assignments-some">
            <span class="label-container">
              Only Certain {{ componentLabel }}s<span class="secondary-line">Select from current set of {{ componentLabel.toLowerCase() }}s only</span>
            </span>
          </label>
        </div>
      </div>
    </div>
    <div v-if="radioSelect === 'some'">
      <div class="checkbox-custom">
        <input id="select-all" v-model="checkbox" type="checkbox" @change="toggleSelectAll" />
        <label for="select-all"><span class="label-container">Select All</span> </label>
      </div>
      <div class="grid">
        <div class="col-8">
          <div class="field">
            <label> <input v-model="filter" type="text" :placeholder="placeholder" /></label>
          </div>
        </div>
      </div>
      <div v-for="item in filteredList" :key="item.id" class="checkbox-list">
        <div class="checkbox-custom">
          <input :id="item.id" v-model="assignedList" :value="item.id" :disabled="noTemplate(item)" type="checkbox" @change="emit" />
          <label :for="item.id">
            <span class="label-container">{{ item.name }}</span>
          </label>
        </div>
      </div>
    </div>
    <p class="light">
      <span v-if="radioSelect === 'some'">{{ assignedList.length }} </span>
      <span v-else>All </span>
      <span v-if="assignedList.length === 1">{{ componentLabel }}</span>
      <span v-else>{{ componentLabel }}s</span> Selected
    </p>
  </div>
</template>
<script>
export default {
  name: "AssignmentComponent",
  props: {
    client: { type: Boolean, required: false, default: false },
    list: {
      type: Array,
      required: true,
      default: () => [],
    },
    assignments: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data: function () {
    return {
      radioSelect: "some",
      assignedList: [],
      notAssignedList: [],
      filter: "",
      checkbox: false,
    };
  },
  computed: {
    placeholder() {
      return `Filter - ${this.componentLabel} Name`;
    },
    filteredList() {
      if (this.$props.list) {
        return this.$props.list.filter((item) => item.name.toLowerCase().includes(this.filter.toLowerCase()));
      }
      return [];
    },
    componentLabel() {
      return this.$props.client ? "Client" : "Location";
    },
  },
  watch: {
    assignments(newValue) {
      this.assignedList = [...newValue];
      this.checkbox = false;
      this.$emit("assignmentChange");
    },
  },
  mounted() {
    setTimeout(() => {
      this.assignedList = [...this.$props.assignments];
      this.emit();
    }, 500);
  },
  methods: {
    emit() {
      if (this.filteredList && this.filteredList.length !== this.assignedList.length) {
        this.checkbox = false;
      }
      if (this.$props.list.length === this.assignedList.length && this.checkbox === false) {
        this.radioSelect = "all";
      }
      this.$emit("change", this.assignedList);
    },
    toggleSelectAll() {
      if (this.checkbox) {
        this.assignedList = this.filteredList.filter((item) => !this.noTemplate(item)).map((item) => item.id);
      } else {
        this.assignedList = [];
      }
      this.$emit("change", this.assignedList);
    },
    changeSelection(event) {
      this.radioSelect = event.target.value;
      if (event.target.value === "all") {
        this.assignedList = this.list.map((item) => item.id);
        this.$emit("change", this.assignedList);
      } else {
        this.assignedList = [];
        this.checkbox = false;
        this.filterText = "";
        this.$emit("change", this.assignedList);
      }
    },
    noTemplate(item) {
      if (item.templates) {
        return item.templates.aggregate.count === 0;
      }
      return false;
    },
  },
};
</script>
