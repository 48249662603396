<template>
  <fragment>
    <div v-show="!isMaxSize()" class="spread-md">
      <div class="field">
        <slot v-if="!noTitle" name="title">
          <h3 v-show="$props.itemLabel">{{ $props.itemLabel }}</h3>
        </slot>
        <autocomplete ref="autocomplete" auto-select :search="search" :get-result-value="getResultValue" :placeholder="$props.placeholder" @submit="handleSubmission" />
      </div>
    </div>
    <p v-if="$props.displayAddItemButton">
      <a :tabindex="$props.addButtonTabIndex" :class="{ hidden: !item.id }" @click="addItem(item)"><i class="icon-plus-circle"></i>{{ $props.addItemLabel }}</a>
    </p>
    <slot name="results" :items="items">
      <validation-provider ref="validationProvider" :name="$props.itemLabel" slim>
        <div class="tags">
          <div v-for="item in validateItems()" :key="item.id" class="tag">
            <div class="info">
              <p>{{ item.name }}</p>
            </div>
            <div class="close" @click="removeItem(item)"><i class="icon-x-circle"></i></div>
          </div>
        </div>
      </validation-provider>
    </slot>
  </fragment>
</template>
<script>
import { Fragment } from "vue-fragment";
import Autocomplete from "@trevoreyre/autocomplete-vue";
import { ValidationProvider } from "vee-validate";

export default {
  name: "ItemPicker",
  components: { Fragment, Autocomplete, ValidationProvider },
  props: {
    displayAddItemButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    itemLabel: {
      type: String,
      required: false,
      default: "Item",
    },
    addItemLabel: {
      type: String,
      required: false,
      default: "Add Item",
    },
    placeholder: {
      type: String,
      required: false,
      default: "Type item name",
    },
    selected: {
      type: Array,
      required: false,
      default: () => [],
    },
    searchResults: {
      type: Array,
      required: true,
      default: () => [],
    },
    maxSize: {
      type: Number,
      required: false,
      default: -1,
    },
    addButtonTabIndex: {
      type: Number,
      required: false,
      default: -1,
    },
    autoSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    noTitle: {
      type: Boolean,
      required: false,
      default: false,
    },
    resetPicker: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => {
    return {
      item: {},
      items: [],
      itemsLength: 0,
      initialized: false,
    };
  },
  watch: {
    selected(newValue) {
      if (newValue && !this.initialized) {
        newValue.forEach((item) => {
          if (item) {
            this.addItem(item);
          }
        });
        this.itemsLength = this.items.length;
        this.initialized = true;
      }
    },
    searchResults(newValue) {
      // new search results means we need to refresh the list
      if (newValue !== this.searchResults) {
        this.item = {};
        this.items = [];
        this.itemsLength = 0;
      }
    },
    resetPicker(val) {
      if (val == true) {
        this.reset();
        this.$emit("pickerReset");
      }
    },
  },
  mounted() {
    if (this.$props.selected) {
      this.$props.selected.forEach((item) => {
        if (item) {
          this.addItem(item);
        }
      });
    }
  },
  methods: {
    search(input) {
      return this.searchResults.filter((item) => {
        const itemExists = this.items.filter((existingItem) => existingItem.id === item.id);
        return item.name.toLowerCase().includes(input.toLowerCase()) && itemExists.length === 0;
      });
    },
    addItem(item) {
      if (this.items.findIndex((listItem) => listItem.id === item.id) < 0) {
        this.items.push(item);
        this.item = {};
        this.$refs.autocomplete.value = "";
        const itemChangedEvent = {
          items: this.items,
          itemAdded: item,
          itemRemoved: null,
        };
        this.$emit("itemsChanged", itemChangedEvent);
      }
    },
    removeItem(item) {
      this.items = this.items.filter((selectedItem) => selectedItem.id !== item.id);
      const itemChangedEvent = {
        items: this.items,
        itemAdded: null,
        itemRemoved: item,
      };
      this.$emit("itemsChanged", itemChangedEvent);
    },
    getResultValue(item) {
      return `${item.name}`;
    },
    handleSubmission(event) {
      if (event && event.id) {
        this.item = event;
        if (this.$props.autoSelect) {
          this.addItem(event);
        }
        this.$refs.autocomplete.$refs.input.blur();
      }
    },
    isMaxSize() {
      return this.$props.maxSize > -1 && this.items.length >= this.$props.maxSize;
    },
    validateItems() {
      const length = this.items.length;
      if (this.$refs.validationProvider && length !== this.itemsLength) {
        this.$refs.validationProvider.setFlags({ dirty: true, pristine: false, touched: true, untouched: false, valid: true, invalid: false });
        this.itemsLength = length;
      }
      return this.items;
    },
    reset() {
      this.items.forEach((item) => this.removeItem(item));
    },
  },
};
</script>
