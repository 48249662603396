<template>
  <fragment>
    <h1>IIF Comparison</h1>
    <loader v-if="loading" />
    <div v-else>
      <div class="main-container">
        <div class="legacy-container">
          <label>Upload legacy IIF File</label>
          <input id="myfile" type="file" name="myfile" @change="legacyFile" />
        </div>
        <div>
          <label>Upload new IIF File</label>
          <input id="myfile" type="file" name="myfile" @change="newFile" />
        </div>
      </div>
      <div class="container-btn">
        <button class="button secondary" @click="compareFiles">Compare Both files</button>
      </div>
      <div class="response-msgs">
        <div v-if="isMatched" class="success">Both files are matched</div>
        <div v-if="isNotMatched" class="failed">Both files are different</div>
        <div v-if="invalidFile" class="failed">Invalid file format</div>
      </div>
    </div>
  </fragment>
</template>
<style scoped>
.main-container {
  display: flex;
  justify-content: center;
}
.main-container .legacy-container {
  margin-right: 100px;
}
.container-btn {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.response-msgs {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  margin-top: 15px;
}
.response-msgs .success {
  color: seagreen;
}
.response-msgs .failed {
  color: brown;
}
</style>
<script>
import XLSX from "xlsx";
import { Loader } from "@/components";
import { Fragment } from "vue-fragment";
export default {
  name: "IifTesting",
  components: {
    Fragment,
    Loader,
  },
  data: () => ({
    legacyIIF: [],
    newIIF: [],
    isMatched: false,
    isNotMatched: false,
    loading: false,
    invalidFile: false,
  }),

  computed: {},
  methods: {
    legacyFile(e) {
      const that = this;
      var files = e.target.files,
        f = files[0];
      let fileName = files[0].name.split(".");
      let ext = fileName[fileName.length - 1];
      if (ext == "IIF" || ext == "iif" || ext == "csv") {
        this.invalidFile = false;
        var reader = new FileReader();
        reader.onload = function (e) {
          that.loading = false;
          var data = new Uint8Array(e.target.result);
          var workbook = XLSX.read(data, { type: "array" });
          let sheetName = workbook.SheetNames[0];
          let worksheet = workbook.Sheets[sheetName];
          let obj = XLSX.utils.sheet_to_json(worksheet);
          that.insertData("legacyfile", obj, {});
        };
        reader.readAsArrayBuffer(f);
      } else {
        this.isMatched = false;
        this.isNotMatched = false;
        this.invalidFile = true;
      }
    },

    newFile(e) {
      const that = this;
      var files = e.target.files,
        f = files[0];
      let fileName = files[0].name.split(".");
      let ext = fileName[fileName.length - 1];
      if (ext == "IIF" || ext == "iif" || ext == "csv") {
        this.invalidFile = false;
        var reader = new FileReader();
        reader.onload = function (e) {
          that.loading = false;
          var data = new Uint8Array(e.target.result);
          var workbook = XLSX.read(data, { type: "array" });
          let sheetName = workbook.SheetNames[0];
          let worksheet = workbook.Sheets[sheetName];
          let obj = XLSX.utils.sheet_to_json(worksheet);
          that.insertData("newfile", {}, obj);
        };
        reader.readAsArrayBuffer(f);
      } else {
        this.isMatched = false;
        this.isNotMatched = false;
        this.invalidFile = true;
      }
    },

    insertData(filetype, legacyObj, newObj) {
      if (filetype === "legacyfile") {
        this.legacyIIF = legacyObj;
      } else if (filetype === "newfile") {
        this.newIIF = newObj;
      }
    },
    compareFiles() {
      if (this.legacyIIF.length != this.newIIF.length) {
        this.isNotMatched = true;
      } else {
        this.legacyIIF.forEach((e1, index1) => {
          this.newIIF.forEach((e2, index2) => {
            if (index1 == index2) {
              if (JSON.stringify(e1) === JSON.stringify(e2)) {
                this.isMatched = true;
                this.isNotMatched = false;
              } else {
                this.isMatched = false;
                this.isNotMatched = true;
              }
            }
          });
        });
      }
    },
  },
};
</script>
