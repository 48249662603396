<template>
  <InputSwitch v-model="switchStatus" @input="statusChange" :disabled="$props.disable" />
</template>
<script>
import InputSwitch from 'primevue/inputswitch';

export default {
  name: "SlideSwitch",
  components: { InputSwitch },
  props: {
    status: { 
      type: Boolean, 
      required: false, 
      default: false
    },
    disable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
        switchStatus: this.$props.status,
    }
  },
  methods: {
    statusChange(status) {
      this.$emit("statusChange", { status });
    } 
  },
};
</script>

<style scoped>
.p-inputswitch {
  height: 1.65rem;
}
</style>
