import { store } from "@/store";
import { authenticationService } from "@/services";

const Reset = () => import("@/modules/login/Reset");
const Login = () => import("@/modules/login/Login");
const EmployeeLogin = () => import("@/modules/login/EmployeeLogin");

export default [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { hideBanner: true, open: true },
  },
  {
    path: "/login/employee",
    name: "employeeLogin",
    component: EmployeeLogin,
    meta: { hideBanner: true, open: true },
  },
  {
    path: "/reset",
    name: "reset",
    component: Reset,
    meta: { hideBanner: true, open: true },
  },
  {
    path: "/logout",
    name: "logout",
    component: {
      beforeRouteEnter(to, from, next) {
        authenticationService.logout().then(() => {
          store.dispatch("userLogout");
          store.dispatch("clearOnboarding");
          next({ name: "login" });
        });
      },
    },
  },
];
