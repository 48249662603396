import gql from "graphql-tag";

export const DOCUMENT_OVERRIDE_REMOVE = gql`
  mutation SetRemoveOverride($objects: [documentTemplateOverrides_insert_input!]!) {
    insert_documentTemplateOverrides(objects: $objects) {
      affected_rows
    }
  }
`;

export const CLEAR_REMOVE_CLIENT_OVERRIDE = gql`
  mutation ClearRemoveOverrideClient($clientList: [uuid!], $templateID: uuid) {
    delete_documentTemplateOverrides(where: { clientID: { _in: $clientList }, templateID: { _eq: $templateID }, type: { _eq: "REMOVE" } }) {
      affected_rows
    }
  }
`;
export const CLEAR_REMOVE_LOCATION_OVERRIDE = gql`
  mutation ClearRemoveOverrideLocation($locationList: [uuid!], $templateID: uuid) {
    delete_documentTemplateOverrides(where: { locationID: { _in: $locationList }, templateID: { _eq: $templateID }, type: { _eq: "REMOVE" } }) {
      affected_rows
    }
  }
`;
export const ADD_REMOVE_CLIENT_OVERRIDE = gql`
  mutation MyMutation($objects: [documentTemplateOverrides_insert_input!]!) {
    insert_documentTemplateOverrides(objects: $objects) {
      affected_rows
    }
  }
`;

export const INSERT_TEMPLATE = gql`
  mutation InsertDocumentTemplate(
    $id: uuid
    $text: jsonb
    $version: Int
    $title: String
    $updatedBy: String
    $originalID: uuid
    $isDDI: Boolean
    $previousID: uuid
    $templateStatus: String
    $clientID: uuid
    $locationID: uuid
    $html: String
    $optional: Boolean
  ) {
    template: insert_documentTemplates_one(
      object: {
        id: $id
        text: $text
        version: $version
        title: $title
        updatedBy: $updatedBy
        originalID: $originalID
        isDDI: $isDDI
        previousID: $previousID
        status: $templateStatus
        html: $html
        clientID: $clientID
        locationID: $locationID
        optional: $optional
      }
    ) {
      id
    }
  }
`;

export const UPDATE_DOCUMENT_TEMPLATE = gql`
  mutation UpdateDocumentTemplate($id: uuid!, $title: String, $text: jsonb, $updatedBy: String, $html: String, $optional: Boolean) {
    update_documentTemplates_by_pk(pk_columns: { id: $id }, _set: { title: $title, text: $text, updatedBy: $updatedBy, html: $html, optional: $optional }) {
      id
    }
  }
`;

export const DELETE_CLIENT_TEMPLATE_USE = gql`
  mutation DeleteTemplateUse($clientID: uuid, $templateID: uuid) {
    delete_documentTemplateUse(where: { clientID: { _eq: $clientID }, templateID: { _eq: $templateID }, status: { _eq: "inherited" } }) {
      affected_rows
    }
  }
`;
export const DELETE_LOCATION_TEMPLATE_USE = gql`
  mutation DeleteTemplateUse($clientLocationID: uuid, $templateID: uuid) {
    delete_documentTemplateUse(where: { clientLocationID: { _eq: $clientLocationID }, templateID: { _eq: $templateID }, status: { _eq: "inherited" } }) {
      affected_rows
    }
  }
`;

export const ADD_TEMPLATE_USE = gql`
  mutation InsertDocumentTemplateUse($objects: [documentTemplateUse_insert_input!]!) {
    insert_documentTemplateUse(on_conflict: { constraint: documentTemplateUse_templateID_partnerID_key, update_columns: [clientID, clientLocationID, status] }, objects: $objects) {
      affected_rows
    }
  }
`;

export const UPDATE_TEMPLATE_USE_STATUS = gql`
  mutation updateTemplateUseStatus($id: uuid!, $status: String!) {
    update_documentTemplateUse_by_pk(pk_columns: { id: $id }, _set: { status: $status }) {
      id
    }
  }
`;

export const UPDATE_TEMPLATE_STATUS = gql`
  mutation updateTemplateStatus($id: uuid!, $status: String!) {
    update_documentTemplates_by_pk(pk_columns: { id: $id }, _set: { status: $status }) {
      id
    }
  }
`;

export const ENABLE_TEMPLATE = gql`
  mutation EnableTemplateAndAddInheritance($id: uuid, $disabled: Boolean) {
    update_documentTemplates(where: { _or: [{ id: { _eq: $id } }, { originalID: { _eq: $id } }] }, _set: { disabled: $disabled }) {
      returning {
        client {
          locations {
            id
          }
        }
      }
    }
  }
`;
export const DELETE_DOCUMENT_TEMPLATE = gql`
  mutation DeleteDocumentTemplates($templateID: uuid!) {
    delete_documentTemplates_by_pk(id: $templateID) {
      id
    }
  }
`;

export const REMOVE_FROM_PARTNERS = gql`
  mutation removeFromPartners($templateID: uuid!, $clientLocationID: uuid!) {
    delete_documentTemplateUse(where: { templateID: { _eq: $templateID }, clientLocationID: { _eq: $clientLocationID }, partnerID: { _is_null: false } }) {
      affected_rows
    }
  }
`;

export const REMOVE_FROM_LOCATION_AND_PARTNERS = gql`
  mutation RemoveFromLocationAndPartners($templateID: uuid!, $clientLocationID: uuid!) {
    delete_documentTemplateUse(where: { templateID: { _eq: $templateID }, clientLocationID: { _eq: $clientLocationID } }) {
      affected_rows
    }
  }
`;

export const REMOVE_FROM_CLIENT_AND_LOCATIONS_AND_PARTNERS = gql`
  mutation RemoveFromLocationAndPartners($templateID: uuid, $clientID: uuid) {
    delete_documentTemplateUse(where: { templateID: { _eq: $templateID }, clientID: { _eq: $clientID } }) {
      affected_rows
    }
  }
`;

export const REMOVE_EXISTING_LOCATIONS = gql`
  mutation deleteExistingLocations($cid: uuid, $tid: uuid, $clid: [uuid!]) {
    delete_documentTemplateUse(where: { _and: { clientID: { _eq: $cid }, templateID: { _eq: $tid }, status: { _neq: "orginal" }, clientLocationID: { _in: $clid } } }) {
      affected_rows
      returning {
        id
        clientLocationID
      }
    }
  }
`;

export const UPDATE_LOCATIONS = gql`
  mutation updatingLocations($obj: [documentTemplateUse_insert_input!]!) {
    insert_documentTemplateUse(objects: $obj) {
      affected_rows
      returning {
        id
        clientLocationID
      }
    }
  }
`;

export const FETCH_FUNNEL_ID = gql`
  query fetchFunnelID($cid: uuid!, $documentsTemplateUseId: uuid!) {
    clientLocations(where: { _and: { id: { _eq: $cid }, funnel: { funnelDocuments: { documentTemplateUseID: { _eq: $documentsTemplateUseId } } } } }) {
      funnel {
        funnelDocuments {
          documentTemplateUseID
          funnelID
        }
      }
    }
  }
`;

export const CHANGE_DOCUMENT_STATUS = gql`
  mutation ChangeDocumentStatus($templateID: uuid!, $status: String) {
    update_documentTemplates_by_pk(pk_columns: { id: $templateID }, _set: { status: $status }) {
      id
    }
  }
`;
