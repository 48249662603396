<template>
  <create-edit-document save-label="Create" :copy-template-id="documentID" @onSave="saveDocument" />
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import CreateEditDocument from "@/modules/shared/documents/CreateEditDocument";
import { mapGetters } from "vuex";
import documentService from "@/services/document.service";

export default {
  name: "CreateLocationDocument",
  components: { CreateEditDocument },
  props: {
    clientID: {
      type: String,
      required: false,
      default: () => null,
    },
    locationID: {
      type: String,
      required: false,
      default: () => null,
    },
    inherited: {
      type: Boolean,
      required: false,
      default: false,
    },
    documentID: {
      type: String,
      required: false,
      default: () => null,
    },
  },
  data: () => {
    return {
      version: 1,
      original: true,
    };
  },
  computed: {
    ...mapGetters(["getFullName"]),
    originalTemplateID() {
      if (this.original) {
        return this.templateID;
      } else {
        return this.documentTemplate ? this.documentTemplate.originalID : null;
      }
    },
  },
  methods: {
    insertTemplate(template) {
      const variables = {
        id: template.id,
        title: template.title,
        originalID: template.id,
        version: 1,
        updatedBy: this.getFullName,
        text: template.text,
        html: template.html,
        isDDI: false,
        clientID: this.$props.clientID,
        locationID: this.$props.locationID,
        templateStatus: template.status,
      };
      documentService.createDocumentTemplate(variables);
    },
    async saveDocument(event) {
      const id = uuidv4();
      const template = event.template;
      template.clientID = this.$route.query.clientID;
      template.updatedBy = this.getFullName;
      template.id = id;

      await this.insertTemplate(template);
      this.$router.back();
    },
  },
};
</script>
