
class PollingService {
  constructor() {
  }

  startPolling(execFunction, interval, params) {
    return setInterval(execFunction, interval, ...params);
  }

  clearPolling(intervalName) {
    clearInterval(intervalName);
  }

}

export default new PollingService();
