<template>
  <validation-observer ref="form" v-slot="validation">
    <flyout name="edit-invoice" title="Add Client Invoice Item" :is-overlay="true">
      <h3>DETAILS</h3>
      <form ref="form">
        <div class="field">
          <validation-provider v-slot="{ errors }" name="Chart of Accounts">
            <label>Chart of Accounts*</label>
            <input v-model="addLineItem.chartOfAccounts" :class="{ 'has-error': errors.length > 0, inline: $props.inlineLabel }" required type="text" placeholder="" />
            <p class="error-message">{{ errors[0] }}</p>
          </validation-provider>
        </div>
        <div class="field">
          <validation-provider v-slot="{ errors }" name="Memo">
            <label>Memo*</label>
            <input v-model="addLineItem.memo" :class="{ 'has-error': errors.length > 0, inline: $props.inlineLabel }" required type="text" placeholder="" />
            <p class="error-message">{{ errors[0] }}</p>
          </validation-provider>
        </div>
        <div class="field">
          <validation-provider v-slot="{ errors }" name="Amount">
            <label>Amount*</label>
            <input v-model="addLineItem.amount" :class="{ 'has-error': errors.length > 0, inline: $props.inlineLabel }" required type="number" placeholder="$ 0.0" />
            <p class="error-message">{{ errors[0] }}</p>
          </validation-provider>
        </div>
        <div class="button-group module-large close-top">
          <button class="button" :disabled="validation.invalid" @click="save" @click.prevent>Save</button>
          <cancel-button :cancel-action="() => showFlyout('')" @click="cancel" />
        </div>
      </form>
    </flyout>
  </validation-observer>
</template>
<script>
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import Flyout from "@/components/flyouts/Flyout";
import { mapActions } from "vuex";
import { CancelButton } from "@/components";
import { restApi } from "../../../../http/http.config";
import { encodeWithParam } from "../../../../util/Base64Encoding";

extend("required", {
  message: (field) => `${field} is required*.`,
});

export default {
  name: "EditableInvoiceFlyout",
  components: { Flyout, ValidationProvider, ValidationObserver, CancelButton },
  props: {
    lineItemID: String,
    invoiceID: String,
    editingDefaultItem: Boolean,
    addLineItems: {type: Function },
    itemEditCancel: { type: Function },
    fetchInvoice: {type: Function },
    getClientInvoicing: { type: Function },
    tempLineItem: Object,
    originalLineItem: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data() {
    return {
      addLineItem: this.$props.originalLineItem,
      originalAmount: this.$props.originalLineItem.amount
    };
  },
  methods: {
    ...mapActions(["showFlyout"]),
    createUUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
          return v.toString(16);
      });
    },
    cancel() {
      return this.addLineItem.amount = this.originalAmount
    },
    save() {
      this.addLineItem.amount = Number(this.addLineItem.amount).toFixed(2);
      this.addLineItem?.default == true ? this.$emit("edit", this.addLineItem) : this.$emit("save", this.addLineItem);

      if (this.$props.editingDefaultItem) {
        restApi.post('accounting/editInvoiceAmount', encodeWithParam({
          invoiceID: this.$props.invoiceID, 
          lineItemID: this.$props.lineItemID,
          amount: this.addLineItem.amount
        })).then(()=>{
        }).catch((e) => {console.log(e)})

        restApi.post('accounting/editInvoiceMemo', encodeWithParam({
          invoiceID: this.$props.invoiceID, 
          lineItemID: this.$props.lineItemID,
          memo: this.addLineItem.memo
        })).then(()=>{
        }).catch((e) => {console.log(e)})

        restApi.post('accounting/editInvoiceChart', encodeWithParam({
          invoiceID: this.$props.invoiceID, 
          lineItemID: this.$props.lineItemID,
          chartOfAccounts: this.addLineItem.chartOfAccounts
        })).then(()=>{
        }).catch((e) => {console.log(e)})


      } else {
        let tempLineItem = this.addLineItem
        tempLineItem["id"] = this.createUUID()
        restApi.post('accounting/addLineItems', encodeWithParam({
          invoiceID: this.$props.invoiceID, 
          tempLineItem: tempLineItem
        })).then(()=>{
          this.$props.itemEditCancel();
          this.$props.addLineItems(tempLineItem)
        }).catch((e) => {console.log(e)})
      }
      
      this.$props.getClientInvoicing();
      this.$props.fetchInvoice(this.$props.invoiceID)
      this.$refs.form.reset();
      this.showFlyout("");
    },
  },
};
</script>
