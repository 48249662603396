import gql from "graphql-tag";

export const UPDATE_SETTLEMENT = gql`
  mutation updateSettlement($settlementID: uuid!, $settlement: settlements_set_input!) {
    update_settlements_by_pk(_set: $settlement, pk_columns: { id: $settlementID }) {
      id
    }
  }
`;

export const APPROVE_INVOICE = gql`
  mutation approveInvoice($invoiceId: uuid!, $status: String) {
    update_invoices_by_pk(pk_columns: { id: $invoiceId }, _set: { status: $status }) {
      id
    }
  }
`;

export const ADD_LINEITEM = gql`
  mutation lineItmes($invoiceId: uuid!, $invoice: jsonb) {
    update_invoices_by_pk(pk_columns: { id: $invoiceId }, _set: { invoice: $invoice }) {
      id
    }
  }
`;

export const DEPOSIT_SUMMARY_ADD_LINEITEM = gql`
  mutation lineItmes($id: uuid!, $check: jsonb) {
    update_depositSummaries_by_pk(pk_columns: { id: $id }, _set: { check: $check }) {
      id
    }
  }
`;

export const SET_DISTRIBUTION_TOTAL = gql`
  mutation setDistributionTotalL($id: uuid, $totalPayout: numeric) {
    update_distributions(where: { id: { _eq: $id } }, _set: { totalPayout: $totalPayout }) {
      affected_rows
    }
  }
`;

export const CHANGE_DEPOSIT_SUMMARY_STATUS = gql`
  mutation depositSummaryStatus($id: uuid, $status: String) {
    update_depositSummaries(where: { id: { _eq: $id } }, _set: { status: $status }) {
      affected_rows
    }
  }
`;
