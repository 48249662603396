import { AdminLayout } from "@/components";
import { withPrefix } from "@/util/routeHelper";
import { LocationContactRoutes } from "@/modules/admin/locations/contacts";

const EditDocument = () => import("@/modules/shared/documents/EditDdiDocument");
const Location = () => import("@/modules/admin/locations/Location");
const LocationEditSetup = () => import("@/modules/admin/locations/tabs/LocationEditSetup");

export default [
  {
    path: "locations/:locationID",
    name: "location",
    component: Location,
    meta: { layout: AdminLayout },
    props: (route) => ({
      clientID: route.params.id,
      locationID: route.params.locationID,
    }),
  },
  {
    path: "locations/:locationID/edit",
    name: "locationEditSetup",
    component: LocationEditSetup,
    meta: { hideUI: false },
    props: (route) => ({
      clientID: route.params.id,
      locationID: route.params.locationID,
    }),
  },
  {
    path: "locations/:locationID/documents/edit",
    name: "editLocationDocument",
    component: EditDocument,
    props: (route) => ({
      clientID: route.params.id,
      locationID: route.params.locationID,
      documentID: route.query.documentID,
    }),
    meta: { hideUI: true },
  },
  ...withPrefix("locations/:locationID", "", LocationContactRoutes),
];
