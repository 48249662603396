import { render, staticRenderFns } from "./IifTesting.vue?vue&type=template&id=3333efc1&scoped=true&"
import script from "./IifTesting.vue?vue&type=script&lang=js&"
export * from "./IifTesting.vue?vue&type=script&lang=js&"
import style0 from "./IifTesting.vue?vue&type=style&index=0&id=3333efc1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3333efc1",
  null
  
)

export default component.exports