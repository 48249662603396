<template>
  <div class="module" :style="{ height: '875px' }">
    <EmailEditor ref="documentEditor" min-height="300px" :options="options" :tools="tools" display-mode="web" @load="editorLoaded" />
    <select-image-modal />
  </div>
</template>

<script>
import EmailEditor from "@/components/editor/EmailEditor";
import { default as mergeTags } from "@/components/editor/mergeTags";
import SelectImageModal from "@/components/editor/SelectImageModal";
import micromodal from "micromodal";

export default {
  name: "NewEditor",
  components: {
    SelectImageModal,
    EmailEditor,
  },
  props: {
    content: {
      type: Object,
      required: false,
    },
  },
  emits: ["json", "html"],
  data() {
    return {
      options: {
        editorOptions: {},
        safeHTML: true,
        mergeTags,
        features: {
          backgroundColor: "#ffffff",
          textEditor: {
            spellChecker: true,
            cleanPaste: false,
            tables: true,
            emojis: false,
          },
        },
        designTags: {
          business_name: "Tesla Inc",
          current_user_name: "Elon Musk",
        },
      },
      tools: {
        form: {
          enabled: true,
        },
        button: {
          enabled: true,
        },
      },
    };
  },
  watch: {
    content: function () {
      if (this?.$refs?.documentEditor?.editor) {
        this.$refs.documentEditor.editor.loadDesign(this.content);
      }
    },
  },
  methods: {
    emitMe(event, data) {
      this.$emit(event, data);
    },
    editorLoaded() {
      this.$refs.documentEditor.editor.addEventListener("design:loaded", () => {
        this.$refs.documentEditor.editor.saveDesign((design) => this.emitMe("json", design));
        this.$refs.documentEditor.editor.exportHtml((data) => this.emitMe("html", data.html));
      });
      this.$refs.documentEditor.editor.addEventListener("design:updated", () => {
        this.$refs.documentEditor.editor.saveDesign((design) => this.emitMe("json", design));
        this.$refs.documentEditor.editor.exportHtml((data) => this.emitMe("html", data.html));
      });

      this.$refs.documentEditor.editor.registerCallback("selectImage", function (data, done) {
        micromodal.show("select-image-modal");
        const elements = document.getElementById("mediaLibrary").getElementsByClassName("image");
        elements.forEach(function (element) {
          element.addEventListener("click", function (e) {
            done({ url: e.target.attributes.src.value });
            micromodal.close("select-image-modal");
          });
        });
      });
      if (this.content) {
        if (Object.keys(this.content).length > 0) {
          this.$refs.documentEditor.editor.loadDesign(this.content);
        }
      }
    },
  },
};
</script>
