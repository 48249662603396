<template>
  <data-table :headers="headers" :entries="contacts">
    <template #phone="{ item }">
      <p>{{ item.phone | formatPhone }}</p>
    </template>
    <template #email="{ item }">
      <a :href="`mailto:${item.email}`">{{ item.email }}</a>
    </template>
    <template #address="{ item }">
      <p>
        <span>{{ item.address && item.address.address1 ? item.address.address1 : "" }}</span>
      </p>
      <p>
        <span> {{ item.address && item.address.address2 ? item.address.address2 : "" }}</span>
      </p>
      <p>
        {{ item.address && item.address.city ? item.address.city : "" }}<span v-if="item.address && item.address.state">,</span>
        <span>{{ item.address && item.address.state ? item.address.state : "" }}</span>
      </p>
      <p>{{ item.address && item.address.postalCode ? item.address.postalCode : "" }}</p>
      <p>{{ item.address && item.address.country ? item.address.country : "" }}</p>
    </template>
    <template #edit="{ item }"> <router-link :to="{ name: $props.editLinkName, params: { contactId: item.id } }">Edit</router-link></template>
    <template #noContents><p>There are no contacts entered for this client yet, please add one now!</p></template>
  </data-table>
</template>
<script>
import DataTable from "@/components/table/DataTable";
import TableHeader from "@/components/table/TableHeader";
import * as Roles from "@/security/rolesHelper";
export default {
  name: "ContactsTable",
  components: { DataTable },
  props: {
    contacts: {
      type: Array,
      required: true,
      default: () => [],
    },
    editLinkName: {
      type: String,
      required: true,
      default: "clientContactsEdit",
    },
  },
  data() {
    return {
      headers: [
        new TableHeader({
          label: "Name",
          name: "clientName",
          cellContents: (contact) => `${contact.firstName} ${contact.lastName}`,
          sortable: true,
        }),
        new TableHeader({
          label: "Phone",
          name: "phone",
          sortable: true,
        }),
        new TableHeader({
          label: "Email",
          name: "email",
          sortable: true,
        }),
        new TableHeader({
          label: "Address",
          name: "address",
          sortable: true,
        }),
        new TableHeader({
          label: "Role",
          name: "role",
          cellContents: (contact) => (contact.user && contact.user.role ? Roles.getDisplayValue(contact.user.role) : ""),
          sortable: true,
        }),
        new TableHeader({
          label: "Edit",
          name: "edit",
          sortable: true,
        }),
      ],
    };
  },
};
</script>
