class PaymentProcessor {
  static EZCoastal = "EZ Coastal";
  static Branch = "Branch";

  static processors = {
    [PaymentProcessor.EZCoastal]: "EZ Coastal",
    [PaymentProcessor.Branch]: "Branch",
  };
  static getPaymentProcessorLabel = (processor) => PaymentProcessor.processors[processor];

  static getPaymentProcessorOptions = () => Object.keys(PaymentProcessor.processors).map((processor) => ({ id: processor, label: PaymentProcessor.getPaymentProcessorLabel(processor) }));
}

export default PaymentProcessor;
