<template>
  <base-modal id="modal-custom-invoice-type">
    <div class="module">
      <h4>Create Custom Invoice</h4>
      <div class="multi-input-group">
        <p class="label">Invoicing</p>
        <div class="inner no-left-space">
          <div class="radio-custom">
            <input id="invoice-type-client" type="radio" name="invoice-type" checked="checked" />
            <label for="invoice-type-client"><span class="label-container">A Client</span></label>
          </div>
          <div class="radio-custom">
            <input id="invoice-type-location" type="radio" name="invoice-type" />
            <label for="invoice-type-location"><span class="label-container">A Location</span></label>
          </div>
        </div>
      </div>
    </div>
    <div class="button-group close-top"><a class="button" href="create-custom-invoice.html">Continue</a><a class="button secondary" href="#0">Cancel</a></div>
  </base-modal>
</template>
<script>
import { BaseModal } from "@/components";
export default {
  components: { BaseModal },
};
</script>
