var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('h2',[_vm._v("Garnishment Settings")]),_c('div',{staticClass:"module-large"},[_c('h4',[_vm._v("Federal Rates")]),_c('data-table',{attrs:{"entries":_vm.federalEntries,"headers":_vm.federalHeaders},scopedSlots:_vm._u([{key:"minDisposable",fn:function(ref){
var item = ref.item;
return [_vm._v("$ "+_vm._s(item.minDisposable))]}},{key:"disposalCap",fn:function(ref){
var item = ref.item;
return [_vm._v("$ "+_vm._s(item.disposableCap))]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":[function($event){return _vm.openFlyout(item)},function($event){$event.preventDefault();}]}},[_vm._v("Edit")])]}}])})],1),_c('div',{staticClass:"module-large"},[_c('h4',[_vm._v("Settlement Correction Needed")]),_c('data-table',{attrs:{"entries":_vm.settlementEntries,"headers":_vm.settlementHeaders},scopedSlots:_vm._u([{key:"minDisposable",fn:function(ref){
var item = ref.item;
return [_vm._v("$ "+_vm._s(item.minDisposable))]}},{key:"percentageCap",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.percentageCap)+" %")]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":[function($event){return _vm.openFlyout(item)},function($event){$event.preventDefault();}]}},[_vm._v("Edit")])]}}])})],1),_c('garnishment-flyout',{attrs:{"selected":_vm.selected},on:{"save":_vm.saveGarnishment}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }