<template>
  <div :class="['small-progress-bar', iconColorClass(Math.min(percent, 100))]">
    <div class="icon">
      <i :class="iconClass(Math.min(percent, 100))"></i>
    </div>
    <div class="progress-bar">
      <div class="inner" :style="{ width: Math.min(percent, 100) + '%' }"></div>
    </div>
    <div class="percent">{{ percent.toFixed(0) }}%</div>
  </div>
</template>
<script>
export default {
  props: {
    complete: {
      type: Number,
    },
    total: {
      type: Number,
    }
  },
  computed: {
    percent() {
      if (this.$props.total == 0 && this.$props.complete == 0 ) {
        return 100;
      } else if (this.$props.complete == 0 ) {
        return 0;
      } else {
        return !this.$props.total || this.$props.total === 0 ? 100 : this.$props.complete / this.$props.total / Math.pow(10, -2);
      }
    },
  },
  methods: {
    iconClass: (num) => {
      if (num <= 50) {
        return "icon-alert-triangle";
      }
      if (num > 50 && num <= 99) {
        return "icon-circle-dashed";
      }
      if (num === 100) {
        return "icon-check";
      }
      return "icon-slash";
    },
    iconColorClass: (num) => {
      if (num <= 50) {
        return "warning";
      }
      if (num > 50 && num <= 99) {
        return "on-track";
      }
      if (num === 100) {
        return "fulfilled";
      }
      return "not-recruiting";
    },
  },
};
</script>
