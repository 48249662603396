<template>
  <fragment>
    <h2>Garnishment Settings</h2>
    <div class="module-large">
      <h4>Federal Rates</h4>
      <data-table :entries="federalEntries" :headers="federalHeaders">
        <template #minDisposable="{ item }">$ {{ item.minDisposable }}</template>
        <template #disposalCap="{ item }">$ {{ item.disposableCap }}</template>
        <template #actions="{ item }"> <a @click="openFlyout(item)" @click.prevent>Edit</a></template>
      </data-table>
    </div>
    <div class="module-large">
      <h4>Settlement Correction Needed</h4>
      <data-table :entries="settlementEntries" :headers="settlementHeaders">
        <template #minDisposable="{ item }">$ {{ item.minDisposable }}</template>
        <template #percentageCap="{ item }">{{ item.percentageCap }} %</template>
        <template #actions="{ item }"> <a @click="openFlyout(item)" @click.prevent>Edit</a></template>
      </data-table>
    </div>
    <garnishment-flyout :selected="selected" @save="saveGarnishment" />
  </fragment>
</template>
<script>
import { Fragment } from "vue-fragment";
import DataTable from "@/components/table/DataTable";
import TableHeader from "../../../../components/table/TableHeader";
import { mapActions } from "vuex";
import GarnishmentFlyout from "@/modules/admin/accounting/garnishments/GarnishmentFlyout";
import { GET_GARNISHMENTS } from "@/modules/admin/accounting/garnishments/graph/queries";
import { UPSERT_GARNISHMENT } from "@/modules/admin/accounting/garnishments/graph/mutations";
export default {
  components: { GarnishmentFlyout, DataTable, Fragment },
  data: () => ({
    selected: {},
    federalEntries: [],
    settlementEntries: [],
  }),
  computed: {
    settlementHeaders() {
      return [
        new TableHeader({ cellContents: "state", headerClass: "cell30", label: "State", sortable: true }),
        new TableHeader({ cellContents: "minDisposable", headerClass: "cell20", label: "Minimum Disposable Amount", name: "minDisposable" }),
        new TableHeader({ cellContents: "percentageCap", headerClass: "cell20", label: "Percentage Cap", name: "percentageCap" }),
        new TableHeader({ cellContents: "actions", headerClass: "cell15", cellClass: "table-actions", name: "actions" }),
      ];
    },
    federalHeaders() {
      return [
        new TableHeader({ cellContents: "name", headerClass: "cell30", label: "" }),
        new TableHeader({ cellContents: "minDisposable", headerClass: "cell20", label: "Minimum Disposable Amount", name: "minDisposable" }),
        new TableHeader({ cellContents: "disposalCap", headerClass: "cell20", label: "Disposal Cap", name: "disposalCap" }),
        new TableHeader({ cellContents: "actions", headerClass: "cell15", cellClass: "table-actions", name: "actions" }),
      ];
    },
  },
  methods: {
    ...mapActions(["showFlyout"]),
    openFlyout(item) {
      this.selected = item;
      this.showFlyout("edit-garnishment-rate");
    },
    saveGarnishment(data) {
      if (data.state == null) {
        delete data.name;
      }
      delete data.__typename;
      this.$apollo
        .mutate({
          mutation: UPSERT_GARNISHMENT,
          variables: {
            object: data,
          },
        })
        .then(() => {
          this.showFlyout("");
          this.$apollo.queries.garnishments.refetch();
        });
    },
  },
  apollo: {
    garnishments: {
      fetchPolicy: "no-cache",
      query: GET_GARNISHMENTS,
      result({ data }) {
        this.settlementEntries = [];
        this.federalEntries = [];
        data.garnishments.forEach((garnishment) => {
          if (garnishment.state) {
            this.settlementEntries.push(garnishment);
          } else {
            this.federalEntries.push({ name: "Federal Minimum Wage", ...garnishment });
          }
        });
      },
    },
  },
};
</script>
