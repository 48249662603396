<template>
  <div class="module">
    <div ref="editor"></div>
    <div ref="editor-text"></div>
  </div>
</template>
<script>
import Quill from "quill";
import "../../assets/css/quill.css";

export default {
  name: "Editor",
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    small: { type: Boolean, default: false },
  },

  data() {
    return {
      editor: null,
      "editor-text": null,
      // TODO this needs to be dynamic and at the very least, passed in via props
      variables: {
        DDI: {
          1: "{{ddi.address.street1}}",
          2: "{{ddi.address.street2}}",
          3: "{{ddi.address.street3}}",
          4: "{{ddi.address.city}}",
          5: "{{ddi.address.state}}",
          6: "{{ddi.address.zip}}",
          7: "{{ddi.name}}",
          8: "{{ddi.phone}}",
          9: "{{ddi.onboardingEmail}}",
        },
        client: {
          1: "{{client.name}}",
          2: "{{client.workerType}}",
          3: "{{driver.elaResponse}}",
        },
        partner: {
          1: "{{driver.name.first}}",
          2: "{{driver.name.middle}}",
          3: "{{driver.name.last}}",
          // 4: "{{driver.name.tax}}",
          // 5: "{{driver.name.other}}",
          // 6: "{{driver.doingBusinessAs}}",
          // 7: "{{driver.dob}}",
          // 8: "{{driver.ssn}}",
          // 9: "{{driver.ein}}",
          // 10: "{{driver.insuranceExpirationDate}}",
          // 11: "{{driver.license.expiry}}",
          // 12: "{{driver.license.number}}",
          // 13: "{{driver.license.stateIssued}}",
          14: "{{driver.address.street}}",
          15: "{{driver.address.city}}",
          16: "{{driver.address.state}}",
          17: "{{driver.address.zip}}",
          18: "{{driver.phone}}",
          19: "{{driver.email}}",
          20:"{{driver.address.county}}",
          // 20: "{{driver.emergencyContact.name}}",
          // 21: "{{driver.emergencyContact.phone}}",
          // 22: "{{driver.emergencyContact.relationship}}",
          // 23: "{{driver.vehicle.make}}",
          // 24: "{{driver.vehicle.model}}",
          // 25: "{{driver.vehicle.year}}",
          26: "{{driver.signature}}",
          27: "{{driver.initials}}",
          // 28: "{{driver.location.application.link}}",
        },
      },
    };
  },

  mounted() {
    if (!this.small) {
      this.editor = new Quill(this.$refs.editor, {
        modules: {
          toolbar: {
            container: ".toolbar",
            handlers: {
              ddiVars: this.ddiVars,
              clientVars: this.clientVars,
              partnerVars: this.partnerVars,
            },
          },
        },
        theme: "snow",
        //placeholder: "Start your document here.",
      });
      setTimeout(() => {
        if (this.$props.value?.ops) {
          this.editor.setContents(this.$props.value.ops);
        }
      }, 1000);
      this.editor.on("text-change", () => this.update());
    } else {
      this["editor-text"] = new Quill(this.$refs["editor-text"], {
        modules: {
          toolbar: ".toolbar-text",
        },
        theme: "snow",
        //placeholder: "Start your document here.",
      });
      setTimeout(() => {
        this["editor-text"].setContents(this.$props.value);
      }, 1000);
      this["editor-text"].on("text-change", () => this.updateSmall());
    }
    this.$refs.editor.classList.add("white-well", "editor");
  },
  methods: {
    update() {
      this.$emit("input", this.editor.getContents());
      this.$emit("emptyStatus", this.isQuillEmpty());
    },
    updateSmall() {
      this.$emit("input", this["editor-text"].getContents());
      this.$emit("emptyStatus", this.isQuillEmpty());
    },
    ddiVars: function (args) {
      const cursorPosition = this.editor.getSelection().index;
      this.editor.insertText(cursorPosition, this.variables.DDI[args]);
      this.editor.setSelection(cursorPosition + this.variables.DDI[args].length);
    },
    clientVars: function (args) {
      const cursorPosition = this.editor.getSelection().index;
      this.editor.insertText(cursorPosition, this.variables.client[args]);
      this.editor.setSelection(cursorPosition + this.variables.client[args].length);
    },
    partnerVars: function (args) {
      const cursorPosition = this.editor.getSelection().index;
      this.editor.insertText(cursorPosition, this.variables.partner[args]);
      this.editor.setSelection(cursorPosition + this.variables.partner[args].length);
    },
    isQuillEmpty() {
      if (this.$props.small) {
        if ((this["editor-text"].getContents()["ops"] || []).length !== 1) {
          return false;
        }
        return this["editor-text"].getText().trim().length === 0;
      } else {
        if ((this.editor.getContents()["ops"] || []).length !== 1) {
          return false;
        }
        return this.editor.getText().trim().length === 0;
      }
    },
    clearText() {
      if (this.editor) {
        this.$nextTick(() => this.editor.setText(""));
      }
    },
  },
};
</script>
