<template>
  <div class="more-menu" :class="{ right: $props.right, large: $props.large }">
    <div v-click-outside="hidePopup" style="display: flex" @click="showPopup">
      <span v-if="$props.title" :style="{ color: $props.titleColor }" class="partnerFlyout-status">{{ $props.title }} </span>
      <i v-if="!$props.changeIcon" class="icon-chevron-down menu-icon"></i>
      <img v-else src="@/assets/images/logos/walmart-meatball.svg"/>
    </div>
    <nav v-show="popup === $props.id" class="popup-nav" :class="{ large: $props.large }">
      <ul class="list-unstyled">
        <slot></slot>
      </ul>
    </nav>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  name: "Meatball",
  directives: { ClickOutside },
  props: {
    id: {
      type: String,
      required: true,
      default: "",
    },
    right: Boolean,
    title: String,
    large: Boolean,
    long: {
      type: Boolean,
      required: false,
      default: false,
    },
    titleColor: {
      type: String,
      required: false,
      default: "",
    },
    changeIcon: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    popup: "",
  }),
  methods: {
    showPopup() {
      if (this.popup === this.$props.id) {
        this.hidePopup();
      } else {
        this.popup = this.$props.id;
        this.$emit("selected", this.$props.id);
      }
    },
    hidePopup() {
      this.popup = "";
    },
  },
};
</script>
