<template>
  <div class="progress-bar-container">
    <p class="text-progress">
      {{ label }} <strong>{{ complete }}/{{ total }}</strong>
    </p>
    <div class="outer-bar">
      <div class="progress-bar-wrapper">
        <div class="progress-bar">
          <div class="inner" :style="{ width: percent + '%' }"></div>
        </div>
      </div>
      <div class="percent">{{ percent }}%</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProgressBar",
  props: {
    label: String,
    complete: Number,
    total: Number,
  },
  computed: {
    percent() {
      return !this.$props.total || this.$props.total === 0 ? 100 : Number(this.$props.complete / this.$props.total / Math.pow(10, -2)).toFixed(2);
    },
  },
};
</script>
