<template>
  <fragment>
    <h3 v-show="$props.personLabel">{{ $props.personLabel }}</h3>
    <div v-show="!isMaxSize()" class="spread-md">
      <div class="field">
        <autocomplete ref="autocomplete" auto-select :search="search" :get-result-value="getResultValue" :placeholder="$props.placeholder" @submit="handleSubmission" />
      </div>
    </div>
    <p>
      <a :tabindex="$props.addButtonTabIndex" :class="{ hidden: !person.id }" @click="addPerson(person)"><i class="icon-plus-circle"></i>{{ $props.addPersonLabel }}</a>
    </p>
    <slot name="results" :people="people">
      <validation-provider ref="validationProvider" :name="$props.personLabel" slim>
        <div class="tags">
          <div v-for="person in validatePeople()" :key="person.id" class="tag">
            <div class="info">
              <p>{{ person.firstName }} {{ person.lastName }}</p>
            </div>
            <div class="close" @click="removePerson(person)"><i class="icon-x-circle"></i></div>
          </div>
        </div>
      </validation-provider>
    </slot>
  </fragment>
</template>
<script>
import { Fragment } from "vue-fragment";
import Autocomplete from "@trevoreyre/autocomplete-vue";
import { ValidationProvider } from "vee-validate";

export default {
  name: "PeoplePicker",
  components: { Fragment, Autocomplete, ValidationProvider },
  props: {
    personLabel: {
      type: String,
      required: true,
      default: "Person",
    },
    addPersonLabel: {
      type: String,
      required: true,
      default: "Add Person",
    },
    placeholder: {
      type: String,
      required: false,
      default: "Type person name",
    },
    selected: {
      type: Array,
      required: false,
      default: () => [],
    },
    searchResults: {
      type: Array,
      required: true,
      default: () => [],
    },
    maxSize: {
      type: Number,
      required: false,
      default: -1,
    },
    addButtonTabIndex: {
      type: Number,
      required: false,
      default: -1,
    },
    autoSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => {
    return {
      person: {},
      people: [],
      peopleLength: 0,
      initialized: false,
    };
  },
  watch: {
    selected(newValue) {
      if (newValue && !this.initialized) {
        newValue.forEach((person) => {
          if (person) {
            this.addPerson(person);
          }
        });
        this.peopleLength = this.people.length;
        this.initialized = true;
      }
    },
  },
  mounted() {
    if (this.$props.selected) {
      this.$props.selected.forEach((person) => {
        if (person) {
          this.addPerson(person);
        }
      });
    }
  },
  methods: {
    search(input) {
      return this.searchResults.filter((person) => {
        const personExists = this.people.filter((existingPerson) => existingPerson.id === person.id);
        return (person.firstName.toLowerCase().includes(input.toLowerCase()) || person.lastName.toLowerCase().includes(input.toLowerCase())) && personExists.length === 0;
      });
    },
    addPerson(person) {
      this.people.push(person);
      this.person = {};
      this.$refs.autocomplete.value = "";
      const personChangedEvent = {
        people: this.people,
        personAdded: person,
        personRemoved: null,
      };
      this.$emit("peopleChanged", personChangedEvent);
    },
    removePerson(person) {
      this.people = this.people.filter((selectedPerson) => selectedPerson.id !== person.id);
      const personChangedEvent = {
        people: this.people,
        personAdded: null,
        personRemoved: person,
      };
      this.$emit("peopleChanged", personChangedEvent);
    },
    getResultValue(person) {
      return `${person.firstName} ${person.lastName}`;
    },
    handleSubmission(event) {
      if (event && event.id) {
        this.person = event;
        if (this.$props.autoSelect) {
          this.addPerson(event);
        }
      }
    },
    isMaxSize() {
      return this.$props.maxSize > -1 && this.people.length >= this.$props.maxSize;
    },
    validatePeople() {
      const length = this.people.length;
      if (this.$refs.validationProvider && length !== this.peopleLength) {
        this.$refs.validationProvider.setFlags({ dirty: true, pristine: false, touched: true, untouched: false, valid: true, invalid: false });
        this.peopleLength = length;
      }
      return this.people;
    },
  },
};
</script>
