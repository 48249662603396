import { AdminLayout } from "@/components";
import ProfilePage from "@/modules/admin/profile/ProfilePage";
import SettingsPage from "@/modules/admin/profile/SettingsPage";

export default [
  {
    path: "profile",
    name: "profile",
    component: ProfilePage,
    meta: { layout: AdminLayout },
  },
  {
    path: "settings",
    name: "settings",
    component: SettingsPage,
    meta: { layout: AdminLayout },
  },
];
