import apolloClient from "@/apollo/vue-apollo";
import {
  ADD_REMOVE_CLIENT_OVERRIDE,
  CLEAR_REMOVE_CLIENT_OVERRIDE,
  CLEAR_REMOVE_LOCATION_OVERRIDE,
  DOCUMENT_OVERRIDE_REMOVE,
  UPDATE_DOCUMENT_TEMPLATE,
} from "@/modules/shared/documents/graph/mutations";
import { restApi } from "@/http/http.config";
import {encodeWithParam} from "@/util/Base64Encoding";

class DocumentService {
  constructor() {
    this.$apollo = apolloClient;
  }

  createDocumentTemplate(variables,isLocation) {
    console.log(isLocation)
    if(isLocation === undefined) {
      isLocation =false
    }
    return restApi.post('/document/createNewDocument',variables).then(()=>{
      if(isLocation){
        restApi.post('/document/assignDocumentToOneLocation',variables)
      }
    })
    // return this.$apollo.mutate({
    //   mutation: INSERT_TEMPLATE,
    //   variables,
    // });
  }

  updateDocumentTemplate(variables) {
    return this.$apollo.mutate({
      mutation: UPDATE_DOCUMENT_TEMPLATE,
      variables,
    });
  }

  setRemoveOverrideClients(clientList, templateID, updatedBy) {
    const objects = clientList.map((clientID) => ({
      templateID,
      clientID,
      updatedBy,
      originalTemplateID: templateID,
      type: "REMOVE",
    }));
    return this.$apollo.mutate({ mutation: DOCUMENT_OVERRIDE_REMOVE, variables: { objects } });
  }
  setRemoveOverrideLocations(locationList, templateID, updatedBy, clientID) {
    const objects = locationList.map((locationID) => ({
      templateID,
      locationID,
      updatedBy,
      clientID,
      originalTemplateID: templateID,
      type: "REMOVE",
    }));
    return this.$apollo.mutate({ mutation: DOCUMENT_OVERRIDE_REMOVE, variables: { objects } });
  }

  clearRemoveClientOverride(clientList, templateID) {
    return this.$apollo.mutate({ mutation: CLEAR_REMOVE_CLIENT_OVERRIDE, variables: { clientList, templateID } });
  }
  clearRemoveLocationOverride(locationList, templateID) {
    return this.$apollo.mutate({ mutation: CLEAR_REMOVE_LOCATION_OVERRIDE, variables: { locationList, templateID } });
  }

  addRemoveClientOverride(objects) {
    return this.$apollo.mutate({ mutation: ADD_REMOVE_CLIENT_OVERRIDE, variables: { objects } });
  }

  changeDocumentStatus(templateID, status) {
    return restApi.post('/document/updateDocStatus',encodeWithParam({id:templateID,status:status}));
  }
}

export default new DocumentService();
