<template>
  <create-edit-document
    title="Edit Document"
    :possible-assignments="locations"
    :copy-template-id="documentID"
    assignment-key="clientLocationID"
    @onSave="saveDocument"
    @onAssignmentsChanged="assignmentChange"
  />
</template>
<script>
import { ADD_TEMPLATE_USE, DELETE_LOCATION_TEMPLATE_USE, UPDATE_DOCUMENT_TEMPLATE } from "@/modules/shared/documents/graph/mutations";
import { GET_CLIENT_LOCATION_IDs, GET_LIST_OF_PARTNERS } from "@/modules/shared/documents/graph/queries";
import CreateEditDocument from "@/modules/shared/documents/CreateEditDocument";
import { mapGetters } from "vuex";
export default {
  name: "EditClientDocument",
  components: { CreateEditDocument },
  props: {
    clientID: {
      type: String,
      required: false,
      default: () => null,
    },
    documentID: {
      type: String,
      required: false,
      default: () => null,
    },
  },
  data: () => {
    return {
      locations: [],
      clientLocations: [],
      locationsVariable: [],
      clientLocationPartners: [],
    };
  },
  computed: {
    ...mapGetters(["getFullName"]),
  },
  mounted() {
    this.id = this.$route.params.id;
  },
  methods: {
    assignmentChange(assignments) {
      this.clientLocations = [];
      this.locationsVariable = assignments.map((assignment) => {
        return { clientLocationID: { _eq: assignment } };
      });
      this.$apollo.queries.clientLocationPartners.refetch({ _or: this.locationsVariable });
    },
    insertTemplateUse(objects) {
      return this.$apollo.mutate({
        mutation: ADD_TEMPLATE_USE,
        variables: { objects: objects },
      });
    },
    updateDocumentTemplate(template) {
      return this.$apollo.mutate({
        mutation: UPDATE_DOCUMENT_TEMPLATE,
        variables: {
          id: this.documentID,
          title: template.title,
          text: template.text,
          html: template.html,
          version: 1, // TODO come back to this...
          updatedBy: this.getFullName,
          status: template.status,
        },
      });
    },
    async deleteDocumentTemplateUse(template, assignments, originalAssignments) {
      const removedAssignments = this.removeAssignment(template, assignments, originalAssignments);
      if (removedAssignments.length > 0) {
        let promises = removedAssignments.map((assignment) => {
          return this.$apollo.mutate({
            mutation: DELETE_LOCATION_TEMPLATE_USE,
            variables: {
              templateID: this.$props.documentID,
              clientLocationID: assignment,
            },
          });
        });
        await Promise.all(promises);
      }
    },
    async saveDocument(saveDocumentEvent) {
      const { template, assignments, originalAssignments } = saveDocumentEvent;
      await this.updateDocumentTemplate(template);
      if (template.status === "published") {
        // List of Locations
        const locations = this.newAssignments(assignments, originalAssignments).map((assignment) => {
          return {
            clientID: this.$props.clientID,
            clientLocationID: assignment,
            status: "inherited",
            templateID: template.id,
          };
        });

        // List of partners
        // const partners = this.clientLocationPartners.map((item) => {
        //   return {
        //     clientID: item.location.client.id,
        //     clientLocationID: item.location.id,
        //     partnerID: item.partnerID,
        //     status: "inherited",
        //     templateID: template.id,
        //   };
        // });
        const objects = [
          ...locations,
          //  ...partners
        ];
        await this.insertTemplateUse(objects);
      }
      await this.deleteDocumentTemplateUse(template, assignments, originalAssignments);
      this.$router.back();
    },
    newAssignments(assignments, originalAssignments) {
      const arr = [];
      for (let i = 0; i < assignments.length; i++) {
        if (!originalAssignments.includes(assignments[i])) {
          arr.push(assignments[i]);
        }
      }
      return arr;
    },
    removeAssignment(template, assignments, originalAssignments) {
      if (template.status === "draft") {
        return originalAssignments;
      } else {
        const arr = [];
        for (let i = 0; i < originalAssignments.length; i++) {
          if (!assignments.includes(originalAssignments[i])) {
            arr.push(originalAssignments[i]);
          }
        }
        return arr;
      }
    },
  },
  apollo: {
    locations: {
      query: GET_CLIENT_LOCATION_IDs,
      variables() {
        return {
          id: this.$props.clientID,
        };
      },
      skip() {
        return !this.$props.clientID;
      },
      update: (data) => data.clientLocations,
    },
    clientLocationPartners: {
      query: GET_LIST_OF_PARTNERS,
      fetchPolicy: "no-cache",
      variables() {
        return {
          _or: this.locationsVariable,
        };
      },
      skip() {
        return this.locationsVariable.length === 0;
      },
    },
  },
};
</script>
