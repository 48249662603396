<template>
  <create-edit-document title="Edit Document" :copy-template-id="documentID" @onSave="saveDocument" />
</template>
<script>
import { ADD_TEMPLATE_USE, REMOVE_FROM_PARTNERS, UPDATE_DOCUMENT_TEMPLATE } from "@/modules/shared/documents/graph/mutations";
import { GET_LIST_OF_PARTNERS } from "@/modules/shared/documents/graph/queries";
import CreateEditDocument from "@/modules/shared/documents/CreateEditDocument";
import { mapGetters } from "vuex";
export default {
  name: "EditLocationDocument",
  components: { CreateEditDocument },
  props: {
    clientID: {
      type: String,
      required: false,
      default: () => null,
    },
    locationID: {
      type: String,
      required: false,
      default: () => null,
    },

    documentID: {
      type: String,
      required: false,
      default: () => null,
    },
  },
  data: () => {
    return {
      version: 1,
    };
  },
  computed: {
    ...mapGetters(["getFullName"]),
  },
  methods: {
    deleteDocumentTemplateUse() {
      return this.$apollo.mutate({
        mutation: REMOVE_FROM_PARTNERS,
        variables: {
          templateID: this.$props.documentID,
          clientLocationID: this.$props.locationID,
        },
      });
    },
    insertTemplateUse(objects) {
      return this.$apollo.mutate({
        mutation: ADD_TEMPLATE_USE,
        variables: { objects: objects },
      });
    },
    updateDocumentTemplate(template) {
      return this.$apollo.mutate({
        mutation: UPDATE_DOCUMENT_TEMPLATE,
        variables: {
          id: this.documentID,
          title: template.title,
          text: template.text,
          html: template.html,
          version: this.version,
          updatedBy: this.getFullName,
          status: template.status,
        },
      });
    },
    async saveDocument(saveDocumentEvent) {
      const { template } = saveDocumentEvent;
      await this.updateDocumentTemplate(template);
      if (template.status === "published") {
        // List of partners
        const partners = this.clientLocationPartners.map((item) => {
          return {
            clientID: item.location.client.id,
            clientLocationID: item.location.id,
            partnerID: item.partnerID,
            status: "inherited",
            templateID: this.$props.documentID,
          };
        });
        await this.insertTemplateUse(partners);
      }
      if (template.status === "draft") {
        await this.deleteDocumentTemplateUse();
      }
      this.$router.back();
    },
  },
  apollo: {
    clientLocationPartners: {
      query: GET_LIST_OF_PARTNERS,
      variables() {
        return {
          _or: [{ clientLocationID: { _eq: this.$props.locationID } }],
        };
      },
      skip() {
        return this.$props.locationID === null;
      },
    },
  },
};
</script>
