<template>
  <fragment>
    <button-bar-tabs :buttons="commsTabs">
      <template #texts>
        <div class="module-large">
          <h2>Text Messages</h2>
          <data-table
            :headers="headersText"
            :loading="textLoader"
            :entries="communicationsText"
            :pagination-total-count="totalCountSMS"
            :pagination-per-page="perPageSMS"
            :pagination-current-page="currentPageSMS"
            :display-rows-per-page-max-selector=true
            @onPaginate="onPaginateSMS($event)"
            @onChangePerPage="onChangePerPageSMS($event)"

          >
            <template #status="{ item }">
              <a v-if="item.externalIDFull" :href="`https://www.twilio.com/console/sms/logs/${item.externalID}`" target="_blank">{{ item.externalStatus }}</a>
              <span v-if="item.externalStatus == '' || !item.externalStatus">No Status Found</span>
              <span v-else>{{item.externalStatus}}</span>
            </template>
            <template #createAt="{ item }">{{ item.created_at | formatDateTime }}</template>
            <template #updatedAt="{ item }">{{ item.updated_at | formatDateTime }}</template>
          </data-table>
        </div>
      </template>
      <template #emails>
        <div>
          <h2>Email Messages</h2>
          <data-table
            :headers="headersEmail"
            :loading="loading"
            :entries="communicationsEmail"
            :pagination-total-count="totalCount"
            :pagination-per-page="perPage"
            :pagination-current-page="currentPage"
            :display-rows-per-page-max-selector=true
            @onPaginate="onPaginate($event)"
            @onChangePerPage="onChangePerPage($event)"
          >
            <template #status="{ item }">
              <a v-if="item.externalStatus && (item.externalIDFull || item.externalID)" :href="`https://app.sendgrid.com/email_activity/${item.externalIDFull ? item.externalIDFull : item.externalID}`" target="_blank">{{ item.externalStatus }}</a>
              <span v-if="!item.externalStatus">No Status Found</span>
              
            </template>
            <template #createAt="{ item }">{{ item.created_at | formatDateTime }}</template>
            <template #updatedAt="{ item }">{{ item.updated_at | formatDateTime }}</template>
          </data-table>
        </div>
      </template>
    </button-bar-tabs>
  </fragment>
</template>
<script>
import { DataTable, TableHeader, ButtonBarTabs } from "@/components";
import { Fragment } from "vue-fragment";
// import { COMMS_SMS } from "@/modules/admin/ddi/graph/queries";
import { restApi } from "@/http/http.config";
import {encodeWithParam} from "@/util/Base64Encoding";

export default {
  name: "DdiCommunicationsTab",
  components: { DataTable, Fragment, ButtonBarTabs },
  data: function () {
    return {
      communicationsEmail: [],
      communicationsText: [],
      totalCount: 0,
      currentPage: 1,
      offset: 0,
      perPage: 50,
      totalCountSMS: 0,
      currentPageSMS: 1,
      offsetSMS: 0,
      perPageSMS: 50,
      restorePerPageSMS: 50,
      textLoader:true,
      loading:true
    };
  },
  computed: {
    commsTabs() {
      return [
        { id: "texts", label: "Texts" },
        { id: "emails", label: "Emails" },
      ];
    },
    headersText() {
      return [
        new TableHeader({
          label: "To",
          name: "to",
          cellContents: (item) => `${item.to}`,
          sortable: true,
        }),
        new TableHeader({
          label: "Status",
          name: "status",
          sortable: true,
        }),
        new TableHeader({
          label: "Created At",
          name: "updatedAt",
          direction: "desc",
          sortable: true,
          sortFunction: () => (item1, item2) => {
            return new Date(item1.created_at) - new Date(item2.created_at);
          },
        }),
        new TableHeader({
          label: "Updated At",
          name: "updatedAt",
          sortable: true,
          sortFunction: () => (item1, item2) => {
            return new Date(item1.created_at) - new Date(item2.created_at);
          },
        }),
      ];
    },
    headersEmail() {
      return [
        new TableHeader({
          label: "To",
          name: "to",
          cellContents: (item) => `${item.to}`,
          sortable: true,
        }),
        new TableHeader({
          label: "Status",
          name: "status",
          sortable: true,
        }),
        new TableHeader({
          label: "Created At",
          name: "updatedAt",
          direction: "asc",
          sortable: true,
          sortFunction: () => (item1, item2) => {
            return new Date(item1.created_at) - new Date(item2.created_at);
          },
        }),
        new TableHeader({
          label: "Updated At",
          name: "updatedAt",
          sortable: true,
          sortFunction: () => (item1, item2) => {
            return new Date(item1.created_at) - new Date(item2.created_at);
          },
        }),
      ];
    },
  },
  mounted() {
    this.CommsText();
    this.CommsEmails();
  },
  methods: {
    async CommsEmails() {
      this.loading = true;
      const param = {
        limit: this.perPage,
        offset: this.offset
      }
      await restApi.post(`/comm/CommsEmails`, encodeWithParam(param)).then((responseData)=>{
        this.communicationsEmail = responseData?.data?.data;
        this.totalCount = responseData?.data?.rowCount;
        this.loading = false;
      });
    },
    onPaginate(event) {
      let definedPage = Number(event.pageNumber);
      this.offset = event.offset; 
      if (definedPage <= Math.ceil(this.totalCount / this.perPage) && definedPage > 0) {
        this.currentPage = Number(event.pageNumber);
        this.CommsEmails();  
      }
    },
    async CommsText() {
      this.textLoader=true;
      const param = {
        limit: this.perPageSMS,
        offset: this.offsetSMS
      }
      await restApi.post(`/comm/CommsText`, encodeWithParam(param)).then((responseData)=>{
      this.communicationsText = responseData?.data?.data?.rows;
      this.totalCountSMS = responseData?.data?.totalCount;
      this.textLoader=false;
      });
    },
    
    onPaginateSMS(event) {
      let definedPage = Number(event.pageNumber);
      this.offsetSMS = event.offset; 
      if (definedPage <= Math.ceil(this.totalCountSMS / this.perPageSMS) && definedPage > 0) {
        this.currentPageSMS = Number(event.pageNumber);
        this.CommsText();  
      }
    },
    onChangePerPageSMS(event){
      if(this.perPageSMS == 50 || this.perPageSMS == 75 || this.perPageSMS == 100)
        this.restorePerPageSMS = this.perPageSMS; 
      this.perPageSMS = Number(event.perPage); 
      this.CommsText();  
    },
    onChangePerPage(event) {
      this.perPage = Number(event.perPage);
      this.CommsEmails();
    },
    
    
   
    // external(item) {
    //   return item.externalIDFull ? item.externalIDFull : item.externalID;
    // },
  },
 
  // apollo: {
  //   communicationsText: {
  //     query: COMMS_SMS,
  //     variables() {
  //       return { limit: this.perPageSMS, offset: this.offsetSMS };
  //     },
  //     update: (data) => data.communicationsText,
  //     result({ data }) {
  //       this.communicationsText = data.communicationsText;
  //       this.totalCountSMS = data?.communicationsText_aggregate?.aggregate?.count ? data?.communicationsText_aggregate?.aggregate?.count : 0;
  //     },
  //   },
  // },
};
</script>
