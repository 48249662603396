<template>
  <base-filter v-bind="$props">
    <div class="date-range-picker start-date">
      <date-picker placeholder="Start Date" :value="options.startDate" :width="160" />
    </div>
    <div class="date-range-picker end-date">
      <date-picker placeholder="End Date" :value="options.endDate" :width="160" />
    </div>
  </base-filter>
</template>
<script>
import { BaseFilter } from "@/components";
import DatePicker from "@/components/forms/fields/DatePicker";
export default {
  components: { DatePicker, BaseFilter },
  props: {
    name: {
      type: String,
      required: true,
      default: "Filter",
    },
    type: {
      type: String,
      required: false,
      default: "checkbox",
    },
    dateRange: {
      type: Object,
      required: true,
      default: () => ({ startDate: "", endDate: "" }),
    },
    minWidth: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: () => ({
    startDate: "",
    endDate: "",
  }),
  methods: {
    updateFilters(itemChangedEvent) {
      this.$emit("onFilter", itemChangedEvent.items);
    },
  },
};
</script>
