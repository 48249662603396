const state = {
  flyout: "",
};

const getters = {
  isFlyoutShowing: (state) => {
    return state.flyout !== "";
  },
  getFlyout: (state) => {
    return state.flyout;
  },
  isThisFlyoutShowing: (state) => (flyoutToShow) => {
    return flyoutToShow && state.flyout === flyoutToShow;
  },
};

const mutations = {
  updateFlyout: (state, payload) => {
    if (payload === "") {
      document.body.classList.remove("no-scroll");
    } else {
      document.body.classList.add("no-scroll");
    }
    state.flyout = payload;
  },
};

const actions = {
  showFlyout: ({ commit }, payload) => {
    commit("updateFlyout", payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
