<template>
  <div class="module-form">
    <h3>Main Address</h3>
    <form-input :value="value.address1" name="address1" label="Street Address" required @input="update('address1', $event)" disabled/>
    <form-input :value="value.address2" name="address2" label="Street Address " @input="update('address2', $event)" disabled/>
    <form-input :value="value.city" name="city" label="City" required @input="update('city', $event)" disabled/>
    <div class="field">
      <label>
        State
        <region-select :value="value.state" :country="value.country" :region="value.state" placeholder="Select State" autocomplete disable-placeholder @input="update('state', $event)" disabled/>
      </label>
    </div>
    <form-input :value="value.postalCode" name="zip" label="Zip" @input="update('postalCode', $event)" disabled/>
    <div class="field">
      <label>
        Country
        <country-select :value="value.country" :country="value.country" top-country="US" autocomplete @input="update('country', $event)" disabled/>
      </label>
    </div>
  </div>
</template>
<script>
import FormInput from "@/components/forms/fields/FormInput";
export default {
  name: "MainAddress",
  components: { FormInput },
  props: {
    value: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  methods: {
    update(key, value) {
      this.$emit("input", { ...this.$props.value, [key]: value });
    },
  },
};
</script>
