<template>
  <div class="well text-center no-contents module" :style="{ minHeight, minWidth, height: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex' }">
    <vue-simple-spinner />
  </div>
</template>
<script>
import VueSimpleSpinner from "vue-simple-spinner";

export default {
  name: "TableLoader",
  components: { VueSimpleSpinner },
  props: {
    minHeight: {
      type: String,
      required: false,
      default: "500px",
    },
    minWidth: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
