<template>
  <div id="app" :class="{ 'show-flyout': isFlyoutShowing }">
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import { appIn /sights } from "@/main";

export default {
  computed: {
    ...mapGetters(["isFlyoutShowing", "getFullName"]),
  },
  created() {
    if (!this.getFullName && this.getFullName.length <= 0) {
      this.$store.dispatch("userLogin");
    }
    // appInsights.trackPageView();
  },
};
</script>
