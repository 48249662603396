import CreateClientDocument from "@/modules/shared/documents/CreateClientDocument";
import CreateLocationDocument from "@/modules/shared/documents/CreateLocationDocument";
import CreateDdiDocument from "@/modules/shared/documents/CreateDdiDocument";
import VersionPreview from "@/modules/shared/documents/VersionPreview";
import EditDdiDocument from "@/modules/shared/documents/EditDdiDocument";
import EditClientDocument from "@/modules/shared/documents/EditClientDocument";
import EditLocationDocument from "@/modules/shared/documents/EditLocationDocument";
import { AddEditLayout } from "@/components";

export default [
  {
    path: "/admin/documents/client/create",
    name: "createClientTemplate",
    component: CreateClientDocument,
    props: (route) => ({
      clientID: route.query.clientID,
      inherited: String(route.query.inherited).toLowerCase() === "true",
      documentID: route.query.documentID,
    }),
    meta: { hideUI: true, layout: AddEditLayout },
  },
  {
    path: "/admin/documents/location/create",
    name: "createLocationTemplate",
    component: CreateLocationDocument,
    props: (route) => ({
      clientID: route.query.clientID,
      locationID: route.query.locationID,
      inherited: String(route.query.inherited).toLowerCase() === "true",
      documentID: route.query.documentID,
    }),
    meta: { hideUI: true, layout: AddEditLayout },
  },
  {
    path: "/admin/documents/ddi/create",
    name: "createDdiTemplate",
    component: CreateDdiDocument,
    props: (route) => ({
      inherited: String(route.query.inherited).toLowerCase() === "true",
      documentID: route.query.documentID,
      partnerId: route.query.partnerId,
    }),
    meta: { hideUI: true, layout: AddEditLayout },
  },
  {
    path: "/admin/documents/client/edit",
    name: "editClientTemplate",
    component: EditClientDocument,
    props: (route) => ({
      clientID: route.query.clientID,
      documentID: route.query.documentID,
    }),
    meta: { hideUI: true, layout: AddEditLayout },
  },
  {
    path: "/admin/documents/location/edit",
    name: "editLocationTemplate",
    component: EditLocationDocument,
    props: (route) => ({
      clientID: route.query.clientID,
      locationID: route.query.locationID,
      documentID: route.query.documentID,
    }),
    meta: { hideUI: true, layout: AddEditLayout },
  },
  {
    path: "/admin/documents/ddi/edit",
    name: "editDdiTemplate",
    component: EditDdiDocument,
    props: (route) => ({
      documentID: route.query.documentID,
    }),
    meta: { hideUI: true, layout: AddEditLayout },
  },
  {
    path: "/admin/documents/versions",
    name: "versionPreview",
    component: VersionPreview,
    props: (route) => ({
      clientID: route.query.clientID,
      locationID: route.query.locationID,
      documentID: route.query.documentID,
    }),

    meta: { hideUI: true, layout: AddEditLayout },
  },
];
