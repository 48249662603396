export class ErrorLogCategory {
  static PAYMENT_PROCESSOR = "PAYMENT_PROCESSOR";
  static SCREENING = "SCREENING";
  static UI = "UI";
  static LEGACY = "LEGACY";
  static ONBOARDING = "ONBOARDING";
  // static ACCOUNTING = "ACCOUNTING";
  static API_INTEGRATION = "API_INTEGRATION";
  static DATA_ACCESS = "DATA_ACCESS";
  static COMMUNICATIONS = "COMMUNICATIONS";
  static SYSTEM = "SYSTEM";

  static values = {
    [ErrorLogCategory.PAYMENT_PROCESSOR]: "Payment Processor",
    [ErrorLogCategory.SCREENING]: "Screening",
    [ErrorLogCategory.UI]: "User Interface",
    [ErrorLogCategory.LEGACY]: "Legacy",
    [ErrorLogCategory.ONBOARDING]: "Onboarding",
    // [ErrorLogCategory.ACCOUNTING]: "Accounting",
    [ErrorLogCategory.API_INTEGRATION]: "API Integration",
    [ErrorLogCategory.DATA_ACCESS]: "Data Access",
    [ErrorLogCategory.COMMUNICATIONS]: "Communications",
    [ErrorLogCategory.SYSTEM]: "System",
  };

  static findCategoryFromLabel = (label) => {
    const filteredList = Object.keys(ErrorLogCategory.values).filter((key) => ErrorLogCategory.values[key].toLowerCase() === label);
    if (filteredList && filteredList.length > 0) {
      return filteredList[0];
    }
    return null;
  };

  static getKeys = () => Object.keys(ErrorLogCategory.values);
}
