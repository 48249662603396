<template>
  <div class="field" :style="computeWidth">
    <label>{{ $props.label }}</label>
    <div class="select-container">
      <select :value="$props.value" :required="$props.required" @change="change($event)">
        <option value="" disabled selected hidden>Select</option>
        <option value="MONDAY">Monday</option>
        <option value="TUESDAY">Tuesday</option>
        <option value="WEDNESDAY">Wednesday</option>
        <option value="THURSDAY">Thursday</option>
        <option value="FRIDAY">Friday</option>
        <option value="SATURDAY">Saturday</option>
        <option value="SUNDAY">Sunday</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "DaySelector",
  props: {
    label: {
      type: String,
      required: false,
      default: () => "Day",
    },
    width: {
      type: Number,
      required: false,
      default: () => null,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: [String, null],
      required: false,
      default: "",
    },
  },
  computed: {
    computeWidth() {
      if (this.$props.width) {
        return { width: `${this.$props.width}px` };
      }
      return {};
    },
  },
  methods: {
    change(event) {
      this.$emit("change", event.target.value);
    },
  },
};
</script>
