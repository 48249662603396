<template>
  <flyout name="edit-garnishment-rate" title="Edit Garnishment Rate">
    <h4>{{ selected.state }}</h4>
    <form>
      <div class="field">
        <label>Minimum Disposable Amount</label>
        <div class="input-has-prepend spread-sm">
          <div class="input-prepend">$</div>
          <input v-model="data.minDisposable" type="text" placeholder="" />
        </div>
      </div>

      <div v-if="data.state == null" class="field">
        <label>Disposal Cap</label>
        <div class="input-has-prepend spread-sm">
          <div class="input-prepend">$</div>
          <input v-model="data.disposableCap" type="text" placeholder="" />
        </div>
      </div>
      <div v-else class="field">
        <label>“Other” Garnishment Percentage Cap</label>
        <div class="input-has-append spread-sm">
          <div class="input-append">%</div>
          <input v-model="data.percentageCap" type="text" placeholder="" />
        </div>
      </div>
      <div class="button-group module-large close-top"><a class="button" @click="save">Save</a><a class="button secondary" @click="showFlyout('')">Cancel</a></div>
    </form>
  </flyout>
</template>
<script>
import Flyout from "@/components/flyouts/Flyout";
import { mapActions } from "vuex";

export default {
  name: "GarnishmentFlyout",
  components: { Flyout },
  props: {
    selected: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      data: {},
    };
  },
  watch: {
    selected(newVal) {
      this.data = newVal;
    },
  },
  methods: {
    ...mapActions(["showFlyout"]),
    save() {
      this.$emit("save", this.data);
    },
  },
};
</script>
