import { AdminLayout } from "@/components";
import AddEditLayout from "@/components/layout/AddEditLayout";

const Employees = () => import("@/modules/admin/employees/Employees");
const CreateEmployee = () => import("@/modules/admin/employees/individuals/CreateEmployee");
const EditEmployee = () => import("@/modules/admin/employees/individuals/EditEmployee");
const CreateTeam = () => import("@/modules/admin/employees/teams/CreateTeam");
const EditTeam = () => import("@/modules/admin/employees/teams/EditTeam");

export default [
  {
    path: "employees",
    name: "employees",
    component: Employees,
    meta: { layout: AdminLayout },
  },
  {
    path: "employees/new",
    name: "createEmployee",
    component: CreateEmployee,
    meta: { layout: AddEditLayout },
  },
  {
    path: "employees/:id/edit",
    name: "editEmployee",
    component: EditEmployee,
    meta: { layout: AddEditLayout },
  },
  {
    path: "teams/new",
    name: "createTeam",
    component: CreateTeam,
    meta: { layout: AddEditLayout },
  },
  {
    path: "teams/:id/edit",
    name: "editTeam",
    component: EditTeam,
    meta: { layout: AddEditLayout },
  },
];
