import LocationRoutes from "@/modules/admin/locations/routes";
import { AddEditLayout, AdminLayout } from "@/components";
import { withPrefix } from "@/util/routeHelper";
import { ClientContactRoutes } from "@/modules/admin/clients/contacts";

const VersionPreview = () => import("@/modules/shared/documents/VersionPreview");
const Clients = () => import("@/modules/admin/clients/Clients");
const AddClient = () => import("@/modules/admin/clients/AddClient");
const Client = () => import("@/modules/admin/clients/Client");
const AddClientLocation = () => import("@/modules/admin/clients/locations/AddClientLocation");
const ClientEditSetup = () => import("@/modules/admin/clients/setup/ClientEditSetup");
const RequestResubmission = () => import("@/modules/shared/documents/RequestResubmission");
const AddEditPaymentSettings = () => import("@/modules/admin/clients/Payment/AddEditPaymentSettings");

export default [
  {
    path: "clients",
    name: "clients",
    component: Clients,
    meta: {
      breadcrumb: "Clients",
      layout: AdminLayout,
    },
  },
  {
    path: "clients/new", // NOTE: this one has to come first so that :id doesn't take over
    name: "addClient",
    component: AddClient,
    meta: { layout: AddEditLayout },
  },
  {
    path: "clients/:id",
    name: "client",
    component: Client,
    meta: { layout: AdminLayout },
  },
  {
    path: "clients/:id/edit",
    name: "editClient",
    component: ClientEditSetup,
    meta: { layout: AddEditLayout },
  },
  {
    path: "clients/:id/locations/add",
    name: "addClientLocation",
    component: AddClientLocation,
    meta: { layout: AddEditLayout },
  },
  ...withPrefix("clients/:id", "", LocationRoutes),
  ...withPrefix("clients/:id", "", ClientContactRoutes),
  {
    path: "documents/request-resubmission",
    name: "clientDocumentRequestResubmission",
    component: RequestResubmission,
    props: (route) => ({
      clientID: route.params.id,
      documentID: route.query.documentID,
    }),
    meta: { hideUI: true },
  },
  {
    path: "documents/preview/:documentID",
    name: "VersionPreviewClient",
    component: VersionPreview,
    meta: { hideUI: true },
  },
  {
    path: "clients/:id/payment-settings",
    name: "PaymentSettings",
    component: AddEditPaymentSettings,
    props: (route) => ({
      paymentData: route.query.paymentData,
      dataAvailable: route.query.dataAvailable,
    }),
    meta: { layout: AddEditLayout },
  },
];
