import gql from "graphql-tag";

export const SAVE_PAY_PERIOD = gql`
  mutation savePayPeriod($payPeriod: paymentSettings_insert_input!) {
    insert_paymentSettings_one(object: $payPeriod, on_conflict: { constraint: payPeriod_pkey, update_columns: [startDay, payCycle, payDay, paymentProcessor] }) {
      id
    }
  }
`;

export const UPDATE_FINANCE_TEMPLATE_WITH_PAYMENT_SETTINGS = gql`
  mutation updateFinanceTemplateWithPaymentSettings($financeTemplateId: uuid!, $payPeriodId: uuid!) {
    update_financeTemplates_by_pk(pk_columns: { id: $financeTemplateId }, _set: { payPeriodID: $payPeriodId }) {
      id
    }
  }
`;

export const CREATE_TRANSACTION_MODIFIER = gql`
  mutation createSettlementItem($transactionModifier: settlementItems_insert_input!) {
    insert_settlementItems_one(
      object: $transactionModifier
      on_conflict: {
        constraint: transactionModifier_pkey
        update_columns: [name, invoiceTemplateID, taxSetting, settlementItemType, category, transactionType, description, additionalData, billTo, status, disposable, chartOfAccounts, mappingKey, order]
      }
    ) {
      id
    }
  }
`;

export const DELETE_TRANSACTION_MODIFIER = gql`
  mutation deleteTransactionModifier($modifierID: uuid!) {
    delete_settlementItems_by_pk(id: $modifierID) {
      id
    }
  }
`;

export const DELETE_TRANSACTION_MODIFIER_BY_LOCATION = gql`
  mutation deleteTransactionModifierByLocation($templateID: uuid!, $overriddenModifierID: uuid!) {
    delete_settlementItems(where: { _and: [{ templateID: { _eq: $templateID } }, { overriddenSettlementItemID: { _eq: $overriddenModifierID } }] }) {
      affected_rows
    }
  }
`;

export const CREATE_INVOICE_TEMPLATE = gql`
  mutation createInvoiceTemplate($invoiceTemplate: invoiceTemplates_insert_input!) {
    insert_invoiceTemplates_one(
      object: $invoiceTemplate
      on_conflict: { constraint: invoiceTemplates_pkey, update_columns: [name, terms, clientID, financeTemplateID, addressID, useDefaultAddress, isActive] }
    ) {
      id
    }
  }
`;

export const CHANGE_TRANSACTION_MODIFIER_INVOICE_TEMPLATE = gql`
  mutation changeTransactionModifierInvoiceTemplate($transactionModifierID: uuid!, $invoiceTemplateID: uuid!) {
    update_settlementItems_by_pk(pk_columns: { id: $transactionModifierID }, _set: { invoiceTemplateID: $invoiceTemplateID }) {
      id
    }
  }
`;

export const DELETE_INVOICE_TEMPLATE_BY_ID = gql`
  mutation deleteInvoiceTemplateById($invoiceTemplateID: uuid!) {
    update_invoiceTemplates_by_pk(pk_columns: { id: $invoiceTemplateID }, _set: { isActive: false }) {
      id
    }
  }
`;

export const CREATE_CUSTOM_INVOICE = gql`
  mutation createCustomInvoice($invoice: invoices_insert_input!) {
    insert_invoices_one(object: $invoice) {
      id
    }
  }
`;

export const CREATE_ADDRESS_FOR_INVOICE = gql`
  mutation createAddressForInvoice($address: addresses_insert_input!) {
    insert_addresses_one(object: $address) {
      id
    }
  }
`;
