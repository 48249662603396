<template>
  <fragment>
    <overlay />
    <client-header :logo="client.logo" :color="client.color" />
    <main>
      <div class="container">
        <div class="content">
          <slot :clientId="client.id" />
        </div>
      </div>
    </main>
    <Footer :color="client.color" />
  </fragment>
</template>
<script>
import Overlay from "@/components/modals/Overlay";
import Footer from "@/components/layout/Footer";
import ClientHeader from "@/components/layout/ClientHeader";
import { Fragment } from "vue-fragment";
import { mapGetters } from "vuex";
import { GET_CLIENT_BRANDING } from "@/modules/clientPortal/queries";
export default {
  name: "ClientPortalLayout",
  components: { ClientHeader, Footer, Overlay, Fragment },
  data: () => ({
    client: {},
  }),
  computed: {
    ...mapGetters(["getPersonID"]),
  },
  apollo: {
    client: {
      query: GET_CLIENT_BRANDING,
      variables() {
        return { contactID: this.getPersonID };
      },
      update(data) {
        return data.contacts_by_pk.client;
      },
      skip() {
        return !this.getPersonID;
      },
    },
  },
};
</script>
