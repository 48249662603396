import { AddEditLayout } from "@/components";

const LocationContactsAdd = () => import("@/modules/admin/locations/contacts/LocationContactsAdd");
const LocationContactsEdit = () => import("@/modules/admin/locations/contacts/LocationContactsEdit");

export default [
  {
    path: "contacts/add",
    name: "locationContactsAdd",
    component: LocationContactsAdd,
    meta: { layout: AddEditLayout },
  },
  {
    path: "contacts/:contactId/edit",
    name: "locationContactsEdit",
    component: LocationContactsEdit,
    meta: { layout: AddEditLayout },
  },
];
