<template>
  <validation-provider v-slot="{ errors }" mode="eager" :name="$props.name" :rules="allRules" slim>
    <div class="field" :style="computeWidth" :class="[$props.className]">
      <label>{{ $props.label }}{{ $props.required ? "*" : "" }}</label>
      <div class="select-container">
        <select :value="$props.value" :name="$props.name" :required="$props.required" :disabled="$props.disabled" @change="handleChange($event)">
          <option v-if="!noDefault" value="" disabled>{{ $props.defaultChoice }}</option>
          <option v-for="option in $props.options" :key="option[$props.optionKey]" :value="option[$props.optionValue]">
            {{ option[$props.optionLabel] }}
          </option>
        </select>
        <p class="error-message">{{ errors[0] }}</p>
      </div>
    </div>
  </validation-provider>
</template>
<script>
import { ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules.umd";

extend("required", required);
export default {
  name: "FormSelect",
  components: {
    ValidationProvider,
  },
  props: {
    value: {
      type: [String, null],
      required: false,
      default: "",
    },
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    optionKey: {
      type: String,
      required: false,
      default: "id",
    },
    optionValue: {
      type: String,
      required: false,
      default: "id",
    },
    optionLabel: {
      type: String,
      required: false,
      default: "label",
    },
    defaultChoice: {
      type: String,
      required: false,
      default: "Choose one",
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: String,
      required: false,
      default: "",
    },
    disabled: Boolean,
    noDefault: {
      type: Boolean,
      required: false,
      default: false,
    },
    width: {
      type: Number,
      required: false,
      default: null,
    },
    className: {
      type: [String, Object],
      required: false,
      default: "",
    },
  },
  data: () => {
    return {
      errors: [],
    };
  },
  computed: {
    allRules() {
      const rules = this.$props.rules && this.$props.rules.length > 0 ? this.$props.rules.split(",") : [];
      if (this.$props.required && !rules.includes("required")) {
        rules.push("required");
      }
      return rules.join(",");
    },
    computeWidth() {
      if (this.$props.width) {
        return { width: `${this.$props.width}px` };
      }
      return {};
    },
  },
  //  watch: {
  //   options: {
  //     immediate: true,
  //     deep: true,
  //     handler(options) {
  //         console.log('watch method called...', options)
  //     }
  //   }
  // },
  methods: {
    handleChange(event) {
      this.$emit("input", event.target.value);
      this.$emit("change", event.target.value);
    },
  },
};
</script>
