<template>
  <fragment>
    <div class="invoice-archive-container">
      <h2>Invoice Archive</h2>
      <div class="Payment-settings-router create-new-invoice-invoice-archive">
        <a class="right" @click="addNewInvoice"
          ><span><i class="icon icon-file-plus"></i></span>Create a Custom Invoice</a
        >
      </div>
      <h4>Find invoices marked as paid</h4>
      <div class="flex invoice-archive-flex">
        <div class="filter-container">
          <p class="label">Filters</p>
          <div class="filters">
            <div class="filter-options">
              <div class="field invoice-archive-field">
                <item-picker no-title :search-results="clientFilterOptions" placeholder="Client Name" auto-select @itemsChanged="addingChips = $event" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="filters align-bottom module invoice-date-filters">
        <div class="filter-options">
          <div class="filter-input">
            <div class="field">
              <date-pick :value="startDate" :display-format="'DD.MM.YYYY'" :format="'YYYY-MM-DD'" @input="updateStartDate($event)">
                <template #default="{ toggle }">
                  <div class="input-has-icon input-icon-tab">
                    <div class="input-icon" @click="toggle"><i class="icon-calendar"></i></div>
                    <input id="startDate" :value="startDate" type="text" placeholder="Begin Date" readonly required @click="toggle" />
                  </div>
                </template>
              </date-pick>
            </div>
          </div>
          <div class="filter-input">
            <div class="field">
              <date-pick :value="endDate" :display-format="'DD.MM.YYYY'" :format="'YYYY-MM-DD'" @input="updateEndDate($event)">
                <template #default="{ toggle }">
                  <div class="input-has-icon input-icon-tab">
                    <div class="input-icon" @click="toggle"><i class="icon-calendar"></i></div>
                    <input id="endDate" :value="endDate" type="text" placeholder="End Date" readonly required @click="toggle" />
                  </div>
                </template>
              </date-pick>
            </div>
          </div>

          <div class="filter-input">
            <button class="button secondary" @click="applyDateFilter">Apply</button>
            <button v-if="clearDateFilterBtn" class="button secondary clear-btn" style="margin-left: 5px" @click="clearDateFilter">Clear Date Filter</button>
          </div>
        </div>
        <div v-if="filterError">
          <p class="error-message">Please select Valid Dates</p>
        </div>
      </div>

      <data-table
        :entries="archivedInvoicesObj"
        :headers="invoiceArchiveHeaders"
        :pagination-total-count="totalCount"
        :pagination-per-page="perPage"
        :pagination-current-page="currentPage"
        :loading="loader"
        @onPaginate="onPaginate($event)"
      >
        <template #meatball="{ item }">
          <meatball :id="item.Id" right @selected="currentElement($event)">
            <menu-item :label="`Invoice: ${item.invoice.name}`" :on-click="() => viewInvoice(item.invoice.id)" />
            <div v-if="item.settlementID !== ''">
              <menu-item label="View Settlement" :on-click="viewSettlement" />
            </div>
          </meatball>
        </template>
      </data-table>
    </div>
    <invoice-modal invoice-modal-name="paymentDistributionInvoiceArchive" :invoice-id="selectedInvoice" />
    <create-custom-invoice-modal invoice-modal-name="InvoiceArchiveCreateCustomInvoice" />
  </fragment>
</template>
<style scoped>
.invoice-archive-container .input-icon-tab input {
  padding-right: 0;
}
.invoice-archive-flex .invoice-archive-field {
  margin-bottom: 16px;
  display: flex;
}
</style>
<script>
import { Fragment } from "vue-fragment";
import DatePick from "vue-date-pick";
import { DataTable, Meatball, MenuItem } from "@/components";
import TableHeader from "@/components/table/TableHeader";
import { GET_INVOICE_ARCHIVE, GET_CLIENT_NAMES, GET_FILTERED_INVOICE_ARCHIVE, GET_INVOICES_BY_CLIENTS, GET_CUSTOM_INVOICES } from "@/modules/admin/accounting/archive/graph/queries";
import { mapGetters } from "vuex";
import ItemPicker from "@/components/pickers/ItemPicker";
import { formatDate, formatMoney } from "@/filters";
import micromodal from "micromodal";
import InvoiceModal from "@/modules/admin/accounting/invoicing/InvoiceModal";
import CreateCustomInvoiceModal from "@/modules/admin/accounting/archive/CreateCustomInvoiceModal";
import { Interval } from "luxon";
export default {
  components: {
    DataTable,
    Fragment,
    DatePick,
    Meatball,
    MenuItem,
    InvoiceModal,
    CreateCustomInvoiceModal,
    ItemPicker,
  },
  data: () => ({
    financeTemplate: {
      id: "",
      client: {
        name: "",
      },
      payPeriod: {},
    },
    archivedInvoices: [],
    archivedInvoicesObj: [],
    createInvoiceObj: [],
    targetObj: [],
    targetElement: [],
    lineItemsSum: [],
    startDate: "",
    endDate: "",
    totalCount: 0,
    currentPage: 0,
    offset: 0,
    perPage: 50,
    selectedClient: [],
    addingChips: [],
    allClientIds: [],
    selectedClientID: [],
    selectedInvoice: "",
    selected: false,
    clearDateFilterBtn: false,
    filterError: false,
    invoiceObj: [],
    loader: true,
  }),
  computed: {
    ...mapGetters(["getSelectedEmployee"]),
    clientFilterOptions() {
      this.updatingChips();
      return this.selectedClient;
    },
    invoiceModalId() {
      return "invoice-edit-modal";
    },
    invoiceArchiveHeaders() {
      return [
        new TableHeader({ cellContents: "ClientName", headerClass: "cell25", label: "Client Name", name: "clientName" }),
        new TableHeader({ cellContents: "DateInvoiced", headerClass: "cell10", label: "Date Invoiced", name: "dateInvoiced", sortable: true }),
        new TableHeader({ cellContents: "InvoiceNumber", headerClass: "cell15", label: "Invoice Number", name: "invoiceNumber", sortable: true }),
        new TableHeader({ cellContents: "PayPeriod", headerClass: "cell20", label: "Pay Period", name: "PayPeriod" }),
        new TableHeader({ cellContents: "Total", headerClass: "cell15", label: "Total Invoice", name: "total" }),
        new TableHeader({ cellContents: "DateApproved", headerClass: "cell20", label: "Date Approved", name: "dateApproved", sortable: true }),
        new TableHeader({ headerClass: "cell25", cellClass: "table-actions", name: "meatball" }),
      ];
    },
  },
  watch: {
    archivedInvoices() {
      return this.archivedInvoicesObj;
    },
    addingChips(event) {
      var input = event.items.map((item) => item.id);
      this.selectedClientID = input;
      if (input.length === 0) {
        this.loader = true;
        this.$apollo.queries.clients.refetch();
        if (this.startDate !== "" || this.endDate !== "") {
          this.getFilteredData(this.allClientIds, this.startDate, this.endDate);
        } else {
          this.$apollo.queries.invoices.refetch();
        }
      } else {
        if (this.startDate !== "" || this.endDate !== "") {
          this.allFilters(input);
        } else {
          this.fetchFilteredResults(input);
        }
      }
    },
    getSelectedEmployee() {
      this.addingChips = [];
    },
  },
  methods: {
    filterInvoice(invoice) {
      const locationFound = this.addingChips.findIndex((location) => location.name === invoice.name);
      return this.addingChips.length === 0 || locationFound >= 0;
    },
    updatingChips() {
      this.selectedClient = this.selectedClient.filter((element, index, array) => array.findIndex((t) => t.id === element.id) === index);
    },

    updateStartDate(value) {
      this.startDate = value;
    },
    updateEndDate(value) {
      this.endDate = value;
    },
    inherited(template) {
      if (!this.$route.params.id && !this.$route.params.locationID) {
        return false;
      } else if (!template.inherited) {
        return false;
      } else {
        return template.inherited || template.isDDI;
      }
    },
    currentElement(elementId) {
      this.targetElement = elementId;
    },
    viewInvoice(id) {
      this.selectedInvoice = id;
      micromodal.show("paymentDistributionInvoiceArchive", {
        onShow: function () {
          document.body.classList.add("no-scroll");
        },
        onClose: function () {
          document.body.classList.remove("no-scroll");
        },
      });
    },
    viewSettlement() {
      this.currentObj();
      this.$router.push({ name: "accountingReview", params: { settlementID: this.targetObj[0].settlementID, client: this.targetObj[0].ClientName, payPeriod: this.targetObj[0].PayPeriod } });
    },
    currentObj() {
      this.targetObj = [];
      const that = this;
      this.archivedInvoicesObj.forEach((item) => {
        if (that.targetElement === item.Id) {
          that.targetObj.push(item);
        }
      });
    },

    allFilters(input) {
      this.$apollo
        .query({
          query: GET_FILTERED_INVOICE_ARCHIVE,
          variables: {
            clientID: input,
            startDate: this.startdate,
            endDate: this.endDate,
          },
        })
        .then((data) => {
          this.totalCount = data.data.invoices.length;
          this.archivedInvoicesObj = [];
          data.data.invoices.forEach((item) => {
            let payPeriod = {};
            if (item.settlement) {
              payPeriod = {
                startDate: item.settlement.startDate,
                endDate: item.settlement.endDate,
              };
            } else {
              payPeriod = {
                startDate: item.invoice.startDate,
                endDate: item.invoice.endDate,
              };
            }
            let customObj = {
              Id: item.id,
              ClientName: item.client.name,
              DateInvoiced: `${formatDate(payPeriod.endDate)}`,
              InvoiceNumber: item.invoiceNumber ? item.invoiceNumber : "",
              PayPeriod: `${formatDate(payPeriod.startDate)} - ${formatDate(payPeriod.endDate)}`,
              Total: `${formatMoney(item.invoice.total)}`,
              DateApproved: formatDate(payPeriod.endDate),
              settlementID: item?.settlementID ? item.settlementID : "",
              invoice: { name: item.name, id: item.id },
            };
            this.archivedInvoicesObj.push(customObj);
          }, this);
          this.getCustomInvoices();
          this.loader = false;
        });
    },

    fetchFilteredResults(input) {
      this.$apollo
        .query({
          query: GET_INVOICES_BY_CLIENTS,
          variables: {
            clientID: input,
          },
        })
        .then((data) => {
          this.totalCount = data.data.invoices.length;
          this.archivedInvoicesObj = [];
          data.data.invoices.forEach((item) => {
            let payPeriod = {};
            if (item.settlement) {
              payPeriod = {
                startDate: item.settlement.startDate,
                endDate: item.settlement.endDate,
              };
            } else {
              payPeriod = {
                startDate: item.invoice.startDate,
                endDate: item.invoice.endDate,
              };
            }
            let customObj = {
              Id: item.id,
              ClientName: item.client.name,
              DateInvoiced: `${formatDate(payPeriod.endDate)}`,
              InvoiceNumber: item.invoiceNumber ? item.invoiceNumber : "",
              PayPeriod: `${formatDate(payPeriod.startDate)} - ${formatDate(payPeriod.endDate)}`,
              Total: `${formatMoney(item.invoice.total)}`,
              DateApproved: formatDate(payPeriod.endDate),
              settlementID: item?.settlementID ? item.settlementID : "",
              invoice: { name: item.name, id: item.id },
            };
            this.archivedInvoicesObj.push(customObj);
          }, this);
          this.loader = false;
        });
    },

    getCustomInvoices() {
      this.$apollo
        .query({
          query: GET_CUSTOM_INVOICES,
        })
        .then((data) => {
          data.data.invoices.forEach((item) => {
            let payPeriod = {
              startDate: item.invoice.startDate,
              endDate: item.invoice.endDate,
            };
            let customObj = {
              Id: item.id,
              ClientName: item.client.name,
              DateInvoiced: `${formatDate(payPeriod.endDate)}`,
              InvoiceNumber: item.invoiceNumber ? item.invoiceNumber : "",
              PayPeriod: `${formatDate(payPeriod.startDate)} - ${formatDate(payPeriod.endDate)}`,
              Total: `${formatMoney(item.invoice.total)}`,
              DateApproved: formatDate(payPeriod.endDate),
              settlementID: item?.settlementID ? item.settlementID : "",
              invoice: { name: item.name, id: item.id },
            };
            if (this.startDate !== "" || this.endDate !== "") {
              if (this.filterCustomInvoicesbyDates(payPeriod.startDate, payPeriod.endDate)) {
                this.archivedInvoicesObj.push(customObj);
              }
            } else if (this.allClientIds.length == this.selectedClient.length) {
              this.archivedInvoicesObj.push(customObj);
            }
          }, this);
          this.loader = false;
        });
    },
    filterCustomInvoicesbyDates(StartDate, EndDate) {
      let startDateGap = Interval.fromDateTimes(new Date(this.startDate), new Date(StartDate)).length("days");
      let endDateGap = Interval.fromDateTimes(new Date(EndDate), new Date(this.endDate)).length("days");
      console.log("sdg", startDateGap);
      console.log("edg", endDateGap);
      if (startDateGap >= 0 && endDateGap >= 0) {
        return true;
      } else {
        return false;
      }
    },
    onPaginate(event) {
      this.currentPage = Number(event.pageNumber);
      this.offset = event.offset;
    },
    getFilteredData(ids, startdate, enddate) {
      this.loader = true;
      let newStartdate;
      let newEnddate;
      if (startdate == "") {
        newStartdate = "01-01-2000";
      } else {
        newStartdate = startdate;
      }
      if (enddate == "") {
        newEnddate = new Date().toISOString();
      } else {
        newEnddate = enddate;
      }

      this.$apollo
        .query({
          query: GET_FILTERED_INVOICE_ARCHIVE,
          variables: {
            clientID: ids,
            startDate: newStartdate,
            endDate: newEnddate,
          },
        })
        .then((data) => {
          this.totalCount = data.data.invoices.length;
          this.archivedInvoicesObj = [];
          data.data.invoices.forEach((item) => {
            let payPeriod = {};
            if (item.settlement) {
              payPeriod = {
                startDate: item.settlement.startDate,
                endDate: item.settlement.endDate,
              };
            } else {
              payPeriod = {
                startDate: item.invoice.startDate,
                endDate: item.invoice.endDate,
              };
            }
            let customObj = {
              Id: item.id,
              ClientName: item.client.name,
              DateInvoiced: `${formatDate(payPeriod.endDate)}`,
              InvoiceNumber: item.invoiceNumber ? item.invoiceNumber : "",
              PayPeriod: `${formatDate(payPeriod.startDate)} - ${formatDate(payPeriod.endDate)}`,
              Total: `${formatMoney(item.invoice.total)}`,
              DateApproved: formatDate(payPeriod.endDate),
              settlementID: item?.settlementID ? item.settlementID : "",
              invoice: { name: item.name, id: item.id },
            };
            this.archivedInvoicesObj.push(customObj);
          }, this);
          this.getCustomInvoices();
          this.loader = false;
        });
    },

    // alter
    applyDateFilter() {
      this.filterError = false;
      let parsedStartDate = Date.parse(this.startDate);
      let parsedEndDate = Date.parse(this.endDate);
      if (parsedEndDate <= parsedStartDate) {
        this.filterError = true;
      } else {
        if (this.selectedClientID.length === 0) {
          this.getFilteredData(this.allClientIds, this.startDate, this.endDate);
        } else {
          this.getFilteredData(this.selectedClientID, this.startDate, this.endDate);
        }

        this.clearDateFilterBtn = true;
      }
    },
    clearDateFilter() {
      this.loader = true;
      this.archivedInvoicesObj = [];
      this.startDate = "";
      this.endDate = "";
      this.clearDateFilterBtn = false;
      if (this.selectedClientID.length == 0) {
        this.$apollo.queries.invoices.refetch();
      } else {
        this.getFilteredData(this.selectedClientID, this.startDate, this.endDate);
      }
    },
    addNewInvoice() {
      micromodal.show("InvoiceArchiveCreateCustomInvoice", {
        onShow: function () {
          document.body.classList.add("no-scroll");
        },
        onClose: function () {
          document.body.classList.remove("no-scroll");
        },
      });
    },
    sum(lineItems) {
      return lineItems.reduce((acc, curr) => {
        const num = parseFloat(curr.amount);
        return acc + num;
      }, 0);
    },
  },
  apollo: {
    invoices: {
      fetchPolicy: "no-cache",
      query: GET_INVOICE_ARCHIVE,
      result({ data }) {
        this.totalCount = data.invoices.length;
        this.archivedInvoicesObj = [];
        data.invoices.forEach((item) => {
          let payPeriod = {};
          if (item.settlement) {
            payPeriod = {
              startDate: item.settlement.startDate,
              endDate: item.settlement.endDate,
            };
          } else {
            payPeriod = {
              startDate: item.invoice.startDate,
              endDate: item.invoice.endDate,
            };
          }
          let customObj = {
            Id: item.id,
            ClientName: item.client.name,
            DateInvoiced: `${formatDate(payPeriod.endDate)}`,
            InvoiceNumber: item.invoiceNumber ? item.invoiceNumber : "",
            PayPeriod: `${formatDate(payPeriod.startDate)} - ${formatDate(payPeriod.endDate)}`,
            Total: `${formatMoney(item.invoice.total)}`,
            DateApproved: formatDate(payPeriod.endDate),
            settlementID: item?.settlementID ? item.settlementID : "",
            invoice: { name: item.name, id: item.id },
          };
          this.archivedInvoicesObj.push(customObj);
        }, this);
        this.loader = false;
      },
    },
    clients: {
      fetchPolicy: "no-cache",
      query: GET_CLIENT_NAMES,
      result({ data }) {
        this.allClientIds = [];
        this.selectedClient = data.clients;
        data.clients.forEach((item) => {
          this.allClientIds.push(item.id);
        });
      },
    },
  },
};
</script>
