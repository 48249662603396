<template>
  <create-edit-document
    save-label="Create"
    :possible-assignments="locations"
    :copy-template-id="documentID"
    assignment-key="clientLocationID"
    @onSave="saveDocument"
    @onAssignmentsChanged="assignmentChange"
  />
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { GET_CLIENT_LOCATION_IDs } from "@/modules/shared/documents/graph/queries";
import CreateEditDocument from "@/modules/shared/documents/CreateEditDocument";
import { mapGetters } from "vuex";
import documentService from "@/services/document.service";

export default {
  // 1. Get DocumentTemplate info and fill in fields
  // 1a. Insert assignments
  // 2. Increment the version
  // 3. Add inherited from documentTemplateID to previousID field. Pass originalID through
  // 4. Add documentTemplate and documentTemplateUse as normal
  // 5. Remove record of inherited from documentTemplateID where documentTemplateID and clientID matches
  name: "CreateClientDocument",
  components: { CreateEditDocument },
  props: {
    clientID: {
      type: String,
      required: false,
      default: () => null,
    },
    inherited: {
      type: Boolean,
      required: false,
      default: false,
    },
    documentID: {
      type: String,
      required: false,
      default: () => null,
    },
  },
  data: () => {
    return {
      locations: [],
      clientLocations: [],
      locationsVariable: [],
    };
  },
  computed: {
    ...mapGetters(["getFullName"]),
    originalTemplateID() {
      if (this.original) {
        return this.templateID;
      } else {
        return this.documentTemplate ? this.documentTemplate.originalID : null;
      }
    },
  },
  methods: {
    assignmentChange(assignments) {
      this.clientLocations = [];
      this.locationsVariable = assignments.map((assignment) => {
        return { clientLocationID: { _eq: assignment } };
      });
    },
    insertTemplate(template) {
      const variables = {
        id: template.id,
        title: template.title,
        originalID: template.id,
        version: 1,
        updatedBy: template.updatedBy,
        text: template.text,
        html: template.html,
        isDDI: false,
        clientID: template.clientID,
        templateStatus: template.status,
      };
      documentService.createDocumentTemplate(variables);
    },
    async saveDocument(event) {
      let removeOverrideLocationIDs;
      const id = uuidv4();
      const template = event.template;
      template.clientID = this.$route.query.clientID;
      template.updatedBy = this.getFullName;
      template.id = id;

      await this.insertTemplate(template);

      if (template.status === "draft" || template.status === "inactive") {
        removeOverrideLocationIDs = event.assignments;
      } else {
        // TODO: Add to funnel documents
        removeOverrideLocationIDs = event.deSelected;
        await documentService.clearRemoveLocationOverride(event.assignments, template.id);
      }

      await documentService.setRemoveOverrideLocations(removeOverrideLocationIDs, template.id, template.updatedBy);
      this.$router.back();
    },
  },
  apollo: {
    locations: {
      query: GET_CLIENT_LOCATION_IDs,
      variables() {
        return {
          id: this.$props.clientID,
        };
      },
      skip() {
        return !this.$props.clientID;
      },
      update: (data) => data.clientLocations,
    },
  },
};
</script>
