<template>
  <div :id="editorId" class="unlayer-editor" :style="{ minHeight: minHeight }"></div>
</template>

<script>
const scriptUrl = "//editor.unlayer.com/embed.js?2";
const callbacks = [];
let loaded = false;

const isScriptInjected = () => {
  const scripts = document.querySelectorAll("script");
  let injected = false;

  scripts.forEach((script) => {
    if (script.src.includes(scriptUrl)) {
      injected = true;
    }
  });

  return injected;
};

const addCallback = (callback) => {
  callbacks.push(callback);
};

const runCallbacks = () => {
  if (loaded) {
    let callback;

    while ((callback = callbacks.shift())) {
      callback();
    }
  }
};

const loadScript = (callback) => {
  addCallback(callback);

  if (!isScriptInjected()) {
    const embedScript = document.createElement("script");
    embedScript.setAttribute("src", scriptUrl);
    embedScript.onload = () => {
      loaded = true;
      runCallbacks();
    };
    document.head.appendChild(embedScript);
  } else {
    runCallbacks();
  }
};

let lastEditorId = 0;

export default {
  name: "EmailEditor",
  props: {
    options: { type: Object, required: false, default: () => {} },
    projectId: { type: Number, required: false },
    tools: { type: Object, required: false, default: () => {} },
    appearance: { type: Object, required: false, default: () => {} },
    locale: String,
    displayMode: {
      type: String,
      required: false,
      default: "web",
    },
    minHeight: {
      type: String,
      default: "500px",
    },
  },
  computed: {
    editorId() {
      return `editor-${++lastEditorId}`;
    },
  },
  created() {},
  mounted() {
    loadScript(this.loadEditor.bind(this));
  },
  methods: {
    loadEditor() {
      const options = this.options || {};

      if (this.projectId) {
        options.projectId = this.projectId;
      }

      if (this.tools) {
        options.tools = this.tools;
      }

      if (this.appearance) {
        options.appearance = this.appearance;
      }

      if (this.locale) {
        options.locale = this.locale;
      }

      /* global unlayer */
      this.editor = unlayer.createEditor({
        ...options,
        id: this.editorId,
        displayMode: this.displayMode,
      });

      this.$emit("load");
    },
    loadDesign(design) {
      this.editor.loadDesign(design);
    },
    saveDesign(callback) {
      this.editor.saveDesign(callback);
    },
    exportHtml(callback) {
      this.editor.exportHtml(callback);
    },
  },
};
</script>

<style scoped>
.unlayer-editor {
  flex: 1;
  display: flex;
}
</style>
