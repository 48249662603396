<template>
  <base-modal id="modal-delete-line">
    <div class="module">
      <h4 :style="{ color: 'red' }"><i class="icon-alert-octagon"></i> Delete This Line Item?</h4>
    </div>
    <h5>Positive Adjustments</h5>
    <p>Deleting a line item from an invoice cannot be undone.</p>

    <div class="button-group close-top">
      <a class="button warning" data-micromodal-close @click="deleteLine">Delete</a>
      <a class="button secondary" @click="closeModal">Cancel</a>
    </div>
  </base-modal>
</template>
<script>
import micromodal from "micromodal";
import BaseModal from "@/components/modals/BaseModal";
import { restApi } from "../../../../http/http.config";
import { encodeWithParam } from "../../../../util/Base64Encoding";
export default {
  name: "DeleteInvoiceLineModal",
  components: { BaseModal },
  props: {
    deleteDefaultItems: Array,
    selectedInvoice: Object,
    invoiceId: String,
    fetchInvoice: {type: Function},
    getClientInvoicing: {type: Function}
  },
  data: function () {
    return {
      deleteLineItem: false,
    };
  },
  computed: {},
  methods: {
    closeModal() {
      micromodal.close("modal-delete-line");
    },
    deleteLine() {
      restApi.post('accounting/deleteLineItem', encodeWithParam({
        lineItemID: this.$props.deleteDefaultItems[0],
        invoiceID: this.$props.selectedInvoice.id
      })).then(()=>{
        this.$props.getClientInvoicing()
      this.$props.fetchInvoice(this.$props.selectedInvoice.id)
      }).catch((e) => {console.log(e)})
    },
  },
};
</script>
