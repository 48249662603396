<template>
  <fragment>
    <div class="container sticky-header">
      <div class="content">
        <h2>Version Preview</h2>
      </div>
    </div>
    <main class="off-white">
      <div class="container small-content">
        <div class="content">
          <div class="button-group">
            <p class="text-right"><a class="button secondary" @click="editTemplate">Edit Current Version </a><a class="button secondary" @click="$router.back()">Cancel</a></p>
          </div>
          <div class="field">
            <label>Document Version</label>
            <div class="select-container">
              <select v-model="selected">
                <option v-for="document in documentTemplates" :key="document.id" :value="document.id">
                  Version {{ document.version }} - {{ document.updated_at | formatDate }} -
                  {{ document.updatedBy }}
                </option>
              </select>
            </div>
          </div>
          <div class="white-well">
            <div v-if="$apollo.loading" class="loading">
              <p>Loading Document</p>
              <p><img :src="loader" /></p>
            </div>
            <div v-else v-html="createPreview"></div>
          </div>
        </div>
      </div>
    </main>
  </fragment>
</template>

<script>
import { GET_DOCUMENT_VERSIONS } from "@/modules/shared/documents/graph/queries";
import loader from "@/assets/images/svg/loader.svg";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import { Fragment } from "vue-fragment";

export default {
  name: "VersionPreview",
  title: "Version Preview",
  components: { Fragment },
  props: {
    documentID: {
      type: String,
      required: true,
      default: "",
    },
  },
  data: function () {
    return {
      flyout: false,
      loader,
      html: "",
      documentTemplates: [],
      selected: "",
    };
  },
  computed: {
    createPreview() {
      const cfg = {};
      if (this.documentTemplates.length === 0 || this.selected === "") {
        return "";
      } else {
        let document = this.documentTemplates.find((template) => template.id === this.selected);
        const delta = JSON.parse(document.text);
        const converter = new QuillDeltaToHtmlConverter(delta.ops, cfg);
        return converter.convert();
      }
    },
  },
  methods: {
    isLocation() {
      return !!this.$route.params.locationID;
    },
    editTemplate() {
      if (!this.isLocation()) {
        this.$router.push({ name: "editDocumentTemplate", query: { clientID: this.$props.clientID, documentID: this.selected } });
      } else {
        this.$router.push({ name: "editDocumentTemplate", query: { clientID: this.$props.clientID, locationID: this.$props.locationID, documentID: this.selected } });
      }
    },
  },
  apollo: {
    documentTemplates: {
      query: GET_DOCUMENT_VERSIONS,
      variables() {
        return {
          id: this.$props.documentID,
        };
      },
      result({ data }) {
        this.selected = data.documentTemplates[0].id || "";
      },
    },
  },
};
</script>
<style scoped>
.button-group {
  margin-top: 0;
}
</style>
