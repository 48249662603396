<template>
  <fragment>
    <div class="distribution-report-container">
      <div class="module-m">
        <h2>Reports</h2>
        <div>
          <div class="grid">
            <div class="col-6">
              <p class="label">Choose a Report</p>
              <form-select :value="selected" :options="category" option-label="name" label="" @change="onChange" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="distrubutionAccount" class="sub-cat-container">
        <div class="grid">
          <div class="bar-info-contents">
            <h3>Distribution Report</h3>
            <p>Amount to send to payment processor for partner payments.</p>
          </div>
          <div class="col-8">
            <p class="label">Filters</p>
            <div class="filter-options">
              <div class="filter-input">
                <div class="field">
                  <date-pick :value="filterDate" :display-format="'DD.MM.YYYY'" :format="'YYYY-MM-DD'" @input="updateStartDate($event)">
                    <template #default="{ toggle }">
                      <div class="input-has-icon input-icon-tab">
                        <div class="input-icon" @click="toggle"><i class="icon-calendar"></i></div>
                        <input id="startDate" :value="filterDate" type="text" placeholder="Begin Date" readonly required @click="toggle" />
                      </div>
                    </template>
                  </date-pick>
                </div>
              </div>
            </div>
            <div class="buttons-bar">
              <base-filter name="Payment Processor" :options="statusFilterOptions" :filter-toggle-on="true" @onFilter="buttonsChanged((selectedButton = $event))" />
            </div>
          </div>
        </div>
        <div class="module-xlarge">
          <data-table :entries="distrubutedObj" :headers="reportHeaders" :loading="toBedistributionloader"></data-table>
        </div>
        <div v-if="showTotal" class="total-amount">
          <div class="total-text">TOTAL</div>
          <div class="total-text-amount">{{ sumAmount }}</div>
        </div>
      </div>

      <!-- test -->
      <div v-if="testAccount" class="sub-cat-container">
        <div class="grid">
          <div class="bar-info-contents">
            <h3>Accounts Payable</h3>
            <p>Another report - just testing the report selector for now.</p>
          </div>
        </div>
      </div>
      <!-- test -->
    </div>
  </fragment>
</template>
<style>
.distribution-report-container .total-amount {
  display: inline-flex;
  width: 100%;
  padding: 5px 20px;
  font-weight: 700;
  box-shadow: 0px -1px 0px #999999;
}
.distribution-report-container .bar-info-contents {
  padding: 12px;
}
.distribution-report-container .bar-info-contents h3 {
  margin-bottom: 0;
}

.distribution-report-container .select-container select {
  width: 50%;
}
.distribution-report-container .total-amount .total-text {
  width: 50%;
}
.distribution-report-container .total-amount .total-text-amount {
  text-align: end;
  width: 50%;
}

.distribution-report-container .buttons-bar {
  margin-top: 15px;
}
</style>
<script>
import { Fragment } from "vue-fragment";
import DatePick from "vue-date-pick";
import FormSelect from "@/components/forms/fields/FormSelect";
import { DataTable, BaseFilter } from "@/components";
import TableHeader from "@/components/table/TableHeader";
import {
  // GET_DISTRIBUTION,
  GET_DISTRIBUTION_BY_DATE,
} from "@/modules/admin/accounting/distribution-reports/graph/queries";
import { formatDate, formatMoney } from "@/filters";
import { DateTime } from "luxon";

export default {
  name: "DistributionReport",
  components: {
    DatePick,
    FormSelect,
    DataTable,
    Fragment,
    BaseFilter,
  },
  data: function () {
    return {
      selectedButton: [],
      filterDate: DateTime.now().toFormat("yyyy-MM-dd"),
      selected: "distribution",
      distrubutedObj: [],
      toBedistributionloader: false,
      sumAmount: 0,
      showTotal: false,
      statusFilterOptions: [
        { id: "Branch", name: "Branch", label: "Branch", checked: false, value: "Branch" },
        { id: "EZ Coastal", name: "EZ Coastal", label: "EZ Coastal", checked: false, value: "EZ Coastal" },
      ],
      category: [
        { id: "distribution", name: "Distribution", label: "Distribution" },
        { id: "account-payable", name: "Accounts Payable", label: "Accounts Payable" },
      ],
    };
  },
  computed: {
    reportHeaders() {
      return [
        new TableHeader({ cellContents: "Name", headerClass: "cell30", label: "Client / Location", name: "Name" }),
        new TableHeader({ cellContents: "PaymentProcessor", headerClass: "cell30", label: "Payment Processor", name: "PaymentProcessor" }),
        new TableHeader({ cellContents: "PayPeriod", headerClass: "cell25", label: "Pay Period", name: "PayPeriod" }),
        new TableHeader({ cellContents: "TotalPayout", headerClass: "cell25 text-right", cellClass: "text-right", label: "Distribution Amount", name: "TotalPayout" }),
      ];
    },
    distrubutionAccount() {
      return this.selected === "distribution";
    },
    testAccount() {
      return this.selected === "account-payable";
    },
  },
  methods: {
    updateStartDate(value) {
      this.filterDate = value;
      if (this.selectedButton.length == 0) {
        this.selectedButton = ["EZ Coastal", "Branch"];
      }
      this.$apollo.query({ query: GET_DISTRIBUTION_BY_DATE, variables: { filteredDate: this.filterDate, paymentProcessor: this.selectedButton } }).then((data) => {
        if (data.data.distributions.length == 0) {
          this.showTotal = false;
          this.distrubutedObj = [];
        } else {
          this.distrubutedObj = [];
          this.showTotal = true;
          this.toBedistributionloader = false;
          data.data.distributions.forEach((item) => {
            let customObj = {
              Id: item.id,
              Name: item.client.name,
              Distributed: formatDate(item.distributedDateTime),
              PaymentProcessor: item.paymentProcessor ? item.paymentProcessor : "",
              PayPeriod: `${formatDate(item.payPeriodStartDate)} - ${formatDate(item.payPeriodEndDate)}`,
              TotalPayout: `${formatMoney(item.totalPayout.toFixed(2))}`,
              ToBeDistributed: true,
              settlementID: item.depositSummaries ? item.depositSummaries[0].settlementID : "",
              payPeriodEnd: item.payPeriodEndDate,
              totalPay: item.totalPayout,
            };
            this.distrubutedObj.push(customObj);
          }, this);
          let totals = this.sum(this.distrubutedObj);
          this.sumAmount = `${formatMoney(totals.toFixed(2))}`;
        }
      });
    },
    onChange(item) {
      this.selected = item;
    },
    sum(lineItems) {
      return lineItems.reduce((acc, curr) => {
        const num = parseFloat(curr.totalPay);
        return acc + num;
      }, 0);
    },
    buttonsChanged(button) {
      this.distrubutedObj = [];
      if (button.length > 0) {
        button.forEach((item, index) => {
          if (item == "ez coastal") {
            button[index] = "EZ Coastal";
          } else if (item == "branch") {
            button[index] = "Branch";
          }
        });
        this.selectedButton = button;
      }
      this.updateStartDate(this.filterDate);
    },
  },
  apollo: {
    distributions: {
      query: GET_DISTRIBUTION_BY_DATE,
      variables() {
        return {
          paymentProcessor: ["EZ Coastal", "Branch"],
          filteredDate: this.filterDate,
        };
      },
      result({ data }) {
        if (data.distributions.length == 0) {
          this.showTotal = false;
        } else {
          this.showTotal = true;
          this.distrubutedObj = [];
          this.toBedistributionloader = false;
          data.distributions.forEach((item) => {
            let customObj = {
              Id: item.id,
              Name: item.client.name,
              Distributed: formatDate(item.distributedDateTime),
              PaymentProcessor: item.paymentProcessor ? item.paymentProcessor : "",
              PayPeriod: `${formatDate(item.payPeriodStartDate)} - ${formatDate(item.payPeriodEndDate)}`,
              TotalPayout: `${formatMoney(item.totalPayout.toFixed(2))}`,
              ToBeDistributed: true,
              settlementID: item.depositSummaries ? item.depositSummaries[0].settlementID : "",
              payPeriodEnd: item.payPeriodEndDate,
              totalPay: item.totalPayout,
            };
            this.distrubutedObj.push(customObj);
          }, this);
          let totals = this.sum(this.distrubutedObj);
          this.sumAmount = `${formatMoney(totals.toFixed(2))}`;
        }
      },
    },
  },
};
</script>
