<template>
  <div class="overlay" @click="showFlyout('')" />
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "Overlay",
  methods: {
    ...mapActions(["showFlyout"]),
  },
};
</script>
