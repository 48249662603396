<template>
  <button class="button secondary" @click="click">Add Partner</button>
</template>

<script>
import micromodal from "micromodal";

export default {
  name: "AddPartnerButton",
  props: {
    locationId: {
      type: String,
      required: true,
    },
  },
  methods: {
    click() {
      micromodal.show("modal-add-partner", {});
    },
  },
};
</script>
