import { AddEditLayout } from "@/components";

const ClientContactsAdd = () => import("@/modules/admin/clients/contacts/ClientContactsAdd");
const ClientContactsEdit = () => import("@/modules/admin/clients/contacts/ClientContactsEdit");

export default [
  {
    path: "contacts/add",
    name: "clientContactsAdd",
    component: ClientContactsAdd,
    meta: { layout: AddEditLayout },
  },
  {
    path: "contacts/:contactId/edit",
    name: "clientContactsEdit",
    component: ClientContactsEdit,
    meta: { layout: AddEditLayout },
  },
];
