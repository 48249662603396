const getInitialState = () => {
  return {
    selectedEmployee: {},
    startDate: null,
    endDate: null,
    candidatesTab: {
      statusAlertUrgency: ["ON_HOLD", "NEEDED", "DID_NOT_QUALIFY", "CONSIDER"],
      normalUrgency: ["COMPLETE", "APPROVAL", "STARTED", "SUBMITTED", "UNFINISHED", "AUTO", "INCOMPLETE"],
      dismissedUrgency: ["WITHDRAWN"],
      mvrDeniedUrgency: ["MVR_DENIED"],
      cbcDeniedUrgency: ["CBC_DENIED"],
      invalidUrgency: ["INVALID"],
      neededUrgency:["NEEDED"],
      insuranceUrgency: ["Insurance Incomplete"],
      applicationOnHold: ["ONHOLD"],
      locationsFilterOptions: [],

      stagesFilterOptions: [
        // { id: "oc-unapproved", name: "UNAPPROVED", label: "Not Yet Approved", checked: false },
        { id: "oc-application", name: "APPLICATION", label: "Application" },
        { id: "oc-review", name: "REVIEW", label: "Review" },
        { id: "oc-enrollment", name: "ENROLLMENT", label: "Enrollment" },
        { id: "oc-riskMitigation", name: "RISK_MITIGATION", label: "Risk Mitigation" },
        { id: "oc-completion", name: "COMPLETION", label: "Completion" },
        { id: "oc-approved", name: "APPROVAL", label: "Approval" }
      ],
      urgencyFilterOptions: [
        { id: "oc-status-alert", name: "statusAlert", label: "Status Alert" },
        { id: "oc-time-alert", name: "timeAlert", label: "Time Alert" },
        { id: "oc-normal", name: "normal", label: "Normal" },
        { id: "oc-dismissed", name: "dismissed", label: "Dismissed" },
       // { id: "oc-denied", name: "denied", label: "Denied" },
        { id: "oc-mvr-denied", name: "mvrDenied", label: "MVR Denied" },
        { id: "oc-cbc-denied", name: "cbcDenied", label: "CBC Denied" },
        { id: "oc-invalid", name: "invalid", label: "Invalid" },
        {id: "oc-needed",name:"needed",label: "Needed"},
        {id: "oc-insurance-incomplete", name: "insurance-incomplete", label: "Insurance Alert"},
        { id: "oc-onhold", name: "onHold", label: "On-Hold" }
      ],
      timeInFilterOptions: [
        { id: "oc-one", name: "1", label: "1 Day"},
        { id: "oc-seven", name: "7", label: "7 Days"},
        { id: "oc-thirty", name: "30", label: "30 Days"},
        { id: "oc-sixty", name: "60", label: "60 Days"},
        { id: "oc-one-eighty", name: "180", label: "180 Days"},
        { id: "oc-one-eighty-plus", name: "180+", label: "180+ Days"},
      ],
      selectedUrgencies: [],
      selectedStages: [],
      selectedLocations: [],
      selectedTimeIn: [],
      locations: [],
    },
  };
};

const state = getInitialState();

const getters = {
  getSelectedEmployee: (state) => {
    return state.selectedEmployee;
  },
  getStartDate: (state) => {
    return state.startDate;
  },
  getEndDate: (state) => {
    return state.endDate;
  },
  getUrgencyFilterData: (state) => {
    let data = [];
    if (state.candidatesTab.selectedUrgencies.length > 0) {
      for (let i = 0; i < state.candidatesTab.selectedUrgencies.length; i++) {
        switch (state.candidatesTab.selectedUrgencies[i]) {
          case "status alert":
            for (let i = 0; i < state.candidatesTab.statusAlertUrgency.length; i++) {
              data.push(state.candidatesTab.statusAlertUrgency[i]);
            }
            break;
          case "normal":
            for (let i = 0; i < state.candidatesTab.normalUrgency.length; i++) {
              data.push(state.candidatesTab.normalUrgency[i]);
            }
            break;
          case "dismissed":
            for (let i = 0; i < state.candidatesTab.dismissedUrgency.length; i++) {
              data.push(state.candidatesTab.dismissedUrgency[i]);
            }
            break;
          case "mvr denied":
            for (let i = 0; i < state.candidatesTab.mvrDeniedUrgency.length; i++) {
              data.push(state.candidatesTab.mvrDeniedUrgency[i]);
            }
            break;

            case "cbc denied":
              for (let i = 0; i < state.candidatesTab.cbcDeniedUrgency.length; i++) {
                data.push(state.candidatesTab.cbcDeniedUrgency[i]);
              }
              break;

            case "invalid":
              for (let i = 0; i < state.candidatesTab.invalidUrgency.length; i++) {
                data.push(state.candidatesTab.invalidUrgency[i]);
              }
              break;
            case "needed":
              for (let i = 0; i < state.candidatesTab.neededUrgency.length; i++) {
                data.push(state.candidatesTab.neededUrgency[i]);
              }
              break;
            case "insurance alert":
              for (let i = 0; i < state.candidatesTab.insuranceUrgency.length; i++) {
                data.push(state.candidatesTab.insuranceUrgency[i]);
              }
              break;
            case "on-hold":
              for (let i = 0; i < state.candidatesTab.applicationOnHold.length; i++) {
                data.push(state.candidatesTab.applicationOnHold[i]);
              }
              break;

        }
      }
    } else {
      data = [
        "ON_HOLD",
        "NEEDED",
        "DID_NOT_QUALIFY",
        "COMPLETE",
        "APPROVAL",
        "STARTED",
        "SUBMITTED",
        "UNFINISHED",
        "AUTO",
        "INCOMPLETE",
        "MVR Submitted",
        "MVR Consider",
        "CBC Submitted",
        "COMPLETE",
        "CONSIDER",
        "MANUAL",
        "APPROVED",
        "CBC-PROMOTED",
        "MVR Submitted - Needed",
        "CBC Submitted - Needed",
        "ONHOLD",
        "Insurance Needed",
        "Insurance Complete",
        "Insurance Incomplete",
        "CHKR_ONBOARD_ERROR",
        "CHKR_REPORT_ERROR_MVR",
        "CHKR_REPORT_ERROR_CBC"
        // "CBC_DENIED",
        // "MVR_DENIED",
        // "WITHDRAWN",
        // "DENIED",
        // "INVALID",
      ];
    }
    return data;
  },
  getLocationFilterData: (state) => {
    let data;
    if (state.candidatesTab.selectedLocations.length > 0) {
      data = state.candidatesTab.selectedLocations.map((item) => {
        return item.id;
      });
    } 
    else{
      data=[]
    }
    // else {
    //   data = state.candidatesTab.locationsFilterOptions.map((item) => {
    //     return item.id;
    //   });
    // }
    return data;
  },

  getAllLocationsData:(state)=>{
    let data;
    data = state.candidatesTab.locationsFilterOptions.map((item) => {
          return item.id;
        });
        return data;
  },
  getStagesFilterData: (state) => {
    let data = [];
    if (state.candidatesTab.selectedStages.length > 0) {
      switch (state.candidatesTab.selectedStages[0]) {
        case "risk mitigation":
          data.push(state.candidatesTab.selectedStages[0].replaceAll(" ", "_").toUpperCase());
          return data;
        case "not yet approved":
          data.push("UNAPPROVED");
          return data;
        case "approved":
          data.push("APPROVAL");
          return data;
        default:
          data.push(state.candidatesTab.selectedStages[0].toUpperCase());
          return data;
      }
    } else {
      data = state.candidatesTab.stagesFilterOptions.map((item) => {
        return item.name;
      });
    }
    return data;
  },
  getTimeInFilterData: (state) => {
    let data = "";
    if (state.candidatesTab.selectedTimeIn.length > 0) {
      state.candidatesTab.selectedTimeIn.forEach((item) => {
        data = item;
      })
    }
    return data;
  }
};

const mutations = {
  setSelectedEmployee: (state, payload) => {
    state.selectedEmployee = payload;
  },
  setStartDate: (state, payload) => {
    state.startDate = payload;
  },
  setEndDate: (state, payload) => {
    state.endDate = payload;
  },
  clearOnboarding: (state) => {
    Object.assign(state, getInitialState());
  },
  setSelectedUrgencies: (state, payload) => {
    state.candidatesTab.selectedUrgencies = [...payload];
  },
  setSelectedStages: (state, payload) => {
    state.candidatesTab.selectedStages = [...payload];
  },
  setSelectedTimeIn: (state, payload) => {
    state.candidatesTab.selectedTimeIn = [...payload];
  },
  setSelectedLocations: (state, payload) => {
    state.candidatesTab.selectedLocations = [...payload];
  },
  setLocationsFilterOptions: (state, payload) => {
    state.candidatesTab.locationsFilterOptions = [...payload];
  },
  restoreCandidatesTabState: (state) => {
    state.candidatesTab.urgencyFilterOptions.forEach((item) => {
      if (state.candidatesTab.selectedUrgencies.includes(item.name)) {
        item.checked = true;
      }
    });
    state.candidatesTab.stagesFilterOptions.forEach((item) => {
      if (state.candidatesTab.selectedStages.includes(item.label.toLowerCase())) {
        item.checked = true;
      }
    });
  },
};

const actions = {
  updateSelectedEmployee: ({ commit }, payload) => {
    commit("setSelectedEmployee", payload);
  },
  updateStartDate: ({ commit }, payload) => {
    commit("setStartDate", payload);
  },
  updateEndDate: ({ commit }, payload) => {
    commit("setEndDate", payload);
  },
  clearOnboarding: ({ commit }) => {
    commit("clearOnboarding");
  },
  updateSelectedUrgencies: ({ commit }, payload) => {
    commit("setSelectedUrgencies", payload);
  },
  updateSelectedStages: ({ commit }, payload) => {
    commit("setSelectedStages", payload);
  },
  updateSelectedTimeIn: ({ commit }, payload) => {
    commit("setSelectedTimeIn", payload);
  },
  updateSelectedLocations: ({ commit }, payload) => {
    commit("setSelectedLocations", payload);
  },
  updateLocationsFilterOptions: ({ commit }, payload) => {
    commit("setLocationsFilterOptions", payload);
  },
  restoreCandidatesTabState: ({ commit }) => {
    commit("restoreCandidatesTabState");
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
