import gql from "graphql-tag";

export const GET_TEMPLATE_USED_BY_CLIENTS = gql`
  query GetTemplateUsedByClients($templateID: uuid) {
    inheritedClients: documentTemplateOverrides(where: { templateID: { _eq: $templateID } }) {
      id
      clientID
    }
    allClients: clients(where: { active: { _eq: true } }) {
      id
      name
    }
  }
`;
export const GET_TEMPLATE_USED_BY_LOCATIONS = gql`
  query GetTemplateUsedByLocations($templateID: uuid, $clientID: uuid) {
    inheritedLocations: documentTemplateOverrides(where: { templateID: { _eq: $templateID }, clientID: { _eq: $clientID } }) {
      id
      locationID
    }
    allClientLocations: clientLocations(where: { client: { id: { _eq: $clientID }, active: { _eq: true } } }) {
      id
      name
    }
  }
`;
export const GET_TEMPLATE_USED_BY_PARTNERS = gql`
  query GetTemplateUsedByPartners($templateID: uuid, $clientID: uuid, $locationID: uuid) {
    inheritedLocations: documentTemplateUse(where: { templateID: { _eq: $templateID }, clientID: { _eq: $clientID }, clientLocationID: { _eq: $locationID } }, distinct_on: clientLocationID) {
      id
      partner {
        id
        firstName
        lastName
      }
    }
    allLocationPartners: clientLocationPartners(where: { clientLocationID: { _eq: $locationID } }) {
      id
      location {
        name
      }
      partner {
        firstName
      }
    }
  }
`;
export const GET_DOCUMENT_BY_ID = gql`
  query GetDocumentByID($id: uuid!) {
    template: documentTemplates_by_pk(id: $id) {
      id
      version
      title
      text
      status
      originalID
      isDDI
      overrides {
        id
        type
        clientID
        locationID
        templateID
      }
    }
  }
`;

export const GET_DOCUMENT_VERSIONS = gql`
  query GetDocumentVersions($id: uuid) {
    documentTemplates(where: { _or: [{ id: { _eq: $id } }, { originalID: { _eq: $id } }] }, order_by: { version: desc }) {
      id
      title
      version
      text
      updated_at
      originalID
      updatedBy
    }
  }
`;
export const GET_DOCUMENT_PREVIEW = gql`
  query GetDocumentPreview($templateID: uuid!) {
    documentTemplates_by_pk(id: $templateID) {
      id
      title
      text
      html
      updated_at
    }
  }
`;

export const GET_CLIENT_LOCATION_IDs = gql`
  query getClientLocationIDs($id: uuid) {
    clientLocations(where: { client: { id: { _eq: $id } } }) {
      id
      name
    }
  }
`;

export const GET_DOCUMENT_PARTNER_INFO = gql`
  query documentPartnerInfo($templateID: uuid) {
    documents(where: { templateID: { _eq: $templateID } }) {
      id
      partner {
        id
        email
        phone
      }
    }
  }
`;

export const DOCUMENT_TEMPLATE_USE = gql`
  query documentTemplateUse($id: uuid) {
    documentTemplateUse(where: { _or: [{ clientID: { _eq: $id } }, { clientLocationID: { _eq: $id } }] }) {
      id
      template {
        id
        title
      }
    }
  }
`;
export const DOCUMENT_TEMPLATE_USE_ORIGINAL = gql`
  query documentTemplateUseOriginal($id: uuid) {
    documentTemplateUse(where: { clientID: { _eq: $id }, status: { _eq: "original" }, template: { status: { _neq: "published" } } }) {
      id
      status
      template {
        id
        title
        status
      }
    }
  }
`;

export const GET_DDI_TEMPLATES = gql`
  query documentTemplateUse($status: String) {
    documentTemplateUse(where: { status: { _eq: "original" }, template: { isDDI: { _eq: true }, status: { _eq: $status } } }) {
      id
      status
      template {
        id
        title
        text
        isDDI
        updatedBy
        updated_at
        status
      }
    }
  }
`;

export const GET_LOCATION_IN_USE_COUNT = gql`
  query GetLocationInUse($templateID: uuid, $clientID: uuid, $clientLocationID: uuid) {
    documentTemplateUse_aggregate(
      where: { _and: [{ templateID: { _eq: $templateID } }, { clientID: { _eq: $clientID } }, { clientLocationID: { _eq: $clientLocationID } }, { status: { _eq: "inherited" } }] }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_LIST_OF_LOCATIONS_AND_PARTNERS = gql`
  query GetListOfLocations($_or: [clientLocations_bool_exp]!) {
    clientLocations(where: { _or: $_or }) {
      id
      clientID
      partners {
        id
        partnerID
        location {
          id
          client {
            id
          }
        }
      }
    }
  }
`;

export const GET_LOCATIONS = gql`
  query GetListOfLocations($_or: [uuid!]) {
    clientLocations(where: { clientID: { _in: $_or } }) {
      id
      clientID
    }
  }
`;

export const GET_LIST_OF_PARTNERS = gql`
  query GetListOfPartners($_or: [clientLocationPartners_bool_exp]!) {
    clientLocationPartners(where: { _or: $_or }) {
      id
      partnerID
      location {
        id
        client {
          id
        }
      }
    }
  }
`;

export const GET_DDI_TEMPLATE_IN_USE_STATUS = gql`
  query GetDdiTemplateInheritanceStatus($templateID: uuid) {
    clients: documentTemplateUse_aggregate(where: { templateID: { _eq: $templateID }, clientLocationID: { _is_null: true }, status: { _eq: "inherited" } }) {
      aggregate {
        count
      }
    }
    locations: documentTemplateUse_aggregate(where: { templateID: { _eq: $templateID }, clientLocationID: { _is_null: false }, partnerID: { _is_null: true }, status: { _eq: "inherited" } }) {
      aggregate {
        count
      }
    }
    partners: documentTemplateUse_aggregate(where: { templateID: { _eq: $templateID }, partnerID: { _is_null: false }, status: { _eq: "inherited" } }) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_CLIENT_TEMPLATE_IN_USE_STATUS = gql`
  query GetClientTemplateInheritanceStatus($templateID: uuid) {
    locations: documentTemplateUse_aggregate(where: { templateID: { _eq: $templateID }, clientLocationID: { _is_null: false }, partnerID: { _is_null: true }, status: { _eq: "inherited" } }) {
      aggregate {
        count
      }
    }
    partners: documentTemplateUse_aggregate(where: { templateID: { _eq: $templateID }, partnerID: { _is_null: false }, status: { _eq: "inherited" } }) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_FUNNEL_AND_DOCUMENT_TEMPLATE_ID = gql`
  query getFunnelID($cid: uuid!) {
    clientLocations(where: { id: { _eq: $cid } }) {
      funnel {
        id
      }
    }
  }
`;

export const DOCUMENT_TEMPLATE_ORIGINAL_SUBSCRIPTION = gql`
  subscription documentTemplateUseOriginal($id: uuid, $locationID: uuid) {
    documentTemplates(where: { _and: [{ _or: [{ locationID: { _eq: $locationID } }, { clientID: { _eq: $id } }] }, { isDDI: { _eq: false } }, { status: { _in: ["published", "inactive"] } }] }) {
      id
      isDDI
      clientID
      locationID
      status
      title
      originalID
      version
      text
      html
    }
  }
`;

export const DDI_DOCUMENT_TEMPLATE_ORIGINAL_SUBSCRIPTION = gql`
  subscription documentTemplateUseOriginal {
    documentTemplates(where: { isDDI: { _eq: true }, status: { _in: ["published", "inactive"] } }) {
      id
      isDDI
      clientID
      locationID
      status
      title
      originalID
      version
      text
      html
    }
  }
`;
