<template>
  <create-edit-document save-label="Create" :possible-assignments="clients" :copy-template-id="documentID" is-client @onSave="saveDocument" />
</template>
<script>
// 1. Get DocumentTemplate info and fill in fields
// 1a. Insert assignments
// 2. Increment the version
// 3. Add inherited from documentTemplateID to previousID field. Pass originalID through
// 4. Add documentTemplate and documentTemplateUse
// 5. Remove record of inherited from documentTemplateID where documentTemplateID matches

import { v4 as uuidv4 } from "uuid";
import CreateEditDocument from "@/modules/shared/documents/CreateEditDocument";
import { GET_CLIENTS_DETAILS } from "@/modules/admin/clients/graph/queries";
import { mapGetters } from "vuex";
import documentService from "@/services/document.service";

export default {
  name: "CreateDdiDocument",
  components: { CreateEditDocument },
  props: {
    inherited: {
      type: Boolean,
      required: false,
      default: false,
    },
    documentID: {
      type: String,
      required: false,
      default: () => null,
    },
    partnerId: {
      type: String,
      required: false,
      default: () => null,
    },
  },
  data: () => ({
    clients: [],
  }),
  computed: {
    ...mapGetters(["getFullName"]),
  },
  methods: {
    insertTemplate(template) {
      const variables = {
        id: template.id,
        title: template.title,
        originalID: template.id,
        version: 1,
        updatedBy: template.updatedBy,
        text: template.text,
        html: template.html,
        isDDI: true,
        templateStatus: template.status,
      };
      documentService.createDocumentTemplate(variables);
    },
    async saveDocument(event) {
      let removeOverrideClientIDs;
      const id = uuidv4();
      const template = event.template;
      template.updatedBy = this.getFullName;
      template.id = id;

      await this.insertTemplate(template);

      if (template.status === "draft" || template.status === "inactive") {
        removeOverrideClientIDs = event.assignments;
      } else {
        // TODO: Add to funnel documents
        removeOverrideClientIDs = event.deSelected;
        await documentService.clearRemoveClientOverride(event.assignments, template.id);
      }

      await documentService.setRemoveOverrideClients(removeOverrideClientIDs, template.id, template.updatedBy);
      this.$router.back();
    },
  },
  apollo: {
    clients: {
      query: GET_CLIENTS_DETAILS,
    },
  },
};
</script>
