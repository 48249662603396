<template>
  <fragment>
    <div class="module-large">
      <h2>Partner Data</h2>
      <div class="metricWrapper">
        <div class="metric">
          Total Partners
          <p>{{ TotalPartners | formatNumber }}</p>
        </div>
        <div class="metric">
          Approved Partners
          <p>{{ ApprovedPartners | formatNumber }}</p>
        </div>
      </div>
      <h2>Branch Status</h2>
      <div class="metricWrapper">
        <div class="metric">
          Active
          <p>{{ BranchActive | formatNumber }}</p>
        </div>
        <div class="metric">
          Unclaimed
          <p>{{ BranchUnclaimed | formatNumber }}</p>
        </div>
        <div class="metric">
          No Wallet
          <p>{{ NoWallet | formatNumber }}</p>
        </div>
      </div>
      <br />
      <item-picker :search-results="searchResults" :selected="selectedLocations" placeholder="Search Locations" auto-select no-title @itemsChanged="updateLocation" />
      <base-filter name="Status" type="checkbox" :options="filterOptions" @onFilter="filterStatus" />
      <data-table
        :headers="headersText"
        :entries="partners"
        :loading="loader"
        :pagination-total-count="totalCount"
        :pagination-per-page="perPage"
        :pagination-current-page="currentPage"
        display-rows-per-page-max-selector
        @onChangePerPage="onChangePerPage($event)"
        @onPaginate="onPaginate($event)"
      >
        <template #name="{ item }">
          <router-link :to="{ name: 'partner', params: { id: item.id } }">{{ item.name }}</router-link>
        </template>
      </data-table>
    </div>
  </fragment>
</template>
<script>
import { DataTable, TableHeader } from "@/components";
import { Fragment } from "vue-fragment";
// import { BRANCH_STATUS, BRANCH_STATUS_LOCATIONS, CLIENT_LOCATIONS } from "@/modules/admin/ddi/graph/queries";
import ItemPicker from "@/components/pickers/ItemPicker";
// import { APPROVED_PARTNERS, BRANCH_ACTIVE, BRANCH_NO_WALLET, BRANCH_UNCLAIMED, TOTAL_PARTNERS } from "@/modules/admin/ddi/graph/subscriptions";
import BaseFilter from "@/components/filters/BaseFilter";
import { restApi } from "@/http/http.config";
import {encodeWithParam} from "@/util/Base64Encoding";

export default {
  name: "BranchStatus",
  components: {
    BaseFilter,
    ItemPicker,
    DataTable,
    Fragment,
  },
  data: function () {
    return {
      clientLocationPartners: [],
      clientLocations: [],
      locationID: null,
      totalCount: 0,
      currentPage: 1,
      perPage: 50,
      offset: 0,
      searchTerm: "",
      selectedLocations: [],
      selectedLocationIDs: [],
      TotalPartners: 0,
      ApprovedPartners:0,
      BranchActive: 0,
      BranchUnclaimed: 0,
      NoWallet: 0,
      endDate: "",
      filteredStatuses: ["UNCLAIMED"],
      filterOptions: [
        { id: "UNCLAIMED", name: "UNCLAIMED", label: "Unclaimed", checked: true },
        { id: "CLAIMED", name: "CLAIMED", label: "Claimed", checked: false },
        { id: "ACTIVE", name: "ACTIVE", label: "Active", checked: false },
        { id: "PENDING", name: "PENDING", label: "Pending", checked: false },
      ],
      loader: false
    };
  },
  computed: {
    headersText() {
      return [
        new TableHeader({
          label: "Name",
          name: "name",
          headerClass: "cell20",
          // cellContents: (item) => item.name,
          sortable: false,
        }),
        new TableHeader({
          label: "Status",
          name: "status",
          headerClass: "cell20",
          cellContents: (item) => item.status,
          sortable: false,
        }),
        // new TableHeader({
        //   label: "Processor",
        //   name: "processor",
        //   cellContents: (item) => item.processor,
        //   sortable: true,
        // }),
        new TableHeader({
          label: "Magic Link",
          name: "magicLink",
          cellContents: (item) => item.magicLink,
          sortable: false,
        }),
      ];
    },
    searchResults() {
      return this.clientLocations.filter((location) => location.name.includes(this.searchTerm));
    },
    partners() {
      return this.clientLocationPartners;
      // return this.clientLocationPartners.map((locationPartner) => {
      //   const partner = locationPartner.partner;
      //   const paymentProcessor = locationPartner.partner.paymentProcessor;
      //   return {
      //     id: partner.id,
      //     name: `${partner.firstName} ${partner.lastName}`,
      //     processor: paymentProcessor ? paymentProcessor.paymentProcessor : "N/A",
      //     status: paymentProcessor ? paymentProcessor.status : "Not Run",
      //     magicLink: paymentProcessor ? paymentProcessor.magicLink : "",
      //   };
      // });
    },
  },
  mounted() {
    this.getAllLocations(),
    this.branchWalletKPI(),
    this.getPartnersByLocation()
  },
  methods: {
    async getAllLocations() {
      await restApi.get(`/location/getLocations`).then((responseData)=>{
        // console.log("getAllLocations", responseData.data.clientLocations);
        this.clientLocations = responseData.data.clientLocations;
      });
    },
    async getPartnersByLocation() {
      this.loader = true;
      const param = {
        limit: this.perPage,
        offset: this.offset,
        status: this.filteredStatuses,
        locationIDs: this.selectedLocationIDs
      }
      // console.log("param", param);
      await restApi.post(`/comm/PartnersByLocation`, encodeWithParam(param)).then((responseData)=>{
        // console.log("PartnersByLocation", responseData.data);
        this.clientLocationPartners = responseData.data.data;
        this.totalCount = responseData?.data?.rowCount || 0;

        this.loader = false;
      });
    },
    async branchWalletKPI(){
      await restApi.get(`/comm/branchWalletKPI`).then((responseData)=>{
        // console.log("branchWalletKPI", responseData.data.data);
        this.TotalPartners = responseData?.data?.data?.TotalPartners;
        this.ApprovedPartners = responseData?.data?.data?.ApprovedPartners;
        this.BranchActive = responseData?.data?.data?.BranchActive;
        this.BranchUnclaimed = responseData?.data?.data?.BranchUnclaimed;
        this.NoWallet = responseData?.data?.data?.NoWallet;
      });
    },
    filterStatus(event) {
      this.filteredStatuses = event.map((item) => item.toUpperCase()).filter((item) => item !== "NOT RUN");
      this.getPartnersByLocation();
    },
    onPaginate(event) {
      let definedPage = Number(event.pageNumber);
      if (definedPage <= Math.ceil(this.totalCount / this.perPage) && definedPage > 0) {
        this.currentPage = Number(event.pageNumber);
        this.offset = event.offset;
        this.getPartnersByLocation();
      }
    },
    onChangePerPage(event) {
      this.perPage = Number(event.perPage);
      this.getPartnersByLocation();
    },
    updateLocation(itemChangedEvent) {
      if (itemChangedEvent.items.length !== 0) {
        this.selectedLocationIDs = itemChangedEvent.items.map((item) => item.id);
      } else {
        this.selectedLocationIDs = [];
      }
      this.getPartnersByLocation();
      // this.$apollo.queries.clientLocationPartners.refetch();
      // this.$emit("clientManagerChanged", itemChangedEvent);
    },
  },
  // apollo: {
  //   clientLocations: {
  //     fetchPolicy: "no-cache",
  //     query: CLIENT_LOCATIONS,
  //   },
  //   clientLocationPartners: {
  //     fetchPolicy: "no-cache",
  //     query() {
  //       return this.selectedLocationIDs.length > 0 ? BRANCH_STATUS_LOCATIONS : BRANCH_STATUS;
  //     },
  //     variables() {
  //       let variables = { limit: this.perPage, offset: this.offset, status: this.filteredStatuses };
  //       if (this.selectedLocationIDs.length > 0) {
  //         variables.locationIDs = this.selectedLocationIDs;
  //       }
  //       return variables;
  //     },

  //     update: (data) => data.clientLocationPartners,
  //     result({ data }) {
  //       if (data?.total?.aggregate?.count || data?.total?.aggregate?.count === 0) {
  //         this.totalCount = data?.total?.aggregate?.count || data?.total?.aggregate?.count === 0;
  //       }
  //       // this.totalCount = data?.total?.aggregate?.count || data?.total?.aggregate?.count === 0 ? data.total.aggregate.count : this.totalCount;
  //       this.clientLocationPartners = data?.clientLocationPartners ? data?.clientLocationPartners : [];
  //     },
  //   },
  //   $subscribe: {
  //     TotalPartners: {
  //       query: TOTAL_PARTNERS,
  //       result({ data }) {
  //         this.TotalPartners = data.TotalPartners.aggregate.count;
  //       },
  //     },
  //     ApprovedPartners: {
  //       query: APPROVED_PARTNERS,
  //       result({ data }) {
  //         this.ApprovedPartners = data.ApprovedPartners.aggregate.count;
  //       },
  //     },
  //     BranchActive: {
  //       query: BRANCH_ACTIVE,
  //       result({ data }) {
  //         this.BranchActive = data.BranchActive.aggregate.count;
  //       },
  //     },
  //     BranchUnclaimed: {
  //       query: BRANCH_UNCLAIMED,
  //       result({ data }) {
  //         this.BranchUnclaimed = data.BranchUnclaimed.aggregate.count;
  //       },
  //     },
  //     NoWallet: {
  //       query: BRANCH_NO_WALLET,
  //       result({ data }) {
  //         this.NoWallet = data.NoWallet.aggregate.count;
  //       },
  //     },
  //   },
  // },
};
</script>
<style scoped>
.metricWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
}
.metric {
  padding: 5px;
  width: 250px;
  height: 100px;
  background-color: #84c2d9;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.1rem;
}
</style>
