<template>
  <a class="button secondary" @click="cancel">
    {{ $props.cancelLabel }}
  </a>
</template>
<script>
export default {
  props: {
    cancelLabel: {
      type: String,
      required: false,
      default: "Cancel",
    },
    cancelRoute: {
      type: String,
      required: false,
      default: "",
    },
    cancelAction: {
      type: Function,
      required: false,
      default: null,
    },
  },
  methods: {
    cancel() {
      if (this.$props.cancelAction) {
        this.$props.cancelAction();
      } else if (this.$props.cancelRoute) {
        this.$router.push(this.$props.cancelRoute);
      } else {
        this.$router.back();
      }
    },
  },
};
</script>
