<template>
  <li v-show="$props.show">
    <a @click.prevent="handleClick">{{ $props.label }}</a>
  </li>
</template>
<script>
export default {
  name: "MenuItem",
  props: {
    label: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: false,
      default: true,
    },
    onClick: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  methods: {
    handleClick() {
      this.$props.onClick();
    },
  },
};
</script>
