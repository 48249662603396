export default {
  general: {
    name: "General",
    mergeTags: {
      date: {
        name: "Date",
        value: "{{date}}",
        sample: "03/16/2021",
      },
    },
  },
  partner: {
    name: "Partner",
    mergeTags: {
      fullName: {
        name: "Full Name",
        value: "{{partner.name}}",
        sample: "John Doe",
      },
      firstName: {
        name: "First Name",
        value: "{{partner.firstName}}",
        sample: "John",
      },
      lastName: {
        name: "Last Name",
        value: "{{partner.lastName}}",
        sample: "Doe",
      },
      contractDate: {
        name: "Contract Date",
        value: "{{partner.contractDate}}",
        sample: "1990-01-01",
      },
      dateOfBirth: {
        name: "Date of Birth",
        value: "{{partner.dateOfBirth}}",
        sample: "1990-01-01",
      },
      phone: {
        name: "Phone Number",
        value: "{{partner.phone}}",
        sample: "555-555-5555",
      },
      email: {
        name: "Email",
        value: "{{partner.email}}",
        sample: "sample@sample.com",
      },
      signature: {
        name: "Signature",
        value: "<span class='signature-font'>{{partner.signature}}</span>",
        sample: "John Doe",
      },
      initials: {
        name: "Initials",
        value: "{{partner.initials}}",
        sample: "JD",
      },
      address: {
        name: "Address",
        mergeTags: {
          address_1: {
            name: "Address 1",
            value: "{{partner.address.street1}}",
            sample: "1 Infinite Loop",
          },
          address_2: {
            name: "Address 2",
            value: "{{partner.address.street2}}",
          },
          address_3: {
            name: "Address 3",
            value: "{{partner.address.street3}}",
          },
          city: {
            name: "City",
            value: "{{partner.address.city}}",
            sample: "Cupertino",
          },
          state: {
            name: "State",
            value: "{{partner.address.state}}",
            sample: "CA",
          },
          county: {
            name: "County",
            value: "{{partner.address.county}}",
          },
          postal_code: {
            name: "Postal Code",
            value: "{{partner.address.zip}}",
            sample: "95014",
          },
        },
      },
      license: {
        name: "Drivers License",
        mergeTags: {
          license_state: {
            name: "State",
            value: "{{partner.license.license_state}}",
            sample: "CA",
          },
          license_number: {
            name: "Number",
            value: "{{partner.license.license_number}}",
            sample: "DL55555",
          },
          license_expiry: {
            name: "License Expiration",
            value: "{{partner.license.license_expiry}}",
            sample: "2020-01-01",
          },
        },
      },
      vehicle: {
        name: "Vehicle",
        mergeTags: {
          make: {
            name: "Make",
            value: "{{partner.vehicle.make}}",
            sample: "Plymouth",
          },
          model: {
            name: "Model",
            value: "{{partner.vehicle.model}}",
            sample: "Prowler",
          },
          year: {
            name: "Year",
            value: "{{partner.vehicle.year}}",
            sample: "1997",
          },
        },
      },
    },
  },
  ddi: {
    name: "DDI",
    mergeTags: {
      corporate_address: {
        name: "Corporate Address",
        value: "{{ddi.address.street1}},{{ddi.address.street2}}<br> {{ddi.address.city}},{{ddi.address.state}} {{ddi.address.zip}}",
      },
      address: {
        name: "Address",
        mergeTags: {
          address_1: {
            name: "Address 1",
            value: "{{ddi.address.street1}}",
            sample: "2 Venture",
          },
          address_2: {
            name: "Address 2",
            value: "{{ddi.address.street2}}",
            sample: "Suite 430",
          },
          address_3: {
            name: "Address 3",
            value: "{{ddi.address.street3}}",
          },
          city: {
            name: "City",
            value: "{{ddi.address.city}}",
            sample: "Irvine",
          },
          state: {
            name: "State",
            value: "{{ddi.address.state}}",
            sample: "CA",
          },
          postal_code: {
            name: "Postal Code",
            value: "{{ddi.address.zip}}",
            sample: "92618",
          },
        },
      },
      company_info: {
        name: "Company Info",
        mergeTags: {
          name: {
            name: "Name",
            value: "{{ddi.name}}",
            sample: "Delivery Drivers Inc.",
          },
          phone: { name: "Phone", value: "{{ddi.phone}}", sample: "(888) DDI-WORK" },
        },
      },
      onboardingEmail: { name: "Onboarding Email", value: "{{ddi.onboardingEmail}}", sample: "john_doe@test.com" },
    },
  },
};
