import gql from "graphql-tag";

export const GET_INVOICE_ARCHIVE = gql`
  subscription invoicesArchive {
    invoices(where: { status: { _in: ["APPROVED"] }, type: { _in: ["SYSTEM", "CUSTOM"] } }, order_by: { invoiceNumber: desc }) {
      id
      status
      settlementID
      submissionID
      name
      invoiceNumber
      dueDate
      invoice
      settlement {
        startDate
        endDate
      }
      client {
        name
        id
      }
    }
  }
`;

export const GET_FILTERED_INVOICE_ARCHIVE = gql`
  query invoicesArchive($clientID: [uuid!], $startDate: timestamptz, $endDate: timestamptz) {
    invoices(
      order_by: { invoiceNumber: desc }
      where: { clientID: { _in: $clientID }, settlement: { startDate: { _gte: $startDate }, endDate: { _lte: $endDate } }, status: { _in: ["CUSTOM_INVOICE", "APPROVED"] } }
    ) {
      id
      status
      settlementID
      submissionID
      name
      invoiceNumber
      dueDate
      invoice
      settlement {
        id
        startDate
        endDate
      }
      client {
        name
        id
      }
    }
  }
`;

export const GET_INVOICES_BY_CLIENTS = gql`
  query invoiceByClient($clientID: [uuid!]) {
    invoices(where: { type: { _in: ["SYSTEM", "CUSTOM"] }, status: { _eq: "APPROVED" }, clientID: { _in: $clientID } }, order_by: { invoiceNumber: desc }) {
      id
      status
      settlementID
      submissionID
      name
      invoiceNumber
      dueDate
      invoice
      settlement {
        id
        startDate
        endDate
      }
      client {
        name
        id
      }
    }
  }
`;

export const GET_CUSTOM_INVOICES = gql`
  query getCustomInvoice {
    invoices(where: { type: { _in: ["CUSTOM"] }, status: { _eq: "APPROVED" } }, order_by: { invoiceNumber: desc }) {
      id
      status
      settlementID
      submissionID
      name
      invoiceNumber
      dueDate
      invoice
      settlement {
        id
        startDate
        endDate
      }
      client {
        name
        id
      }
    }
  }
`;

export const GET_CLIENT_NAMES = gql`
  query getInvoiceArchiveNames {
    clients {
      id
      name
    }
  }
`;

export const GET_CLIENT_NAMES_FILTERS = gql`
  query getFilterInvoiceArchive($id: [uuid!]) {
    distributions(where: { clientID: { _in: $id }, status: { _eq: "DISTRIBUTED" } }) {
      status
      paymentProcessor
      clientID
      id
      payPeriodStartDate
      totalPayout
      payPeriodEndDate
      submission {
        id
        invoices(where: { status: { _eq: "APPROVED" } }) {
          id
          name
          invoice
        }
      }
      client {
        id
        name
        startDate
      }
      depositSummaries {
        settlementID
      }
    }
  }
`;

export const GET_CLIENT_BY_DATE = gql`
  query getClientByDate($startDate: date, $endDate: date) {
    distributions(where: { payPeriodStartDate: { _gte: $startDate }, payPeriodEndDate: { _lte: $endDate }, status: { _eq: "DISTRIBUTED" } }) {
      status
      paymentProcessor
      clientID
      id
      payPeriodStartDate
      totalPayout
      payPeriodEndDate
      submission {
        id
        invoices(where: { status: { _eq: "APPROVED" } }) {
          id
          name
          invoice
        }
      }
      client {
        id
        name
        startDate
      }
      depositSummaries {
        settlementID
      }
    }
  }
`;

export const GET_CUSTOM_INVOICE = gql`
  query GetInvoice($id: uuid!) {
    invoices_by_pk(id: $id) {
      id
      name
      invoice
      invoiceNumber
      status
      terms
      dueDate
      invoiceTemplate {
        id
        useDefaultAddress
        address {
          address1
          address2
          city
          state
          postalCode
        }
      }
      client {
        name
        logo
        address {
          address1
          address2
          city
          state
          postalCode
        }
      }
      settlement {
        startDate
        endDate
      }
      address {
        address1
        address2
        city
        state
        postalCode
      }
    }
  }
`;
