<template>
  <base-modal id="modal-snooze-time-alert">
    <h4>Snooze Alert for Candidate</h4>

    <div class="module no-space">
      <table class="no-zebra">
        <thead>
          <th>Name</th>
          <th>Location</th>
          <th>Step</th>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>{{ $props.candidateStep.name }}</strong>
            </td>
            <td>{{ $props.candidateStep.location }}</td>
            <td>{{ $props.candidateStep.step | titleCase }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <form-select v-model="unit" :options="unitOptions" :width="160"></form-select>
    <div class="button-group close-top">
      <a class="button" data-micromodal-close="data-micromodal-close" @click="snooze" @click.prevent>Continue</a>
      <a class="button secondary" data-micromodal-close="data-micromodal-close" @click.prevent>Cancel</a>
    </div>
  </base-modal>
</template>
<script>
import { BaseModal, FormSelect } from "@/components";
export default {
  components: { BaseModal, FormSelect },
  props: {
    candidateStep: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data: () => ({
    unit: "",
  }),
  computed: {
    unitOptions() {
      return [
        { id: "TWO_HOURS", label: "2 Hours" },
        { id: "SIX_HOURS", label: "6 Hours" },
        { id: "TWELVE_HOURS", label: "12 Hours" },
        { id: "ONE_DAY", label: "1 Day" },
        { id: "TWO_DAYS", label: "2 Days" },
        { id: "SEVEN_DAYS", label: "7 Days" },
        { id: "NEXT_MONDAY", label: "Next Monday" },
      ];
    },
  },
  methods: {
    snooze() {
      this.$emit("onSnooze", this.unit);
      this.unit = "";
    },
  },
};
</script>
