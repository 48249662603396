import gql from "graphql-tag";

export const GET_GARNISHMENTS = gql`
  query getGarnishemtns {
    garnishments(order_by: { state: asc }) {
      id
      state
      country
      minDisposable
      percentageCap
      disposableCap
    }
  }
`;
