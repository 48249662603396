var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('button-bar-tabs',{attrs:{"buttons":_vm.commsTabs},scopedSlots:_vm._u([{key:"texts",fn:function(){return [_c('div',{staticClass:"module-large"},[_c('h2',[_vm._v("Text Messages")]),_c('data-table',{attrs:{"headers":_vm.headersText,"loading":_vm.textLoader,"entries":_vm.communicationsText,"pagination-total-count":_vm.totalCountSMS,"pagination-per-page":_vm.perPageSMS,"pagination-current-page":_vm.currentPageSMS,"display-rows-per-page-max-selector":true},on:{"onPaginate":function($event){return _vm.onPaginateSMS($event)},"onChangePerPage":function($event){return _vm.onChangePerPageSMS($event)}},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [(item.externalIDFull)?_c('a',{attrs:{"href":("https://www.twilio.com/console/sms/logs/" + (item.externalID)),"target":"_blank"}},[_vm._v(_vm._s(item.externalStatus))]):_vm._e(),(item.externalStatus == '' || !item.externalStatus)?_c('span',[_vm._v("No Status Found")]):_c('span',[_vm._v(_vm._s(item.externalStatus))])]}},{key:"createAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("formatDateTime")(item.created_at)))]}},{key:"updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("formatDateTime")(item.updated_at)))]}}])})],1)]},proxy:true},{key:"emails",fn:function(){return [_c('div',[_c('h2',[_vm._v("Email Messages")]),_c('data-table',{attrs:{"headers":_vm.headersEmail,"loading":_vm.loading,"entries":_vm.communicationsEmail,"pagination-total-count":_vm.totalCount,"pagination-per-page":_vm.perPage,"pagination-current-page":_vm.currentPage,"display-rows-per-page-max-selector":true},on:{"onPaginate":function($event){return _vm.onPaginate($event)},"onChangePerPage":function($event){return _vm.onChangePerPage($event)}},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [(item.externalStatus && (item.externalIDFull || item.externalID))?_c('a',{attrs:{"href":("https://app.sendgrid.com/email_activity/" + (item.externalIDFull ? item.externalIDFull : item.externalID)),"target":"_blank"}},[_vm._v(_vm._s(item.externalStatus))]):_vm._e(),(!item.externalStatus)?_c('span',[_vm._v("No Status Found")]):_vm._e()]}},{key:"createAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("formatDateTime")(item.created_at)))]}},{key:"updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("formatDateTime")(item.updated_at)))]}}])})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }