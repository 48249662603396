<template>
  <fragment>
    <overlay />
    <header-footer>
      <main>
        <div class="container">
          <div class="content">
            <slot></slot>
          </div>
        </div>
      </main>
    </header-footer>
  </fragment>
</template>
<script>
import { Fragment } from "vue-fragment";
import Overlay from "@/components/modals/Overlay";
import HeaderFooter from "@/components/layout/HeaderFooter";

export default {
  name: "AdminLayout",
  components: { Overlay, HeaderFooter, Fragment },
};
</script>
