const state = {
  availableDepartmentManagers: [],
  availableAccountManagers: [],
  availableOnboardingSpecialists: [],
  availableUnassignedLocations: [],
};

const getters = {
  getAvailableOnboardingSpecialists: (state) => {
    return state.availableOnboardingSpecialists;
  },
};

const mutations = {
  setAvailableOnboardingSpecialists: (state, payload) => {
    state.availableOnboardingSpecialists = payload;
  },
  addAvailableOnboardingSpecialist: (state, payload) => {
    state.availableOnboardingSpecialists.push(payload);
  },
  removeAvailableOnboardingSpecialist: (state, payload) => {
    state.availableOnboardingSpecialists = state.availableOnboardingSpecialists.filter((specialist) => specialist.id !== payload.id);
  },
};

const actions = {
  updateAvailableOnboardingSpecialists: ({ commit }, payload) => {
    commit("setAvailableOnboardingSpecialists", payload);
  },
  addAvailableOnboardingSpecialist: ({ commit }, payload) => {
    commit("addAvailableOnboardingSpecialist", payload);
  },
  removeAvailableOnboardingSpecialist: ({ commit }, payload) => {
    commit("removeAvailableOnboardingSpecialist", payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
