import gql from "graphql-tag";

export const GET_CLIENTS = gql`
  query GetClients($limit: Int, $offset: Int, $status1: Boolean, $status2: Boolean) {
    total: clients_aggregate(where: { _or: [{ active: { _eq: $status1 } }, { active: { _eq: $status2 } }] }) {
      aggregate {
        count
      }
    }
    clients(order_by: { name: asc }, limit: $limit, offset: $offset, where: { _or: [{ active: { _eq: $status1 } }, { active: { _eq: $status2 } }] }) {
      name
      id
      active
      locations: locations_aggregate {
        aggregate {
          count
        }
        nodes {
          partners: partners_aggregate {
            aggregate {
              count
            }
          }
        }
      }
      contacts: contacts_aggregate {
        aggregate {
          count
        }
      }
      departmentManagers {
        manager {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
export const GET_CLIENT_DETAILS = gql`
  query GetClientDetails($id: uuid) {
    clients(where: { id: { _eq: $id } }, order_by: { name: asc }) {
      id
      name
      locations {
        id
        name
      }
    }
  }
`;
export const GET_CLIENT_LOCATIONS_BY_PK = gql`
  query GetClientLocationsByPK($id: uuid!) {
    clients_by_pk(id: $id) {
      id
      name
      locations {
        id
        name
      }
    }
  }
`;
export const GET_CLIENTS_DETAILS = gql`
  query GetClientDetails {
    clients(order_by: { name: asc }, where: { active: { _eq: true } }) {
      id
      name
    }
  }
`;
export const GET_CLIENT_LOCATIONS_DETAILS = gql`
  query GetClientLocationsDetails($id: uuid) {
    clientLocations(where: { clientID: { _eq: $id } }) {
      id
      name
    }
  }
`;

export const GET_CLIENT_DETAILS_BY_PK = gql`
  query getClientDetails($id: uuid!) {
    client: clients_by_pk(id: $id) {
      id
      name
      address {
        id
        address1
        address2
        city
        state
        country
        postalCode
      }
      phone
      ageRequirement
      federalTaxID
      taxProgram
      isRecruiting
      active
      timeZone
      startDate
      insuranceSetting
      businessType
      clientConfiguration
      locations: locations_aggregate {
        aggregate {
          count
        }
        nodes {
          partners: partners_aggregate {
            aggregate {
              count
            }
          }
        }
      }
      departmentManagers {
        manager {
          id
          firstName
          lastName
          user {
            id
            role
          }
          teamsManaged {
            team {
              id
              name
              accountManager {
                id
                firstName
                lastName
              }
            }
          }
        }
      }
      timePeriods(where: { type: { _eq: "RECRUITING" } }) {
        startDay
        endDay
        id
      }
    }
  }
`;

// ##### Roles
export const GET_CLIENT_ROLES = gql`
  query GetRoles {
    roles {
      name
      description
    }
  }
`;
// ##### End Roles
// setttlementItems(where: {deleted: {_eq: "false"}}) {
export const GET_FINANCE_TEMPLATE = gql`
  subscription getFinanceTemplate($clientID: uuid!) {
    financeTemplates(where: { clientID: { _eq: $clientID }, locationID: { _is_null: true }, locationPartnerID: { _is_null: true } }) {
      id
      client {
        id
        name
      }
      paymentSettings {
        id
        payCycle
        payDay
        startDay
        paymentProcessor
        secondaryPayCycle
        submissionDueDay
        submissionDueTime
      }
      invoiceTemplates(where: { isActive: { _eq: "true" } }) {
        name
        id
      }
      setttlementItems(where: { status: { _neq: INACTIVE } }) {
        id
        name
        description
        transactionType
        category
        chartOfAccounts
        settlementItemType
        taxSetting
        additionalData
        disposable
        billTo
        mappingKey
        invoiceTemplateID
        modifiersThatOverride {
          financeTemplate {
            clientLocation {
              id
              name
            }
          }
        }
        overriddenModifier {
          id
          name
          description
          transactionType
          category
          settlementItemType
          taxSetting
          additionalData
          billTo
        }
      }
    }
  }
`;

export const GET_ROOT_FINANCE_TEMPLATE = gql`
  query getRootFinanceTemplate {
    financeTemplates(where: { clientID: { _is_null: true }, locationID: { _is_null: true }, locationPartnerID: { _is_null: true } }) {
      id
      client {
        id
        name
      }
      paymentSettings {
        id
        payCycle
        payDay
        startDay
        paymentProcessor
      }
      setttlementItems {
        id
        name
        description
        transactionType
        category
        settlementItemType
        taxSetting
        additionalData
        billTo
        modifiersThatOverride {
          financeTemplate {
            clientLocation {
              id
              name
            }
          }
        }
        overriddenModifier {
          id
          name
          description
          transactionType
          category
          settlementItemType
          taxSetting
          additionalData
          billTo
        }
      }
    }
  }
`;

export const GET_CLIENT_BRANDING = gql`
  query GetClientBranding($clientID: uuid) {
    clients(where: { id: { _eq: $clientID } }) {
      color
      logo
    }
  }
`;

export const GET_LOCATIONS_BY_CLIENT_ID = gql`
  query getLocationsByClientId($id: uuid!) {
    clientLocations(where: { clientID: { _eq: $id } }) {
      id
      name
      templates: financeTemplates_aggregate(where: { locationPartnerID: { _is_null: true } }) {
        aggregate {
          count
        }
        nodes {
          id
        }
      }
    }
  }
`;

export const FIND_ALL_MANAGERS = gql`
  query findAllManagers {
    employees(where: { user: { role: { _eq: DEPARTMENT_MANAGER } } }) {
      id
      firstName
      lastName
      user {
        id
        role
      }
    }
  }
`;

export const FIND_AVAILABLE_SPECIALISTS = gql`
  query findAvailableSpecialists($teamID: uuid!) {
    employees(where: { _and: [{ user: { role: { _eq: ONBOARDING_SPECIALIST } } }, { _or: [{ specialist: { teamID: { _eq: $teamID } } }, { _not: { specialist: {} } }] }] }) {
      id
      firstName
      lastName
      specialist {
        id
      }
    }
  }
`;

//and active
export const GET_INVOICE_TEMPLATES_BY_CLIENT = gql`
  subscription findInvoiceTemplatesForClient($clientID: uuid!) {
    invoiceTemplates(where: { _and: [{ isActive: { _eq: true }, clientID: { _eq: $clientID } }] }) {
      address {
        address1
        address2
        city
        state
        postalCode
      }
      client {
        address {
          address1
          address2
          city
          postalCode
          state
        }
        name
      }
      invoiceCycle
      terms
      name
      useDefaultAddress
      id
      settlementItems(where: { status: { _neq: INACTIVE } }) {
        id
        name
        transactionType
        chartOfAccounts
      }
    }
  }
`;
export const GET_CLIENT_LOCATION_CONTACTS = gql`
  query getClientLocationForContacts($id: uuid!) {
    clientLocations(where: { clientID: { _eq: $id } }, order_by: { name: asc }) {
      name
      id
    }
  }
`;
export const GET_CLIENT_CONTACTS_FILTER_LOCATION = gql`
  query getCLientContactsLocationFilter($clientID: uuid, $clientLocationID: [uuid!]) {
    contacts(where: { _and: { clientID: { _eq: $clientID }, locations: { clientLocationID: { _in: $clientLocationID } } } }) {
      address {
        address1
        address2
        city
        country
        id
        postalCode
        state
      }
      user {
        id
        role
        disabled
      }
      id
      firstName
      lastName
      middleName
      phone
      email
    }
  }
`;

export const FIND_SETTLEMENT_ITEM_BY_INVOICE_TEMPLATE_ID = gql`
  query findSettelmentItemByTemplateId($modifierID: uuid!) {
    settlementItems_by_pk(id: $modifierID) {
      id
      invoiceTemplateID
    }
  }
`;

export const GET_LOCATIONS_FOR_CLIENT = gql`
  query getLocationsByClientId($clientID: uuid!) {
    locations: clientLocations(where: { clientID: { _eq: $clientID } }) {
      id
      name
    }
  }
`;

// mutation updateInvoiceTemplateToInactive($modifierID: uuid!, $status:settlementItemStatuses_enum) {
//   update_settlementItems_by_pk(pk_columns: { id: $modifierID }) {

export const GET_HEADCOUNT_DATA = gql`
  query getHeadcountData($id: uuid!, $startDate: date, $endDate: date) {
    clientLocationRequests(where: {_and: {clientLocationID: {_eq: $id}, startDate: {_eq: $startDate}, endDate: {_eq: $endDate}}}) {
      id
      count
    }    
  }
`;
