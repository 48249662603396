<template>
  <fragment>
    <div class="module-large">
      <h2>Errors</h2>
    </div>

    <!-- Build a summary table grouped by ?? category...eventTime... -->
    <div class="grid">
      <div class="col-12">
        <p class="label">Filters</p>
        <base-filter name="Category" :options="categoryFilterOptions" @onFilter="onFilterCategories" />
        <!-- This would be a helpful filter, but until I have time to build it... -->
        <!-- <date-range-filter name="Event Time" :date-range="dateRangeOptions" /> -->
      </div>
    </div>
    <p class="float-left" style="position: relative; top: 12px">
      Filtered <span>{{ filteredCount }}</span> of <span>{{ totalCount }}</span>
    </p>
    <data-table
      :headers="errorHeaders"
      :entries="errors"
      :loading="loading"
      :pagination-total-count="filteredCount"
      :pagination-per-page="perPage"
      :pagination-current-page="currentPage"
      display-rows-per-page-max-selector
      remote-sort
      use-index
      @onPaginate="onPaginate($event)"
      @onChangePerPage="onChangePerPage($event)"
      @onSort="onSort($event)"
    >
      <template #actions="{ item }">
        <meatball :id="item.id" right > <!--@selected="currentElement($event)"-->
          <!-- <menu-item label="Fix Problem"></menu-item> -->
          <menu-item label="View Context" :on-click="openContext(item.id, item.index)"></menu-item>
        </meatball>
      </template>
    </data-table>

    <error-log-flyout :error-log-id="selectedErrorLogId" :single-log="singleLog" :selected-index="selectedIndex" :on-close="closeContext" :total="filteredCount" @onPage="changeSelectedErrorLogId" />
  </fragment>
</template>
<script>
import { Fragment } from "vue-fragment";
import { DataTable, TableHeader, Meatball, MenuItem, BaseFilter } from "@/components";
// import { FIND_FILTERED_ERRORS } from "@/modules/admin/ddi/graph/queries";
import ErrorLogFlyout from "@/modules/admin/ddi/components/ErrorLogFlyout";
import { mapActions } from "vuex";
import { ErrorLogCategory } from "@/modules/admin/ddi/model/ErrorLogCategory";
import { restApi } from "@/http/http.config";
import {encodeWithParam} from "@/util/Base64Encoding";

export default {
  name: "ErrorLogTab",
  components: { BaseFilter, ErrorLogFlyout, MenuItem, Meatball, DataTable, Fragment },
  data: () => ({
    selectedErrorLogId: "",
    selectedIndex: 0,
    cursor: 0,
    totalCount: 0,
    currentPage: 0,
    perPage: 50,
    offset: 0,
    filteredCount: 0,
    filteredCategories: [],
    sort: { column: "eventTime", type: "desc" },
    errors: [],
    loading: true,
    singleLog: ""
  }),
  computed: {
    // singleLog() {
    //   return JSON.stringify(this.errors[this.cursor]);
    // },
    categoryFilterOptions() {
      return Object.keys(ErrorLogCategory.values).map((key) => ({ id: key, name: key, label: ErrorLogCategory.values[key], checked: false }));
    },
    dateRangeOptions() {
      return { startDate: "", endDate: "" };
    },
    errorHeaders() {
      return [
        new TableHeader({ name: "category", label: "Category", sortable: true, cellContents: "category", cellClass: "cell15" }),
        new TableHeader({ name: "type", label: "Type", sortable: true, cellContents: "type", cellClass: "cell15" }),
        new TableHeader({ name: "message", label: "Message", sortable: true, cellContents: "message" }),
        new TableHeader({ name: "eventTime", label: "Event Time", sortable: true, cellContents: (item) => this.formatDate(item.eventTime), cellClass: "cell20" }),
        new TableHeader({ name: "actions", label: "Actions", sortable: true }),
      ];
    },
  },
  mounted() {
    this.filteredErrorLogs();
  },
  methods: {
    ...mapActions(["showFlyout"]),
    async filteredErrorLogs(){
      this.loading = true;
      let param = {
        limit: this.perPage,
        offset: this.offset,
        filteredCategories: this.filteredCategories.length === 0 ? ErrorLogCategory.getKeys() : this.filteredCategories.map((category) => ErrorLogCategory.findCategoryFromLabel(category)),
        sort: this.sort
      };
      // console.log(param);
      await restApi.post(`/comm/FilteredErrorLogs`, encodeWithParam(param)).then((responseData)=>{
        // console.log(responseData.data.data);
        this.errors = responseData?.data?.data;
        this.totalCount = responseData?.data?.totalCount;
        this.filteredCount =responseData?.data?.rowCount;
        this.loading = false;
        this.selectedErrorLogId = responseData?.data?.data[this.cursor].id;
        // this.singleLog = responseData?.data?.data[this.cursor];
      });
    },
    formatDate(date) {
      return this.$options.filters.formatDateTimePacific(date);
    },
    onPaginate(event) {
      let definedPage = Number(event.pageNumber);
      if (definedPage <= Math.ceil(this.filteredCount / this.perPage) && definedPage > 0) {
        this.currentPage = Number(event.pageNumber);
        this.offset = event.offset;
        this.filteredErrorLogs();
      }
    },
    onChangePerPage(event) {
      this.perPage = Number(event.perPage);
      this.filteredErrorLogs();
    },
    onSort(header) {
      this.sort = { column: header.name, type: header.direction };
      this.filteredErrorLogs();
    },
    onFilterCategories(event) {
      this.currentPage = 1;
      this.offset = 0;
      this.filteredCategories = event;
      this.filteredErrorLogs();
    },
    changeSelectedErrorLogId(event) {
      const oldPage = this.currentPage;
      this.currentPage = Math.floor((event.pageNumber - 1) / this.perPage) + 1;
      const offset = this.currentPage - 1;
      const index = (event.pageNumber - 1) % this.perPage;
      if (oldPage !== this.currentPage) {
        this.cursor = oldPage > this.currentPage ? 9 : 0;
        this.offset = offset * this.perPage;
      } else {
        this.selectedErrorLogId = this.errors[index].id;
      }
    },
    currentElement(event) {
      console.log(event);
    },
    openContext(errorLogID, index) {
      return () => {
        this.selectedErrorLogId = errorLogID;
        this.singleLog = JSON.stringify(this.errors[index]);
        this.selectedIndex = this.perPage * this.currentPage + (index + 1);
        document.body.classList.add("no-scroll");
        this.showFlyout("errorlog-flyout");
      };
    },
    closeContext() {
      document.body.classList.remove("no-scroll");
      this.showFlyout("");
      this.cursor = 0;
      this.selectedIndex = 0;
      this.selectedErrorLogId = this.errors[0].id;
    },
  },
  // apollo: {
  //   errors: {
  //     query: FIND_FILTERED_ERRORS,
  //     fetchPolicy: "no-cache",
  //     variables() {
  //       return {
  //         limit: this.perPage,
  //         offset: this.offset,
  //         filteredCategories: this.filteredCategories.length === 0 ? ErrorLogCategory.getKeys() : this.filteredCategories.map((category) => ErrorLogCategory.findCategoryFromLabel(category)),
  //         sort: this.sort,
  //       };
  //     },
  //     update: (result) => result.errors,
  //     result({ data }) {
  //       this.filteredCount = data?.filteredTotal?.aggregate?.count;
  //       if (data?.total?.aggregate?.count || data?.total?.aggregate?.count === 0) {
  //         this.totalCount = data?.total?.aggregate?.count || data?.total?.aggregate?.count === 0;
  //       }
  //       this.selectedErrorLogId = data.errors[this.cursor].id;
  //     },
  //   },
  // },
};
</script>
