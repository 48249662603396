<template>
  <base-modal id="modal-document-used">
    <h4>Which <span v-if="!isClient && !isLocation">Clients</span><span v-if="isClient">Locations</span><span v-if="isLocation">Partners</span> are Using this Document</h4>
    <div class="grid document-locations">
      <div class="col-6">
        <h5>{{ using.length }} Using (Inherited)</h5>
        <div class="document-location-list">
          <ul v-if="using.length > 0" class="list-unstyled">
            <li v-for="(item, index) in using" :key="index + item.id">
              <span v-if="!isClient && !isLocation">
                <router-link :to="{ name: 'client', params: { id: item.id } }"> {{ item.name }} <i class="icon-external-link"></i></router-link
              ></span>
              <span v-if="isClient && !isLocation">
                <router-link :to="{ name: 'location', params: { id: item.id, locationID: item.id } }"> {{ item.name }} <i class="icon-external-link"></i></router-link
              ></span>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-6">
        <h5>{{ notUsing.length }} Not Using (Removed)</h5>
        <div class="document-location-list">
          <ul v-if="notUsing.length > 0" class="list-unstyled">
            <li v-for="item in notUsing" :key="item.id">
              <router-link :to="{ name: 'location', params: { id: item.clientID, locationID: item.id } }"> {{ item.name }} <i class="icon-external-link"></i></router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="button-group close-top">
      <a class="button secondary" data-micromodal-close="data-micromodal-close">Close</a>
    </div>
  </base-modal>
</template>
<script>
import BaseModal from "@/components/modals/BaseModal";
import {
  // GET_TEMPLATE_USED_BY_CLIENTS,
} from "@/modules/shared/documents/graph/queries";
import micromodal from "micromodal";
export default {
  name: "DocumentUsedModal",
  components: { BaseModal },
  props: {
    template: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data: function () {
    return {
      using: [],
      notUsing: [],
    };
  },
  computed: {
    isClient() {
      return !!this.$route.params.id && !this.$route.params.locationID;
    },
    isLocation() {
      return !!this.$route.params.locationID;
    },
  },
  mounted() {
    this.$nextTick(
      micromodal.init({
        onShow: function () {
          document.body.classList.add("no-scroll");
        },
        onClose: function () {
          document.body.classList.remove("no-scroll");
        },
      })
    );
  },
  methods: {
    locationClick: function (locationID) {
      this.$router.push({ name: "location", params: { clientID: this.$route.params.id, locationID } });
    },
  },
  apollo: {
    // inheritedClients: {
    //   query: GET_TEMPLATE_USED_BY_CLIENTS,
    //   fetchPolicy: "cache-and-network",
    //   variables: function () {
    //     return {
    //       templateID: this.$props.template.id,
    //     };
    //   },
    //   result({ data }) {
    //     const inheritedIds = data.inheritedClients.map((client) => client.clientID);
    //     this.using = data.allClients.filter((client) => {
    //       return !inheritedIds.includes(client.id);
    //     });
    //     this.notUsing = data.allClients.filter((client) => {
    //       return inheritedIds.includes(client.id);
    //     });
    //   },
    //   skip() {
    //     return !this.$props.template.id;
    //   },
    // },
  },
};
</script>
