import gql from "graphql-tag";

export const GET_CLIENT_BRANDING = gql`
  query getClientForContact($contactID: uuid!) {
    contacts_by_pk(id: $contactID) {
      client {
        id
        color
        logo
        name
      }
    }
  }
`;

export const GET_LOCATION_DETAILS = gql`
  query getLocationDetails($locationID: uuid!) {
    clientLocations_by_pk(id: $locationID) {
      id
      name
    }
  }
`;

export const GET_LOCATION_REQUESTS = gql`
  query getLocationRequests($locationID: uuid!,$clientID: uuid) {
    location: clientLocations_by_pk(id: $locationID) {
      id
      name
      isRecruiting
      client {
        id
        name
      }
      partners: partners_aggregate(where: { approvalDate: { _is_null: false }, status: {_in: [ACTIVE, NEWLY_APPROVED]} }) {
        aggregate {
          count
        }
      }
      headCountRequests {
        id
        count
        startDate
        endDate
      }
      stageCounts {
        stage
        count
        weekly
      }
    }
    timePeriods: timePeriod(where: { _and: [{ _or: [{ clientID: { _eq: $clientID } }, { clientID: { _is_null: true } }] }, { type: { _eq: "RECRUITING" } }] }) {
      endDay
      startDay
      timezone
      clientID
    }
  }
`;

export const GET_RECRUITING_TIMEPERIODS = gql`
  query GetRecruitingTimePeriods($clientID: uuid) {
    timePeriod(where: { _and: [{ _or: [{ clientID: { _eq: $clientID } }, { clientID: { _is_null: true } }] }, { type: { _eq: "RECRUITING" } }] }) {
      endDay
      startDay
      timezone
      clientID
    }
  }
`;
export const GET_ON_HOLD_CANDIDATES = gql`
  query getOnHoldPartners($clientLocationID: uuid) {
    clientLocationPartners(where: { clientLocationID: { _eq: $clientLocationID }, candidateProgress: { candidateStep: { _eq: "COMPLETED" }, candidateStatus: { _eq: "ON_HOLD" } } }) {
      candidateProgress(order_by: { date: desc }) {
        candidateStatusDetail
        candidateStep
        candidateStatus
        date
        assignedOS
      }
      partnerID
      id
    }
  }
`;
