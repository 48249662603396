<template>
  <fragment>
    <div class="tags">
      <div v-for="team in taggedTeams" :key="team.id" class="tag">
        <div class="info">
          <p>{{ team.name }}</p>
        </div>
        <div class="close" @click="removeTag(team)">
          <i class="icon-x-circle"></i>
        </div>
      </div>
    </div>
    <div class="select-container">
      <select :class="{ hidden: !teamSelectShowing }" @change="addTag($event)">
        <option value="">Choose Team</option>
        <option v-for="team in teams" :key="team.id" :value="JSON.stringify(team)">
          {{ team.name }}
        </option>
      </select>
    </div>
    <p>
      <a :class="{ hidden: teamSelectShowing || $props.canAdd(taggedTeams) }" @click="showTeamSelect()"> <i class="icon-plus-circle"></i>Add Employee to Team </a>
    </p>
  </fragment>
</template>
<script>
import { Fragment } from "vue-fragment";
import { GET_TEAMS } from "@/components/pickers/queries";
export default {
  name: "TeamPicker",
  components: { Fragment },
  props: {
    canAdd: {
      type: Function,
      required: false,
      default: () => true,
    },
  },
  data: () => {
    return {
      teams: [],
      taggedTeams: [],
      teamSelectShowing: false,
    };
  },
  methods: {
    removeTag(taggedTeam) {
      this.taggedTeams = this.taggedTeams.filter((team) => {
        if (team.id === taggedTeam.id) {
          this.teams.push(team);
          this.teams.sort((a, b) => a.name.localeCompare(b.name));
        }
        return team.id !== taggedTeam.id;
      });
      this.$emit("tagsChanged", this.taggedTeams);
    },
    reset(callback) {
      if (callback(this.taggedTeams.length > 1)) {
        this.teams.push(...this.taggedTeams);
        this.teams.sort((a, b) => a.name.localeCompare(b.name));
        this.taggedTeams = [];
        this.$emit("tagsChanged", this.taggedTeams);
      }
    },
    showTeamSelect() {
      this.teamSelectShowing = true;
    },
    addTag(event) {
      const teamToTag = JSON.parse(event.target.value);
      this.taggedTeams.push(teamToTag);
      this.teams = this.teams.filter((team) => team.id !== teamToTag.id);
      this.teamSelectShowing = false;
      this.$emit("tagsChanged", this.taggedTeams);
    },
  },
  apollo: {
    teams: {
      query: GET_TEAMS,
    },
  },
};
</script>
