<template>
  <fragment>
    <div class="page-header">
      <div class="header-contents">
        <h1>General Settings</h1>
      </div>
      <div class="header-aside">
        <search />
      </div>
    </div>
    <tabs :tabs="tabs">
      <!-- <template #documents><ddi-documents-tab /></template> -->
      <!-- <template #finances><ddi-finance-tab /></template> -->
      <template #communications><ddi-communications-tab /></template>
      <template #branch><branch-status /></template>
      <template #errors><error-log-tab /></template>
    </tabs>
  </fragment>
</template>

<script>
import { mapGetters } from "vuex";
import { Fragment } from "vue-fragment";
import { Tabs, Search } from "@/components";
// import DdiFinanceTab from "@/modules/admin/ddi/tabs/DdiFinanceTab";
import DdiCommunicationsTab from "@/modules/admin/ddi/tabs/DdiCommunicationsTab";
// import DdiDocumentsTab from "@/modules/admin/ddi/tabs/DdiDocumentsTab";
import BranchStatus from "@/modules/admin/ddi/tabs/BranchStatus";
import ErrorLogTab from "./tabs/ErrorLogTab";

export default {
  name: "DDI",
  components: { 
    ErrorLogTab, 
    BranchStatus, 
    // DdiFinanceTab, 
    DdiCommunicationsTab, 
    // DdiDocumentsTab, 
    Search, 
    Tabs, 
    Fragment 
    },
  data() {
    return {
      id: this.$route.params.id,
      breadcrumbs: [{ name: "Clients", path: "clients" }, { name: this.clientName }],
      tabs: [
        // { key: "documents", label: "Documents" },
        // { key: "finances", label: "Finances" },
        { key: "communications", label: "Communications" },
        { key: "branch", label: "Branch Status" },
        { key: "errors", label: "Error Log" },
      ],
    };
  },
  computed: {
    ...mapGetters(["isFlyoutShowing"]),
    tab: () => {
      return this.$route.name;
    },
    showUI() {
      return !this.$route.meta.hideUI;
    },
  },
};
</script>
