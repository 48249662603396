<template>
  <fragment>
    <overlay />
    <slot />
    <footer-component :color="color" />
  </fragment>
</template>
<script>
import FooterComponent from "@/components/layout/Footer";
import Overlay from "@/components/modals/Overlay";
import { Fragment } from "vue-fragment";

export default {
  name: "AddEditLayout",
  components: { Overlay, FooterComponent, Fragment },
  data: () => ({
    color: null,
  }),
};
</script>
